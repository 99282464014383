import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./Statics.module.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.about__cont}>
      <Helmet title="О нас">
        <meta name="description" content="Shalfey : О нас" />
        <meta
          name="keywords"
          content="Экосистема, Шалфей, уникальное онлайн-пространство"
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <div className={styles.about__header}>
        <span className={styles.about__header__title}>“Экосистема Шалфей”</span>
        <span className={styles.about__header__subtitle}>
          уникальное онлайн-пространство
        </span>
      </div>
      <div className={styles.about__body}>
        <div className={styles.about__block}>
          <div className={styles.about__block__text}>
            <p className={styles.about__block__text__title}>О нас</p>
            {ABOUT_US.map((text, index) => (
              <p key={index} className={styles.about__block__text__desc}>
                {text}
              </p>
            ))}
          </div>
          <div
            className={`${styles.about__block__image} ${styles.about__block__image_1}`}
          />
        </div>
        <div className={styles.about__second_block}>
          <div
            className={`${styles.about__block__image} ${styles.about__block__image_2}`}
          />
          <div className={styles.about__block__text}>
            <p className={styles.about__block__text__title}>Наша цель</p>
            {OUR_GOAL.map((text, index) => (
              <p key={index} className={styles.about__block__text__desc}>
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { About };

const ABOUT_US = [
  "В каждой семье, в каждом роду хранятся секреты здоровья, передаваемые из поколения в поколение. Это ценные знания, впитавшие мудрость предков, силу традиций и  гармонию с природой.",
  "Экосистема «Шалфей» бережно собирает эти секреты и объединяет их с современными исследованиями. Мы верим в силу природы и хотим, чтобы вы могли пользоваться ею с уверенностью.",
];
const OUR_GOAL = [
  "Мы поставили перед собой основные задачи:",
  "1. Облегчить доступ к качественным натуральным и природным препаратам.",
  "Анкета, созданная практикующими  экспертами с медицинским образованием, поможет найти именно то, что нужно Вам, классифицируя препараты по эффектам в организме.",
  "2. Соединить традиции и науку.",
  "Мы изучаем и проверяем эффективность традиционных методов врачевания, чтобы предлагать вам надежные и проверенные решения.",
  "3. Создать сообщество осознанных людей.",
  "ШалфейВики  поможет Вам узнать больше о здоровье, питании, экологичном образе жизни и встретить единомышленников.",
];
