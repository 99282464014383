import React from "react";
import styles from "./Inputs.module.scss";

const Tabs = ({
  tabs = [],
  currentTab = "",
  styleTab = {},
  changeTab = () => {},
  ...rest
}) => {
  return (
    <div className={styles.tabs} style={{ ...rest }}>
      {tabs.map((tab, index) => (
        <span
          key={index}
          className={
            currentTab === tab?.name || currentTab === tab?.type
              ? "selected"
              : ""
          }
          style={{ ...styleTab }}
          onClick={() => changeTab(tab?.name || tab?.type)}
        >
          {tab?.title}
        </span>
      ))}
    </div>
  );
};

export { Tabs };
