import styles from "./Inputs.module.scss";

const Button = ({
  text = "",
  bgColor,
  color,
  width,
  height,
  disabled = false,
  light = false,
  newLight = false,
  pressStyle = false,
  prepend = null,
  click,
  ...props
}) => {
  return (
    <button
      className={`${styles.button} ${light && styles.button2} ${
        newLight && styles.button3
      } ${disabled && styles.disabled_button} ${
        pressStyle ? styles.press : null
      }`}
      style={{
        width,
        height,
        backgroundColor: bgColor,
        color,
        ...props,
      }}
      disabled={disabled}
      onClick={click}
    >
      {prepend ? (
        <b style={{ marginRight: prepend && "1vh" }}>{prepend}</b>
      ) : (
        <></>
      )}
      {text}
    </button>
  );
};

export { Button };
