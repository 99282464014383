import React from "react";
import styles from "./Chat.module.scss";
import { vh, vw } from "plugins/convertPXtoVHVW";

const MessageChat = ({ message, isMobile = false, ...rest }) => {
  return (
    <div
      className={`${isMobile ? styles.mobile_message_chat : ""} ${
        styles.message_chat
      } ${
        message?.type_of_message === "MINE" ? styles.message_chat__seller : ""
      }`}
      style={{ ...rest }}
    >
      <div className={styles.message_chat__info}>
        {message?.type_of_message !== "MINE" && (
          <span className={styles.message_chat__info__name}>
            {message?.username}
          </span>
        )}
        <span className={styles.message_chat__info__date}>
          {message?.creation_date}
        </span>
      </div>
      <div className={styles.message_chat__message}>
        <span className={styles.message_chat__message__text}>
          {message?.text}
        </span>
        {message?.isError && (
          <span className={styles.message_chat__message__error}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={vh(14)}
              height={vh(15)}
              viewBox="0 0 14 15"
              fill="none"
              style={{ marginLeft: vw(6) }}
            >
              <circle
                cx="6.99609"
                cy="7.49609"
                r="6.53698"
                stroke="#DC7413"
                strokeWidth="0.933854"
              />
              <path
                d="M3.99609 10.498L9.99944 4.4947M9.99944 10.498L3.99609 4.4947"
                stroke="#DC7413"
                strokeWidth="0.933854"
                strokeLinecap="round"
              />
            </svg>
          </span>
        )}
      </div>
      {message.isLast || message.isError ? (
        <div
          className={`${styles.message_chat__status} ${
            message?.isError && styles.message_chat__status__error
          }`}
        >
          {message?.isError
            ? "Не доставлено"
            : message?.is_seen
            ? "Прочитано"
            : "Доставлен"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { MessageChat };
