import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";

import { DefaultLayout, InstractionLayout, NewProfileLayout } from "layout";
import {
  Catalogue,
  Main,
  Profile,
  SellerProfile,
  BecomeSeller,
  Product,
  AllReviews,
  Service,
  PromotionsDiscount,
  Basket,
  ResetPassword,
  ForgotPassword,
  NotFoundPage,
  Yandex,
  CreateProduct,
  UpdateProduct,
  CreateService,
  UpdateService,
  Questionnaire,
  Instraction,
  QuestionnaireResult,
  WaitForVerification,
  Services,
  Favorite,
  Articles,
  Article,
  CreateArticle,
  MyProducts,
  MyArticles,
  OrdersHandler,
  ChatWithBuyers,
  Warehouse,
  CanceledOrders,
  ShopReviews,
  TrackingOrders,
  Chats,
  ShowMyService,
  ShowMyProduct,
  ReviewMyProduct,
  SellerNotifications,
  TrendAnalytics,
  FunnelAnalytics,
  NewsList,
  News,
  FinancialAnalytics,
  ServiceAnalytics,
  ServiceAnalyticsList,
  Clients,
  Forum,
  Theme,
  About,
  MakeOrder,
  Documents,
} from "pages";
import { axiosBasket, axiosSeller } from "plugins/axios";

import * as links from "constants/url";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function App() {
  useEffect(() => {
    if (
      process.env.REACT_APP_MODE === "user" ||
      process.env.REACT_APP_MODE === "develop"
    ) {
      axiosBasket.getCountInMyCart();
    }
    if (
      process.env.REACT_APP_MODE === "seller" ||
      process.env.REACT_APP_MODE === "develop"
    ) {
      axiosSeller.getSellerNotificationsCount();
    }
  });

  return (
    <Router>
      <Helmet titleTemplate="Shalfey :: %s">
        {process.env.REACT_APP_MODE === "seller" && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <Routes>
        {process.env.REACT_APP_MODE === "develop" ||
        process.env.REACT_APP_MODE === "user" ? (
          <>
            <Route path={links.MAIN.url} element={<DefaultLayout />}>
              <Route exact path={links.MAIN.url} element={<Main />} />
              <Route exact path={links.CONFIRM.url} element={<Main />} />
              <Route exact path={links.PRODUCT_ID.url} element={<Product />} />
              <Route
                exact
                path={links.ALL_REVIEWS.url}
                element={<AllReviews />}
              />
              {/* <Route
                exact
                path={links.SERVICE_LIST.url}
                element={<Services />}
              />
              <Route exact path={links.SERVICE_ID.url} element={<Service />} /> */}

              <Route
                path={links.FORGOT_PASSWORD.url}
                element={<ForgotPassword />}
              />
              <Route
                path={links.RESET_PASSWORD.url}
                element={<ResetPassword />}
              />
              <Route
                path={links.NOT_FOUND_PAGE.url}
                element={<NotFoundPage />}
              />
              <Route path={links.YANDEX.url} element={<Yandex />} />
              <Route
                path={links.QUESTIONNAIRE.url}
                element={<Questionnaire />}
              />
              <Route
                path={links.QUESTIONNAIRE_RESULT.url}
                element={<QuestionnaireResult />}
              />
              <Route
                path={links.WAIT_FOR_VERIFICATION.url}
                element={<WaitForVerification />}
              />
              <Route path={links.FAVORITE.url} element={<Favorite />} />
              <Route path={links.BASKET.url} element={<Basket />} />
              <Route
                exact
                path={links.CATALOGUE_ID.url}
                element={<Catalogue />}
              />
              <Route path={links.ARTICLES.url} element={<Articles />} />
              <Route path={links.ARTICLE_ID.url} element={<Article />} />
              <Route
                path={links.CREATE_ARTICLE.url}
                element={<CreateArticle />}
              />

              <Route path={links.MY_ARTICLES.url} element={<MyArticles />} />
              <Route
                path={links.EDIT_ARTICLE_ID.url}
                element={<CreateArticle />}
              />
              <Route
                exact
                path={links.CHAT_WITH_SELLERS.url}
                element={<Chats />}
              />
              <Route path={links.NEWS.url} element={<NewsList />} />
              <Route path={links.NEWS_ID.url} element={<News />} />
              <Route path={links.FORUM.url} element={<Forum />} />
              <Route path={links.FORUM_ID.url} element={<Theme />} />
              <Route path={links.ABOUT_US.url} element={<About />} />
            </Route>
            <Route
              path={links.MAIN.url}
              element={<DefaultLayout bgColor="#EAF8E7" />}
            >
              <Route exact path={links.PROFILE.url} element={<Profile />} />
              <Route path={links.DOCUMENTS.url} element={<Documents />} />
            </Route>

            <Route
              path={links.BUYERS.url}
              element={<InstractionLayout staticPages />}
            >
              <Route
                path={links.BUYERS.url + links.HOW_TO_MAKE_ORDER.url}
                element={<MakeOrder />}
              />
            </Route>
          </>
        ) : (
          <></>
        )}
        {process.env.REACT_APP_MODE === "develop" ||
        process.env.REACT_APP_MODE === "seller" ? (
          <>
            <Route path={links.NOT_FOUND_PAGE.url} element={<DefaultLayout />}>
              <Route
                path={links.NOT_FOUND_PAGE.url}
                element={<NotFoundPage />}
              />
            </Route>
            <Route
              path={links.SELLER_PROFILE.url}
              element={<InstractionLayout />}
            >
              <Route
                exact
                path={links.INSTRACTION.url}
                element={<Instraction />}
              />
              <Route
                exact
                path={links.BECOME_SELLER.url}
                element={<BecomeSeller />}
              />
            </Route>
            <Route
              path={links.SELLER_PROFILE.url}
              element={<NewProfileLayout />}
            >
              <Route
                exact
                path={links.CREATE_PRODUCT.url}
                element={<CreateProduct />}
              />
              <Route
                exact
                path={links.UPDATE_PRODUCT.url}
                element={<UpdateProduct />}
              />
              <Route
                exact
                path={links.CREATE_SERVICE.url}
                element={<CreateService />}
              />
              <Route
                exact
                path={links.UPDATE_SERVICE.url}
                element={<UpdateService />}
              />
              <Route
                exact
                path={links.SHOW_MY_PRODUCT.url}
                element={<ShowMyProduct />}
              />
              <Route
                exact
                path={links.SHOW_MY_SERVICE.url}
                element={<ShowMyService />}
              />
            </Route>
            <Route
              path={links.SELLER_PROFILE.url}
              element={<NewProfileLayout bgColor="#EAF8E7" />}
            >
              <Route
                exact
                path={links.ORDERS_HANDLER.url}
                element={<OrdersHandler />}
              />
              <Route
                exact
                path={links.SELLER_PROFILE.url}
                element={<SellerProfile />}
              />
              <Route
                exact
                path={links.CHAT_WITH_BUYERS.url}
                element={<ChatWithBuyers />}
              />
              <Route path={links.SHOP_PRODUCTS.url} element={<MyProducts />} />
              <Route path={links.SHOP_REVIEWS.url} element={<ShopReviews />} />
              <Route
                path={links.SHOP_REVIEWS_ID.url}
                element={<ReviewMyProduct />}
              />
              <Route exact path={links.WAREHOUSE.url} element={<Warehouse />} />
              <Route
                exact
                path={links.PROMOTIONS_DISCOUNT.url}
                element={<PromotionsDiscount />}
              />
              <Route
                exact
                path={links.CANCELED_ORDERS.url}
                element={<CanceledOrders />}
              />
              <Route
                exact
                path={links.TRACKING_ORDERS.url}
                element={<TrackingOrders />}
              />
              <Route
                exact
                path={links.TREND_ANALYTICS.url}
                element={<TrendAnalytics />}
              />
              <Route
                exact
                path={links.SERVICES_ANALYTICS.url}
                element={<ServiceAnalyticsList />}
              />
              <Route
                exact
                path={links.SERVICES_ANALYTICS_ID.url}
                element={<ServiceAnalytics />}
              />
              <Route
                exact
                path={links.FINANCIAL_STATEMENTS.url}
                element={<FinancialAnalytics />}
              />
              <Route
                exact
                path={links.MARKETING_ANALYTICS.url}
                element={<TrendAnalytics />}
              />
              <Route
                exact
                path={links.SALES_FUNNEL.url}
                element={<FunnelAnalytics />}
              />
              <Route exact path={links.CLIENTS.url} element={<Clients />} />

              <Route
                exact
                path={links.SELLER_NOTIFICATIONS.url}
                element={<SellerNotifications />}
              />
            </Route>
          </>
        ) : (
          <></>
        )}
      </Routes>
    </Router>
  );
}

export default App;
