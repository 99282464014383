import * as url from "../endpoints";
import { instance } from "./createAxios";

export const axiosStatic = {
  async getStaticInfo(typeOfInfo) {
    try {
      const response = await instance.get(url.STATIC_INFO(typeOfInfo));
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getSocialLink(SocialNetworkType) {
    try {
      const response = await instance.get(url.SOCIAL_LINK(SocialNetworkType));
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getCities(name = "") {
    try {
      const response = await instance.get(url.GET_CITIES(name));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getTerminals(city = "", address = "") {
    try {
      const response = await instance.get(url.GET_TERMINALS(city, address));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getAllDocs() {
    try {
      const response = await instance.get(url.GET_ALL_DOCS);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getDocument(name) {
    try {
      const response = await instance.get(url.GET_DOC(name), {
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
