const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const urlImgToFile = async (urlImg, cover = "cover") => {
  let imgExt = getUrlExtension(urlImg);

  const response = await fetch(urlImg);
  const blob = await response.blob();
  const file = new File([blob], cover + "." + imgExt, {
    type: blob.type,
  });
  return file;
};
