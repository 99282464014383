export const DRAFT = { type: "DT", title: "Черновик" };
export const PUBLISH = { type: "CK", title: "Опубликовать" };
export const PUBLISHED = { type: "PD", title: "Опубликовано" };
export const MODERATED = { type: "CK", title: "Проверяется" };
export const REJECTED = { type: "rejected", title: "Отклонено" };
export const REMOVED = { type: "removed", title: "Удалено" };

export const VISIBILITY_MAP = [DRAFT, PUBLISH];

export const PUBLISHED_TYPE = { type: "published", title: "Публикации" };
export const DRAFT_TYPE = { type: "draft", title: "Черновики" };
export const ARTICLES_TABS = { PUBLISHED_TYPE, DRAFT_TYPE };
export const ARTICLES_TABS_MAP = [PUBLISHED_TYPE, DRAFT_TYPE];
