import styles from "./ScrollList.module.scss";
import { Link, NewProduct } from "components";
import EmptyProduct from "assets/img/empty_product.png";

const ScrollList = ({
  products,
  row = false,
  path = "/product/",
  isDefaultStyle = true,
  ...rest
}) => {
  let itemList = products.map((item) =>
    row ? (
      <Link
        key={`${item.name}-${Math.random()}`}
        path={`${path}${item.id}`}
        className={row ? styles.row : styles.column}
      >
        <li>
          <img
            style={{
              height: "27vh",
              filter: "drop-shadow(1.5vh 1vh 2vh rgba(0, 0, 0, 0.3))",
            }}
            src={item.pic_urls[0]?.pic_url || EmptyProduct}
            alt={item.name}
          />
        </li>
      </Link>
    ) : (
      <NewProduct
        item={item}
        key={`${item.name}-${Math.random()}`}
        isDefaultStyle={isDefaultStyle}
      />
    )
  );
  return (
    <div
      className={`${styles.scrollableContainer} ${
        !isDefaultStyle && styles.light
      }`}
      style={{ ...rest }}
    >
      <ul className={`${row ? styles.list_row : styles.list_column}`}>
        <div className={`${row ? styles.item_row : styles.item_column}`}>
          {itemList}
        </div>
      </ul>
    </div>
  );
};

export { ScrollList };
