import styles from "./MobileMenu.module.scss";
import { CHANGE_IS_OPEN_AUTH_MODAL } from "constants/actionType";
import store from "../../redux/stores";
import { Link } from "components";
import * as links from "constants/url";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import mobile_home_white from "assets/img/mobile_home_white.svg";
import mobile_home from "assets/img/mobile_home.svg";
import mobile_favourite from "assets/img/mobile_favourite.svg";
import mobile_favourite_white from "assets/img/mobile_favourite_white.svg";
import mobile_wiki from "assets/img/mobile_wiki.svg";
import logo from "assets/img/logo_white.png";
import mobile_cart from "assets/img/mobile_cart.svg";
import mobile_cart_white from "assets/img/mobile_cart_white.svg";
import mobile_profile from "assets/img/mobile_profile.svg";
import mobile_profile_white from "assets/img/mobile_profile_white.svg";
import { vhM } from "plugins/convertPXtoVHVW";

const MobileMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const countInBasket = useSelector((state) => state.countInBasket);

  return isValidWikiPath(pathname) ? (
    <div className={styles.content_back}>
      <div
        className={styles.content_back__button}
        onClick={() => navigate(getPathBeforeLastSlash(pathname))}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={vhM(19)}
          height={vhM(13)}
          viewBox="0 0 19 13"
          fill="none"
        >
          <path
            d="M0.469669 5.96967C0.176777 6.26256 0.176777 6.73744 0.469669 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903806 5.53553 0.903806 5.24264 1.1967L0.469669 5.96967ZM19 5.75L1 5.75V7.25L19 7.25V5.75Z"
            fill="#5F6B69"
          />
        </svg>
        Назад
      </div>
    </div>
  ) : (
    <div className={styles.content}>
      <div className={styles.icon}>
        <Link path={links.MAIN.url}>
          {window.location.pathname === links.MAIN.url ? (
            <img src={mobile_home_white} alt="Поиск" />
          ) : (
            <img src={mobile_home} alt="Поиск" />
          )}
        </Link>
      </div>
      <div className={styles.icon}>
        <Link path={links.FAVORITE.url}>
          {window.location.pathname === links.FAVORITE.url ? (
            <img src={mobile_favourite_white} alt="Избранное" />
          ) : (
            <img src={mobile_favourite} alt="Избранное" />
          )}
        </Link>
      </div>
      <div className={styles.icon}>
        <Link path={links.ARTICLES.url}>
          {window.location.pathname === links.ARTICLES.url ? (
            <img
              src={logo}
              alt="Шалфей.Вики"
              style={{ width: "8.5vw", height: "3vh" }}
            />
          ) : (
            <img src={mobile_wiki} alt="Шалфей.Вики" />
          )}
        </Link>
      </div>
      <div className={`${styles.icon} ${styles.btn_basket}`}>
        <Link path={links.BASKET.url}>
          {window.location.pathname === links.BASKET.url ? (
            <img src={mobile_cart_white} alt="Корзина" />
          ) : (
            <img src={mobile_cart} alt="Корзина" />
          )}
          {!!countInBasket && (
            <span className={styles.basket_count}>
              {countInBasket <= 99 ? countInBasket : "99+"}
            </span>
          )}
        </Link>
      </div>
      <div className={styles.icon}>
        <div
          onClick={() => {
            if (localStorage.getItem("token") === null) {
              store.dispatch({
                type: CHANGE_IS_OPEN_AUTH_MODAL,
                isOpenAuthModal: true,
              });
            } else {
              navigate(links.PROFILE.url + "?tab=trackOrder");
            }
          }}
        >
          {window.location.pathname.startsWith(links.PROFILE.url) ? (
            <img src={mobile_profile_white} alt="Профиль" />
          ) : (
            <img src={mobile_profile} alt="Профиль" />
          )}
        </div>
      </div>
    </div>
  );
};

const isValidWikiPath = (path) => {
  if (!path.startsWith("/wiki")) {
    return false;
  }
  const parts = path.split("/").filter((part) => part !== "");
  if (parts.length !== 3) {
    return false;
  }
  return !!(parts[1] && parts[2]);
};

const getPathBeforeLastSlash = (path) => {
  const lastSlashIndex = path.lastIndexOf("/");
  if (lastSlashIndex !== -1) {
    return path.substring(0, lastSlashIndex);
  }
  return "";
};

export { MobileMenu };
