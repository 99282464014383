import styles from "./Inputs.module.scss";

const ButtonCounter = ({
  isPlus = false,
  click,
  smallButton,
  title = "",
  isNewStyle = false,
}) => {
  return (
    <button
      title={title}
      className={
        isNewStyle
          ? isPlus
            ? styles.new_button_counter_plus
            : styles.new_button_counter_minus
          : smallButton
          ? `${styles.small_button_counter} ${
              isPlus
                ? styles.small_button_counter_plus
                : styles.small_button_counter_minus
            }`
          : `${styles.button_counter} ${
              isPlus ? styles.button_counter_plus : styles.button_counter_minus
            }`
      }
      onClick={click}
    />
  );
};

const CounterItem = ({
  count,
  change,
  smallButton = false,
  isNewStyle = false,
  ...rest
}) => {
  const plus = () => {
    change(count + 1);
  };

  const minus = () => {
    change(count - 1);
  };

  return (
    <div
      className={`${
        smallButton ? styles.small_counter_item : styles.counter_item
      }`}
      style={{ ...rest }}
    >
      <ButtonCounter
        title="Уменьшить"
        smallButton={smallButton}
        isNewStyle={isNewStyle}
        click={minus}
      />
      {count}
      <ButtonCounter
        title="Увеличить"
        smallButton={smallButton}
        isPlus
        isNewStyle={isNewStyle}
        click={plus}
      />
    </div>
  );
};

export { CounterItem };
