import styles from "./ModalImg.module.scss";
import slide_left from "assets/img/slide_left.png";
import slide_right from "assets/img/slide_right.png";
import close from "assets/img/close.png";
import { useState } from "react";

const ModalImg = ({ isOpen, onClose, pic_urls, main_img, create }) => {
  const [selectedImage, setSelectedImage] = useState(main_img);

  const next_photo = () => {
    if (selectedImage < pic_urls.length - 1) {
      setSelectedImage(selectedImage + 1);
    }
  };
  const previous_photo = () => {
    if (selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    }
  };

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.modal_wrapper}>
          <div className={styles.all_pic}>
            {pic_urls?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedImage(index);
                }}
                style={{
                  border: selectedImage === index ? "4px solid #20b900" : null,
                  borderRadius: selectedImage === index ? "18.9952px" : null,
                }}
              >
                <img
                  src={
                    create ? URL.createObjectURL(item.pic_url) : item.pic_url
                  }
                  alt={item?.alt || `Товар ${index}`}
                />
              </div>
            ))}
          </div>

          <div className={styles.main_pic}>
            <div className={styles.slide_left}>
              <img src={slide_left} alt="Влево" onClick={previous_photo} />
            </div>
            <div className={styles.main_img}>
              <img
                src={
                  create
                    ? URL.createObjectURL(pic_urls[selectedImage].pic_url)
                    : pic_urls[selectedImage].pic_url
                }
                alt={pic_urls[selectedImage]?.alt || `Товар ${selectedImage}`}
              />
            </div>
            <div className={styles.slide_right}>
              <img src={slide_right} alt="Вправо" onClick={next_photo} />
              <img
                src={close}
                alt="Закрыть"
                className={styles.close}
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export { ModalImg };
