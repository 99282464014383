import * as url from "../endpoints";
import * as ERROR from "constants/error";
import { instance, config } from "./createAxios";
import { removeToken } from "../token";

export const axiosFavorite = {
  async getMyFavorite(order_by) {
    try {
      const response = await instance.get(
        url.GET_FAVORITE(order_by) +
          `&city=${localStorage.getItem("city") || ""}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async addToFavorite(id) {
    try {
      const response = await instance.put(url.ADD_TO_FAVORITE(id), {}, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async removeFromFavorite(id) {
    try {
      const response = await instance.delete(
        url.REMOVE_FROM_FAVORITE(id),
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async addToFavoriteService(id) {
    try {
      const response = await instance.put(
        url.ADD_TO_FAVORITE_SERVICE(id),
        {},
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async removeFromFavoriteService(id) {
    try {
      const response = await instance.delete(
        url.REMOVE_FROM_FAVORITE_SERVICE(id),
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async changeFavoriteProductCount(id, count) {
    try {
      const response = await instance.put(
        url.CHANGE_FAVORITE_PRODUCT_COUNT(id),
        { count },
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getMyFavoriteForUnauth(params) {
    try {
      const response = await instance.post(url.GET_FAVORITE_FOR_UNAUTH(), {
        ...params,
        city: localStorage.getItem("city") || "",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};
