import { Purchases } from "../components/Order/Purchases";
import { TrackOrders } from "components/Deliveries/TrackOrders";
import { Notifications } from "components/Deliveries/Notifications";
import { Favorite } from "components/Favorite/Favorite";
import { MyReviews } from "components/CustomerReviews/MyReviews";
import { SellerProfileUpdate } from "components/ProfileInfo/SellerProfileUpdate";
import { ProfileInfo } from "components/ProfileInfo/ProfileInfo";
import { MainAnalytics } from "pages/Analytics/MainAnalytics";
import { MobileChat } from "components/Chat/MobileChat";

export const MAIN = {
  title: "Главная",
  type: "main",
  component: <Notifications />,
};
export const UPDATE = {
  title: "Редактировать профиль",
  type: "update",
  component: <ProfileInfo />,
};
export const HISTORY_OF_ORDERS = {
  title: "Покупки",
  type: "historyOfOrders",
  component: <Purchases />,
};
export const FAVORITE = {
  title: "Избранное",
  type: "favorite",
  component: <Favorite />,
};
export const REVIEWS = {
  title: "Мои отзывы",
  type: "reviws",
  component: <MyReviews />,
};
const TRACK_ORDER = {
  title: "Доставки",
  type: "trackOrder",
  component: <TrackOrders />,
};

export const SELLER_UPDATE = {
  title: "Редактировать профиль",
  type: "update",
  component: <SellerProfileUpdate />,
};
export const ANALYTICS = {
  title: "Аналитика",
  type: "analytics",
  component: <MainAnalytics />,
};
export const MOBILE_CHAT = {
  title: "Чаты",
  type: "chats",
  component: <MobileChat />,
};

export const userButtons = [
  MAIN,
  UPDATE,
  TRACK_ORDER,
  HISTORY_OF_ORDERS,
  FAVORITE,
  REVIEWS,
];
export const modalUserButtons = [
  HISTORY_OF_ORDERS,
  TRACK_ORDER,
  FAVORITE,
  REVIEWS,
];
export const sellerButtons = [ANALYTICS, SELLER_UPDATE];
