import React, { useEffect } from "react";
import styles from "./Statics.module.scss";
import { Helmet } from "react-helmet";
import step1 from "assets/img/staticPages/step1.png";
import step2 from "assets/img/staticPages/step2.png";
import step3 from "assets/img/step2.png";
import step4 from "assets/img/staticPages/step4.png";
import step5 from "assets/img/staticPages/step5.png";
import step6 from "assets/img/staticPages/step6.png";
import step7 from "assets/img/staticPages/step7.png";
import step8 from "assets/img/staticPages/step8.png";

const MakeOrder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={styles.make_order}>
      <Helmet title="Как сделать заказ ?">
        <meta name="description" content="Shalfey : Как сделать заказ ?" />
        <link rel="canonical" href="/how_to_make_order" />
      </Helmet>
      <div className={styles.heading_block}>
        <h1 className={styles.heading}>Как сделать заказ ?</h1>
        <p>Как сделать заказ на нашем маркетплейсе</p>
        <p>
          Сделать заказ на нашем маркетплейсе народной медицины — это просто и
          удобно. Следуйте этой пошаговой инструкции, чтобы получить нужные вам
          продукты и начать заботиться о своем здоровье.
        </p>
      </div>
      <div className={styles.step1}>
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 1</h3>
          <h2>Вход или регистрация</h2>
          <p>
            Если у вас уже есть аккаунт на нашем маркетплейсе, просто войдите в
            него. Если нет, вам нужно зарегистрироваться. Для этого:
          </p>
          <p>
            Нажмите на кнопку "Войти" или "Регистрация" в верхнем правом углу
            страницы.
          </p>
          <p>
            Заполните необходимые данные: имя, адрес электронной почты и пароль.
          </p>
          <p>
            Нажмите "Зарегистрироваться" и следуйте инструкциям для
            подтверждения вашего аккаунта, если это требуется.
          </p>
        </div>
        <img src={step1} alt="ШАГ 1" />
      </div>
      <div className={styles.step2}>
        <img src={step2} alt="ШАГ 2" />
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 2</h3>
          <h2>Поиск продуктов</h2>
          <p>
            Используйте поисковую строку вверху страницы, чтобы найти
            интересующий вас продукт. Вы также можете найти товары, перейдя по
            категориям нашего каталога. Когда вы нашли нужный продукт, нажмите
            на него, чтобы узнать подробности.
          </p>
        </div>
      </div>
      <div className={styles.step1}>
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 3</h3>
          <h2>Добавление в корзину</h2>
          <p>
            На странице продукта выберите нужные вам опции (например, вес,
            количество).
          </p>
          <p>
            Нажмите кнопку "Добавить в корзину". Продукт автоматически добавится
            в вашу корзину покупок.
          </p>
        </div>
        <img src={step3} alt="ШАГ 3" style={{ transform: "scaleX(-1)" }} />
      </div>
      <div className={styles.step2}>
        <img src={step4} alt="ШАГ 4" />
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 4</h3>
          <h2>Просмотр корзины</h2>
          <p>
            В правом верхнем углу страницы найдите иконку корзины или "Корзина"
            в навигационном меню. Нажмите на эту иконку, чтобы перейти к вашей
            корзине.
          </p>
        </div>
      </div>
      <div className={styles.step1}>
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 5</h3>
          <h2>Оформление заказа</h2>
          <p>
            В корзине вы увидите список добавленных продуктов и их количество.
            Проверьте, что все выбранные продукты и&nbsp;количество верны.
          </p>
          <p>
            Нажмите кнопку "Оформить заказ" или "Перейти к&nbsp;оформлению",
            чтобы перейти к заполнению данных для доставки и оплаты.
          </p>
        </div>
        <img src={step5} alt="ШАГ 5" />
      </div>
      <div className={styles.step2}>
        <img src={step6} alt="ШАГ 6" />
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 6</h3>
          <h2>Заполнение данных для доставки и оплаты</h2>
          <p>
            Заполните необходимую информацию для доставки: адрес, имя,
            контактные данные и другие детали.
          </p>
          <p>
            Выберите удобный способ оплаты. В зависимости от ваших предпочтений,
            это может быть оплата банковской картой, электронным кошельком и
            т.д.
          </p>
        </div>
      </div>
      <div className={styles.step1}>
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 7</h3>
          <h2>Подтверждение заказа</h2>
          <p>
            Пересмотрите введенные данные для доставки и оплаты, удостоверьтесь,
            что все верно.
          </p>
          <p>
            Нажмите кнопку "Подтвердить заказ" или "Оплатить",
            в&nbsp;зависимости от выбранного способа оплаты.
          </p>
        </div>
        <img src={step7} alt="ШАГ 7" />
      </div>
      <div className={styles.step2}>
        <img src={step8} alt="ШАГ 8" />
        <div className={styles.content}>
          <h3 className={styles.title}>ШАГ 8</h3>
          <h2>Получение подтверждения</h2>
          <p>
            После успешной оплаты, вы получите уведомление на&nbsp;странице, что
            ваш заказ успешно оформлен.
          </p>
          <p>
            Также вы получите подтверждение заказа на вашу электронную почту.
          </p>
          <p>
            Если у вас возникнут вопросы или сложности при&nbsp;оформлении
            заказа, не стесняйтесь обращаться к&nbsp;нашей службе поддержки.
          </p>
          <p>
            Мы ценим ваш выбор и рады помочь вам на вашем пути к&nbsp;здоровью и
            благополучию!
          </p>
        </div>
      </div>
    </section>
  );
};

export { MakeOrder };
