import React from "react";

const Textarea = ({
  text = "",
  rows = 5,
  change,
  placeholder = "",
  ...rest
}) => {
  return (
    <textarea
      rows={rows}
      defaultValue={text}
      placeholder={placeholder}
      style={{ ...rest }}
      onChange={(event) => change(event)}
    />
  );
};

export { Textarea };
