import styles from "./ModalChat.module.scss";
import arrow from "assets/img/arrow.svg";
import quill_chat from "assets/img/quill_chat.svg";
import btn_close from "assets/img/btn_close.svg";
import { useState, useEffect } from "react";
import { axiosChat } from "plugins/axios";

const ModalChat = ({ isOpen, onClose }) => {
  const [question, setQuestion] = useState("");
  const [chat, setChat] = useState([]);
  const getChat = async () => {
    if (localStorage.getItem("token") !== null) {
      let result = await axiosChat.getChat();
      setChat(result.results?.questions || []);
    }
  };

  useEffect(() => {
    getChat();
  }, []);

  let messageList = chat?.slice(0).map((messages, index) => (
    <div key={messages.id} style={{ width: "21vw" }}>
      <div className={styles.question}>{messages.question}</div>
      {messages.answer === "" || messages.answer === null ? null : (
        <div className={styles.answer}>{messages.answer}</div>
      )}
    </div>
  ));
  const addNewMassage = async (e) => {
    if (question && question.trim() !== "") {
      await axiosChat.postQuestion({ question });
    }
    getChat();
    setQuestion("");
  };

  return (
    isOpen && (
      <div className={styles.modal_wrapper}>
        <div className={styles.modal_content}>
          <div>
            <img
              src={quill_chat}
              alt="Заголовок"
              style={{ height: "34px", margin: "0 15px" }}
            />
          </div>
          <div className={styles.title}>
            <h1>Напишите ваше сообщение</h1>
            <h2>Операторы онлайн!</h2>
          </div>
          <img
            src={btn_close}
            alt="Закрыть"
            className={styles.close}
            onClick={onClose}
          />
        </div>
        <div className={styles.messageList}>{messageList}</div>
        <div className={styles.for_input}>
          <input
            type="text"
            placeholder="Введите ваше сообщение"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <img
            src={arrow}
            alt="Input"
            className={styles.input_button}
            onClick={addNewMassage}
          />
        </div>
      </div>
    )
  );
};

export { ModalChat };
