import * as url from "../endpoints";
import { instance, config } from "./createAxios";

export const axiosQuestionnaire = {
  async getIllnesses() {
    try {
      const response = await instance.get(url.GET_ILLNESSES());
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getSymptoms() {
    try {
      const response = await instance.get(url.GET_SYMPTOMS());
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getAges() {
    try {
      const response = await instance.get(url.GET_AGES());
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getIllnessesResult(params) {
    try {
      const response = await instance.get(
        url.GET_ILLNESSES_RESULT() +
          `?city=${localStorage.getItem("city") || ""}` +
          params,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getSymptomsResult(params) {
    try {
      const response = await instance.get(
        url.GET_SYMPTOMS_RESULT() +
          `?city=${localStorage.getItem("city") || ""}` +
          params,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getAgesResult(params) {
    try {
      const response = await instance.get(
        url.GET_AGES_RESULT() +
          `?city=${localStorage.getItem("city") || ""}` +
          params,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
