import { Button } from "components/Inputs";
import styles from "./ModalWarning.module.scss";
import btn_close from "assets/img/btn_close.svg";
import store from "../../redux/stores";
import { CHANGE_IS_OPEN_AUTH_MODAL } from "constants/actionType";

const ModalWarning = ({ isOpen, onClose }) =>
  isOpen && (
    <div>
      <div className={styles.modal_wrapper}>
        <img
          src={btn_close}
          alt="Закрыть"
          className={styles.close}
          onClick={onClose}
        />
        <h1>
          Для того, чтобы написать сообщение, пройдите авторизацию на сайте
        </h1>
        <Button
          text="Войти"
          padding="1.2vh 0"
          click={() => {
            store.dispatch({
              type: CHANGE_IS_OPEN_AUTH_MODAL,
              isOpenAuthModal: true,
            });
            onClose();
          }}
        />
      </div>
    </div>
  );

export { ModalWarning };
