import styles from "./Default.module.scss";
import { Header, Footer, BtnChat, MobileMenu } from "components";
import { Outlet } from "react-router-dom";

const DefaultLayout = ({ bgColor = "#fff" }) => {
  return (
    <>
      <Header />
      <main className={styles.main} style={{ backgroundColor: bgColor }}>
        <Outlet />
        <BtnChat />
        <MobileMenu />
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  );
};
export { DefaultLayout };
