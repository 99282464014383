import React from "react";
import styles from "./Input.module.scss";
import { useState } from "react";

const Input = ({
  light = false,
  green = false,
  white = false,

  type = "text",
  required = false,
  requiredText = "",
  disable = false,
  label,
  colorLabel,
  fontSizeLabel = "32px",
  lineHeightLabel = "44px",
  lineHeight = "27px",
  name,
  fontColor = "#20B900",
  step,
  color = "#65735F",
  placeholder,
  multiple,
  prepend = null,
  value,
  errorMessage,
  inputWidth,
  inputStyles = {},
  prependStyles = {},
  onChange,
  onKeyPress,
  ...rest
}) => {
  const htmlFor = `${type}-${Math.random()}`;
  const [focus, setFocus] = useState(false);

  return (
    <div style={{ width: inputWidth, ...rest }}>
      {label && (
        <label
          className={styles.label}
          htmlFor={htmlFor}
          style={{
            color: colorLabel || "",
            fontSize: fontSizeLabel,
            lineHeight: lineHeightLabel,
          }}
        >
          {required && <span>* </span>}
          {label}
        </label>
      )}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      <div
        className={`${styles.Input_class} ${
          prepend !== null ? styles.padding : ""
        } ${light ? styles.light : styles.Input} ${
          green ? styles.green : styles.Input
        } ${white ? styles.input_white : styles.Input}`}
      >
        {requiredText && (
          <div id="password_required" className={styles.required}>
            {requiredText}
          </div>
        )}
        <input
          type={type}
          name={name}
          step={step}
          multiple={multiple}
          id={htmlFor}
          placeholder={placeholder}
          value={value}
          disabled={disable}
          style={{
            ...inputStyles,
            "--placeholder-color": color,
            lineHeight,
            color: fontColor,
            borderTop: errorMessage && "1px solid #E84B4B",
            borderBottom: errorMessage && "1px solid #E84B4B",
            borderLeft: errorMessage && "1px solid #E84B4B",
            borderRight:
              errorMessage && `${prepend ? "0" : "1"}px solid #E84B4B`,
          }}
          onChange={onChange}
          onKeyUp={onKeyPress}
          onFocus={(ev) => setFocus(true)}
          onBlur={(ev) => setFocus(false)}
        />
        <div
          id="prepend"
          className={`${light ? styles.light_prepend : ""} ${
            focus && light ? styles.focus : ""
          } ${white ? styles.white_prepend : ""} ${
            focus && white ? styles.focus_white : ""
          } ${styles.prepend} ${green ? styles.green_prepend : ""}`}
          style={{
            ...prependStyles,
            borderTop: errorMessage && "1px solid #E84B4B",
            borderBottom: errorMessage && "1px solid #E84B4B",
            borderRight: errorMessage && "1px solid #E84B4B",
          }}
        >
          {prepend}
        </div>
      </div>
    </div>
  );
};

export { Input };
