import { getNoun } from "./getNoun";

export const getFormattedDate = (
  date = "",
  showPrevYear = true,
  showTime = false
) => {
  let dateTime = date.split(", ");
  let arrDate = dateTime[0].split("-");
  if (arrDate.length === 3) {
    let day = arrDate[0];
    let month = arrDate[1];
    let year = Number(arrDate[2]);
    let curYear = new Date().getFullYear();

    return `${day} ${
      [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ][month - 1]
    } ${showPrevYear && curYear !== year ? year : ""}${
      showTime && `, ${dateTime[1].slice(0, 5)}`
    }`;
  } else {
    return "";
  }
};

export const parseDate = (oldDateStr) => {
  const oldDate = new Date(oldDateStr);
  const today = new Date();

  const secondsDiff = Math.floor((today - oldDate) / 1000);
  const minutesDiff = Math.floor(secondsDiff / 60);
  const hoursDiff = Math.floor(minutesDiff / 60);

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  if (secondsDiff < 60) {
    return `${secondsDiff} ${getNoun(
      secondsDiff,
      "секунда",
      "секунды",
      "секунд"
    )} назад`;
  } else if (minutesDiff < 60) {
    return `${minutesDiff} ${getNoun(
      minutesDiff,
      "минута",
      "минуты",
      "минут"
    )} назад`;
  } else if (hoursDiff < 24) {
    return `${hoursDiff} ${getNoun(hoursDiff, "час", "часа", "часов")} назад`;
  } else if (oldDate.getFullYear() !== today.getFullYear()) {
    return `${oldDate.getDate()} ${
      months[oldDate.getMonth()]
    } ${oldDate.getFullYear()}`;
  } else {
    return `${oldDate.getDate()} ${months[oldDate.getMonth()]}`;
  }
};
