import styles from "./Recommendations.module.scss";
import { Link, ScrollList } from "components";

const Recommendations = ({
  title,
  products,
  pathRecommendation,
  pathItem,
  isDefaultStyle,
  onClick,
  ...rest
}) => {
  return (
    <div className={styles.recs} style={{ ...rest }}>
      <Link
        className={styles.title}
        path={pathRecommendation}
        onClick={onClick}
      >
        {title}
      </Link>
      <ScrollList
        products={products}
        path={pathItem}
        isDefaultStyle={isDefaultStyle}
      />
      <Link
        className={styles.buttontext}
        path={pathRecommendation}
        onClick={onClick}
      >
        смотреть все&nbsp;&nbsp;
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1.5vh"
          viewBox="0 0 12 13"
          fill="none"
        >
          <path
            d="M3.05859 1.1084C3.05859 1.1084 6.60526 4.26389 8.56723 6.1399C8.77062 6.33438 8.76507 6.66007 8.55763 6.85023C6.49347 8.74237 5.22483 9.90529 3.05859 11.891"
            stroke="#5F6B69"
            strokeWidth="0.8"
            strokeLinecap="round"
          />
        </svg>
      </Link>
    </div>
  );
};

export { Recommendations };
