import * as statuses from "constants/status";

export const checkError = (res) => {
  switch (res.code) {
    case statuses.ERR_BAD_RESPONSE.code:
      return { isError: true, messageError: statuses.ERR_BAD_RESPONSE.message };

    case statuses.ERR_NETWORK.code:
      return { isError: true, messageError: statuses.ERR_NETWORK.message };

    case statuses.ERR_BAD_REQUEST.code:
      if (res?.response.data.code === statuses.TOKEN_NOT_VALID.code)
        return {
          isError: true,
          messageError: statuses.TOKEN_NOT_VALID.message,
        };
      return { isError: true, messageError: statuses.ERR_BAD_REQUEST.message };

    default:
      return { isError: false, messageError: "" };
  }
};
