import React, { useState, useEffect } from "react";
import styles from "./Wiki.module.scss";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { Button, Checkbox } from "components";
import * as visibilityArticle from "constants/visibilityArticle";
import {
  TYPE_STYLE_TEXT_MAP,
  typeStyleText,
  ACTIONS_BLOCK_MAP,
  ACTIONS_BLOCK,
} from "constants/articleStylesAndActions";
import { useOutsideClick } from "hooks";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { axiosArticle } from "plugins/axios";
import { VISIBILITY_MAP } from "constants/visibilityArticle";
import { useNavigate } from "react-router-dom";
import { ARTICLES } from "constants/url";
import { useParams } from "react-router-dom";
import { checkError } from "plugins/checkError";
import { useMediaQuery } from "react-responsive";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  for (let i = result.length; i > 0; i--) {
    result[i - 1].order = i;
  }

  return result;
};
const CreateArticle = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const params = useParams();
  const [error, setError] = useState(false);

  const [info, setInfo] = useState({
    name: "",
    short_desc: "",
    cover_url: "",
    visibility: visibilityArticle.DRAFT.type,
  });
  const [blocks, setBlocks] = useState([
    { id: 0, order: 1, type: typeStyleText.TEXT.type, text: "" },
  ]);
  const [symptoms, setSymptoms] = useState([]);
  const [typesOfMedicine, setTypesOfMedicine] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});

  useEffect(() => {
    const getArticle = async () => {
      let resSymptoms = await axiosArticle.getSymptoms();
      let medicine_worlds = Array.from(resSymptoms?.medicine_worlds || []);
      setSymptoms(resSymptoms?.symptoms || []);
      if (params.id) {
        let res = await axiosArticle.getMyArticle(params.id);
        let { isError, messageError } = checkError(res);
        if (isError) {
          setError(true);
          return;
        }
        setInfo({
          name: res?.name || "",
          short_desc: res?.short_desc || "",
          cover_url: res?.cover_url || "",
          visibility:
            res?.status === visibilityArticle.DRAFT.type
              ? visibilityArticle.DRAFT.type
              : visibilityArticle.PUBLISH.type,
        });
        medicine_worlds = medicine_worlds.map((medicine) => ({
          ...medicine,
          isSelected: res.types_of_medicine.includes(medicine.id),
        }));
        let newSelectCategory =
          resSymptoms?.symptoms.find((category) =>
            category.symptoms.some((symptom) => symptom.id === res.symptoms[0])
          ) || {};
        if (newSelectCategory?.symptoms)
          newSelectCategory.symptoms = newSelectCategory.symptoms.map(
            (symptom) => ({
              ...symptom,
              isSelected: res.symptoms.includes(symptom.id),
            })
          );
        setSelectCategory(newSelectCategory);
        let newBlocks = res.modules.map((module, index) => ({
          id: index,
          order: index + 1,
          type: module.module_type,
          text: module?.text?.replaceAll("<br>", "\n") || "",
          image: module?.image_url || "",
        }));
        setBlocks(newBlocks.length ? newBlocks : blocks);
      }
      setTypesOfMedicine(medicine_worlds || []);
    };
    getArticle();
    window.scrollTo(0, 0);
  }, []);

  const changeInfo = (e) => {
    if (e.target) {
      if (e.target.name === "cover_url")
        setInfo({ ...info, [e.target.name]: e.target.files[0] || "" });
      else setInfo({ ...info, [e.target.name]: e.target.value });
    }
  };
  const changeSelectCategory = (id) => {
    let newSelectCategory = symptoms.find((symptom) => symptom.id === id);
    newSelectCategory.symptoms = newSelectCategory.symptoms.map((symptom) => ({
      ...symptom,
      isSelected: false,
    }));
    setSelectCategory(newSelectCategory);
  };
  const changeMedicineWorlds = (id) => {
    setTypesOfMedicine(
      typesOfMedicine.map((medicine) =>
        medicine.id === id
          ? { ...medicine, isSelected: !medicine.isSelected }
          : medicine
      )
    );
  };
  const changeSelectSymptoms = (id, newValue) => {
    let newSelectCategory = {
      ...selectCategory,
      symptoms: selectCategory.symptoms.map((symptom) =>
        symptom.id === id ? { ...symptom, isSelected: newValue } : symptom
      ),
    };
    setSelectCategory(newSelectCategory);
  };
  const changeTextBlock = (id, e) => {
    setBlocks(
      blocks.map((block) =>
        block.id === id ? { ...block, text: e.target.value } : block
      )
    );
  };
  const changeTypeBlock = (id, newType) => {
    setBlocks(
      blocks.map((block) =>
        block.id === id ? { ...block, type: newType, image: "" } : block
      )
    );
  };
  const changeImgBlock = (id, e) => {
    setBlocks(
      blocks.map((block) =>
        block.id === id
          ? { ...block, image: e?.target?.files[0] || "", text: "" }
          : block
      )
    );
  };
  const addBlock = () => {
    setBlocks([
      ...blocks,
      {
        id:
          blocks.reduce((acc, curr) => (acc.id > curr.id ? acc : curr)).id + 1,
        order: blocks.length + 1,
        type: typeStyleText.TEXT.type,
        text: "",
      },
    ]);
  };
  const checkProperties = (obj) => {
    for (let key in obj) {
      if (obj[key] === null || obj[key] == "" || obj[key] == []) return key;
    }
    return false;
  };
  const createArticle = async () => {
    let elements = document.getElementsByClassName(styles.custom_error);
    for (let i = elements.length - 1; i >= 0; i--) {
      elements[i].classList.remove(styles.custom_error);
    }
    let emptyProp = checkProperties(info);
    if (emptyProp) {
      let field = document.getElementsByName(emptyProp)[0];
      let span = document.getElementById(`span_${emptyProp}`);
      field.classList.add(styles.custom_error);
      span.classList.add(styles.custom_error);
      field.focus();
    } else if (
      typesOfMedicine.length &&
      !typesOfMedicine.some((medicine) => medicine.isSelected)
    ) {
      let field = document.getElementsByName("medicineWorlds")[0];
      let span = document.getElementById("span_medicineWorlds");
      field.classList.add(styles.custom_error);
      span.classList.add(styles.custom_error);
    } else if (!Object.keys(selectCategory).length) {
      let field = document.getElementsByName("categories")[0];
      let span = document.getElementById("span_categories");
      field.classList.add(styles.custom_error);
      span.classList.add(styles.custom_error);
    } else if (
      selectCategory.symptoms.length &&
      !selectCategory.symptoms.some((symptom) => symptom.isSelected)
    ) {
      let field = document.getElementsByName("symptoms")[0];
      let span = document.getElementById("span_symptoms");
      field.classList.add(styles.custom_error);
      span.classList.add(styles.custom_error);
    } else {
      let res = null;
      if (params.id) {
        res = await axiosArticle.updateArticle(params.id, {
          ...info,
          blocks: blocks,
          symptoms: selectCategory?.symptoms?.length
            ? selectCategory.symptoms
                .filter((symptom) => symptom.isSelected)
                .map((symptom) => symptom.id)
                .join(",")
            : selectCategory.id,
          types_of_medicine: typesOfMedicine
            .filter((medicine) => medicine.isSelected)
            .map((symptom) => symptom.id)
            .join(","),
        });
      } else {
        res = await axiosArticle.createArticle({
          ...info,
          blocks: blocks,
          symptoms: selectCategory?.symptoms?.length
            ? selectCategory.symptoms
                .filter((symptom) => symptom.isSelected)
                .map((symptom) => symptom.id)
                .join(",")
            : selectCategory.id,
          types_of_medicine: typesOfMedicine
            .filter((medicine) => medicine.isSelected)
            .map((symptom) => symptom.id)
            .join(","),
        });
      }
      if (!res) navigate(ARTICLES.url);
    }
  };
  const doActionBlock = (id, actionType) => {
    let block = blocks.find((block) => block.id === id);
    if (actionType === ACTIONS_BLOCK.UP_BLOCK.type) {
      if (block.order - 1 <= 0) {
        return;
      } else {
        const items = reorder(blocks, block.order - 1, block.order - 2);
        setBlocks(items);
      }
    } else if (actionType === ACTIONS_BLOCK.DOWN_BLOCK.type) {
      if (block.order >= blocks.length) {
        return;
      } else {
        const items = reorder(blocks, block.order - 1, block.order);
        setBlocks(items);
      }
    } else if (actionType === ACTIONS_BLOCK.DUPLICATE_BLOCK.type) {
      let newBlocks = Array.from(blocks);
      newBlocks.splice(block.order, 0, {
        ...block,
        id: Math.max(...blocks.map((i) => i.id)) + 1,
      });
      for (let i = newBlocks.length; i > 0; i--) {
        newBlocks[i - 1].order = i;
      }
      setBlocks(newBlocks);
    } else if (actionType === ACTIONS_BLOCK.DELETE_BLOCK.type) {
      if (blocks.length > 1) {
        let newBlocks = Array.from(blocks);
        newBlocks.splice(block.order - 1, 1);
        for (let i = newBlocks.length; i > 0; i--) {
          newBlocks[i - 1].order = i;
        }
        setBlocks(newBlocks);
      }
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      blocks,
      result.source.index,
      result.destination.index
    );

    setBlocks(items);
  };

  return error ? (
    <div className={styles.main_error}>Эту статью нельзя редактировать</div>
  ) : (
    <div className={styles.create_article}>
      <div className={styles.left_block}>
        <CustomInputText
          label="Название"
          value={info.name}
          name="name"
          change={changeInfo}
        />
        <CustomInputText
          label="Краткое описание"
          value={info.short_desc}
          name="short_desc"
          change={changeInfo}
        />
        <CustomInputImage
          label="Изображение"
          value={info.cover_url}
          name="cover_url"
          change={changeInfo}
        />
        <CustomSelectVisibility
          label="Видимость"
          value={info.visibility}
          name="visibility"
          change={changeInfo}
        />
        <CustomSelectSymptoms
          label="Выбрать мир Медицины"
          symptoms={typesOfMedicine}
          name="medicineWorlds"
          change={changeMedicineWorlds}
        />
        <CustomSelectCategory
          label="Категория"
          categories={symptoms}
          value={selectCategory.id}
          name="categories"
          change={changeSelectCategory}
        />
        <CustomSelectSymptoms
          label="Симптомы"
          symptoms={selectCategory?.symptoms || []}
          name="symptoms"
          change={changeSelectSymptoms}
        />
      </div>
      <div className={styles.right_block}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.blocks}
              >
                {blocks.length &&
                  blocks
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((block, index) => (
                      <Block
                        key={index}
                        index={index}
                        block={block}
                        changeTextBlock={changeTextBlock}
                        changeTypeBlock={changeTypeBlock}
                        changeImgBlock={changeImgBlock}
                        doActionBlock={doActionBlock}
                      />
                    ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={styles.actions}>
          <Button
            text="Добавить"
            bgColor="#F2F7F4"
            color="#20B900"
            borderRadius={isMobile ? vhM(15) : vh(15)}
            padding={isMobile ? `${vhM(17)} ${vwM(34)}` : `${vh(17)} ${vw(34)}`}
            width="100%"
            fontSize={isMobile ? vhM(16) : vh(16)}
            lineHeight={isMobile ? vhM(19) : vh(19)}
            click={addBlock}
          />
          <Button
            text={params.id ? "Изменить" : "Сохранить"}
            bgColor="#20B900"
            color="#ffffff"
            borderRadius={isMobile ? vhM(15) : vh(15)}
            padding={isMobile ? `${vhM(17)} ${vwM(34)}` : `${vh(17)} ${vw(34)}`}
            width="100%"
            fontSize={isMobile ? vhM(16) : vh(16)}
            lineHeight={isMobile ? vhM(19) : vh(19)}
            click={createArticle}
          />
        </div>
      </div>
    </div>
  );
};

const Block = ({
  index,
  block,
  changeTextBlock,
  changeTypeBlock,
  changeImgBlock,
  doActionBlock,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [openStyleEditor, setOpenStyleEditor] = useState(false);
  const [openActionsBlock, setOpenActionsBlock] = useState(false);
  const refStyleEditor = useOutsideClick(() => setOpenStyleEditor(false));
  const refActionsBlock = useOutsideClick(() => setOpenActionsBlock(false));

  const changeImg = (e) => {
    const img = document.getElementById(`block_img_${block.id}`);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      img.src = fileReader.result;
    };
    if (e.target?.files.length) fileReader.readAsDataURL(e.target.files[0]);
    changeImgBlock(block.id, e);
  };
  return (
    <Draggable
      key={block.id}
      draggableId={`block_drag_${block.id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={styles.block}
          ref={provided.innerRef}
          style={{ ...provided.draggableProps.style }}
          {...provided.draggableProps}
        >
          <div className={styles.block__actions}>
            <div className={styles.block__actions__styles}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={isMobile ? vhM(30) : vh(30)}
                height={isMobile ? vhM(30) : vh(30)}
                viewBox="0 0 30 30"
                fill="none"
                className={openStyleEditor ? styles.open : null}
                onClick={() => setOpenStyleEditor(!openStyleEditor)}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7135 6.42815C15.7135 6.03366 15.3937 5.71387 14.9992 5.71387C14.6048 5.71387 14.285 6.03366 14.285 6.42815V14.2855H6.42815C6.03366 14.2855 5.71387 14.6053 5.71387 14.9998C5.71387 15.3943 6.03366 15.7141 6.42815 15.7141H14.285V23.571C14.285 23.9655 14.6048 24.2853 14.9992 24.2853C15.3937 24.2853 15.7135 23.9655 15.7135 23.571V15.7141H23.571C23.9655 15.7141 24.2853 15.3943 24.2853 14.9998C24.2853 14.6053 23.9655 14.2855 23.571 14.2855H15.7135V6.42815Z"
                  fill="#5F6B69"
                />
              </svg>
              <ul
                className={openStyleEditor ? styles.open : null}
                ref={refStyleEditor}
              >
                {TYPE_STYLE_TEXT_MAP.map((e, index) => (
                  <li
                    key={index}
                    className={e.type === block.type ? styles.select : null}
                    onClick={() => {
                      setOpenStyleEditor(false);
                      changeTypeBlock(block.id, e.type);
                    }}
                  >
                    {e.icon} {e.title}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.block__actions__drag_and_drop}>
              <span {...provided.dragHandleProps}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? vhM(30) : vh(30)}
                  height={isMobile ? vhM(30) : vh(30)}
                  viewBox="0 0 30 30"
                  fill="none"
                  onClick={() => setOpenActionsBlock(!openActionsBlock)}
                >
                  <circle cx="15" cy="9" r="1.5" fill="#5F6B69" />
                  <circle cx="15" cy="15" r="1.5" fill="#5F6B69" />
                  <circle cx="15" cy="21" r="1.5" fill="#5F6B69" />
                </svg>
              </span>
              <ul
                className={openActionsBlock ? styles.open : null}
                ref={refActionsBlock}
              >
                {ACTIONS_BLOCK_MAP.map((e, index) => (
                  <li
                    key={index}
                    className={e.type === block.type ? styles.select : null}
                    onClick={() => {
                      setOpenActionsBlock(false);
                      doActionBlock(block.id, e.type);
                    }}
                  >
                    {e.icon} {e.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {block.type === typeStyleText.TEXT.type ? (
            <textarea
              className={styles.text}
              placeholder="Введите текст..."
              rows={block.text.split("\n").length || 1}
              value={block.text}
              style={{
                height:
                  +vh(16).slice(0, -2) +
                  vh(22).slice(0, -2) * block.text.split("\n").length +
                  "vh",
                backgroundColor: block.text === "" ? "unset" : "#ffffff",
              }}
              onInput={(element) => {
                element.target.rows = element.target.value.split("\n").length;
                element.target.style.height =
                  +vh(16).slice(0, -2) +
                  vh(22).slice(0, -2) * element.target.rows +
                  "vh";
              }}
              onChange={(e) => changeTextBlock(block.id, e)}
            />
          ) : block.type === typeStyleText.HEADING.type ? (
            <textarea
              className={styles.heading}
              placeholder="Введите текст..."
              rows={block.text.split("\n").length || 1}
              value={block.text}
              style={{
                height:
                  +vh(16).slice(0, -2) +
                  vh(45).slice(0, -2) * block.text.split("\n").length +
                  "vh",
                backgroundColor: block.text === "" ? "unset" : "#ffffff",
              }}
              onInput={(element) => {
                element.target.rows = element.target.value.split("\n").length;
                element.target.style.height =
                  +vh(16).slice(0, -2) +
                  vh(45).slice(0, -2) * element.target.rows +
                  "vh";
              }}
              onChange={(e) => changeTextBlock(block.id, e)}
            />
          ) : block.type === typeStyleText.QUOTE.type ? (
            <textarea
              className={styles.quote}
              placeholder="Введите текст..."
              rows={block.text.split("\n").length || 1}
              value={block.text}
              style={{
                height:
                  +vh(16).slice(0, -2) +
                  vh(30).slice(0, -2) * block.text.split("\n").length +
                  "vh",
                backgroundColor: block.text === "" ? "unset" : "#ffffff",
              }}
              onInput={(element) => {
                element.target.rows = element.target.value.split("\n").length;
                element.target.style.height =
                  +vh(16).slice(0, -2) +
                  vh(30).slice(0, -2) * element.target.rows +
                  "vh";
              }}
              onChange={(e) => changeTextBlock(block.id, e)}
            />
          ) : block.type === typeStyleText.DIVIDER.type ? (
            <div className={styles.divider} />
          ) : block.type === typeStyleText.IMAGE.type ? (
            <label className={styles.image}>
              <input type="file" onChange={changeImg} />
              {!block?.image && (
                <div className={styles.image__button}>Загрузить файл</div>
              )}
              <img
                src={
                  typeof block?.image === "string"
                    ? block?.image
                    : URL.createObjectURL(block?.image)
                }
                id={`block_img_${block.id}`}
                alt="Изображение"
                width={vw(1047)}
                height={vh(520)}
                style={{ display: block?.image ? "inline" : "none" }}
              />
            </label>
          ) : (
            <></>
          )}
        </div>
      )}
    </Draggable>
  );
};

const CustomInputText = ({ label = "", name = "", value = "", change }) => {
  return (
    <div className={styles.custom_input}>
      <span id={`span_${name}`}>{label}</span>
      <input
        type="text"
        name={name}
        value={value}
        onChange={(e) => change(e)}
      />
    </div>
  );
};
const CustomInputImage = ({ label = "", name = "", value = "", change }) => {
  const changeImage = (e) => {
    const img = document.getElementById(name);
    img.innerText = e?.target?.files.length
      ? e?.target?.files[0]?.name || ""
      : value?.name || value;
    change(e);
  };
  return (
    <div className={styles.custom_input}>
      <span id={`span_${name}`}>{label}</span>
      <label
        className={styles.custom_input__image}
        onClick={() => {
          const img = document.getElementById(name);
        }}
      >
        <div className={styles.custom_input__image__button}>Загрузить файл</div>
        <div className={styles.custom_input__image__name_pic} id={name}>
          {value?.name || value}
        </div>
        <input type="file" name={name} onChange={changeImage} />
      </label>
    </div>
  );
};
const CustomSelectVisibility = ({
  label = "",
  name = "",
  value = "",
  change,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  const changeSelect = (val) => {
    setOpenSelect(false);
    change({ target: { name, value: val } });
  };
  return (
    <div className={styles.custom_input}>
      <span id={`span_${name}`}>{label}</span>
      <div ref={refSelect} className={styles.custom_input__select}>
        <ButtonSelect
          title={VISIBILITY_MAP.find((e) => e.type === value).title}
          openSelect={openSelect}
          click={() => setOpenSelect(!openSelect)}
        />
        <ul className={openSelect ? styles.open : null}>
          {VISIBILITY_MAP.map((e, index) => (
            <li key={index} onClick={() => changeSelect(e.type)}>
              <span className={value === e.type ? styles.select : null}>
                {e.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const CustomSelectCategory = ({
  label = "",
  value = "",
  name = "",
  categories = [],
  change,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  const changeSelect = (id) => {
    setOpenSelect(false);
    change(id);
  };
  return (
    <div className={styles.custom_input}>
      <span id={`span_${name}`}>{label}</span>
      <div ref={refSelect} name={name} className={styles.custom_input__select}>
        <ButtonSelect
          title={
            categories.find((category) => category.id === value)?.name ||
            "Выбрать"
          }
          openSelect={openSelect}
          click={() => setOpenSelect(!openSelect)}
        />
        <ul
          className={`${styles.categories} ${openSelect ? styles.open : null}`}
        >
          <div>
            {categories.map((category) => (
              <li key={category.id} onClick={() => changeSelect(category.id)}>
                <span className={value === category.id ? styles.select : null}>
                  {category.name}
                </span>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};
const CustomSelectSymptoms = ({
  label = "",
  symptoms = [],
  name = "",
  change,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  return (
    <div
      className={`${styles.custom_input} ${
        !symptoms.length ? styles.disable : null
      }`}
    >
      <span id={`span_${name}`}>{label}</span>
      <div ref={refSelect} name={name} className={styles.custom_input__select}>
        <ButtonSelect
          title={
            symptoms
              .filter((symptom) => symptom.isSelected)
              .map((symptom) => symptom.name)
              .join(", ") || "Выбрать"
          }
          openSelect={openSelect}
          click={() => {
            if (symptoms.length) setOpenSelect(!openSelect);
          }}
        />
        <ul className={`${styles.symptoms} ${openSelect ? styles.open : null}`}>
          <div>
            {symptoms.map((symptom) => (
              <li
                key={symptom.id}
                className={symptom.isSelected ? styles.select : null}
              >
                <span>
                  <label>
                    <Checkbox
                      isSelected={symptom.isSelected}
                      width={isMobile ? vhM(16) : vh(16)}
                      height={isMobile ? vhM(16) : vh(16)}
                      marginRight={isMobile ? vwM(10) : vw(10)}
                      styleBack={{ borderRadius: isMobile ? vhM(3) : vh(3) }}
                      change={(newValue) => change(symptom.id, newValue)}
                    />
                    {symptom.name}
                  </label>
                </span>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

const ButtonSelect = ({ title = "", openSelect = false, click = () => {} }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <button className={openSelect ? styles.open : null} onClick={click}>
      <span>{title}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={isMobile ? vhM(12) : vh(12)}
        height={isMobile ? vhM(13) : vh(13)}
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
          stroke="#5F6B69"
          strokeWidth="0.61828"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export { CreateArticle };
