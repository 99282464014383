import * as url from "../endpoints";
import * as ERROR from "constants/error";
import { instance, config } from "./createAxios";

export const axiosChat = {
  async getChat(page = 1) {
    try {
      const response = await instance.get(url.GET_CHAT(page), config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async postQuestion(params) {
    try {
      await instance.post(url.POST_QUESTION, params, config);
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async questionSeen() {
    try {
      const response = await instance.put(
        url.PUT_QUESTION_SEEN,
        { config },
        config
      );
      return response;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getChatNew() {
    try {
      const response = await instance.get(url.GET_QUESTION_NEW, config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
