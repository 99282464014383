import { TOKEN_NOT_VALID } from "constants/status";

export const removeToken = (error) => {
  if (error?.response?.data?.code === TOKEN_NOT_VALID.code) {
    localStorage.removeItem("history");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
  }
  return;
};
