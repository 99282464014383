import styles from "./BtnChat.module.scss";
import { Link } from "components/shared";
import { useState } from "react";
import { ModalChat, ModalWarning } from "components";
import { useOutsideClick } from "hooks";

const BtnChat = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const refModalChat = useOutsideClick(() => setModalOpen(false));

  return (
    <>
      <div ref={refModalChat} className={styles.btn_chat}>
        {!modalOpen ? (
          <Link
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            <div className={styles.chat}></div>
          </Link>
        ) : localStorage.getItem("token") === null ? (
          <ModalWarning
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
          />
        ) : (
          <ModalChat isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        )}
      </div>
    </>
  );
};

export { BtnChat };
