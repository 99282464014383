import styles from "./Loader.module.scss";

const Loader = ({ bgColor = "#ffffff", count = 8, align = "stretch" }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        color: "#5f6b69",
      }}
    >
      Загрузка...
    </div>
  );
  return (
    <div className={styles.cont_loader} style={{ alignItems: align }}>
      <figure>
        {[...Array(count)].map((x, i) => (
          <span key={i} style={{ background: bgColor }} alt="load..." />
        ))}
      </figure>
    </div>
  );
};

export { Loader };
