import * as url from "../endpoints";
import { instance, config } from "./createAxios";

export const axiosHome = {
  async getMainTypes() {
    try {
      const response = await instance.get(url.GET_MAIN_TYPES, config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getHits() {
    try {
      const response = await instance.get(
        url.GET_HITS + `?city=${localStorage.getItem("city") || ""}`,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getPromotions() {
    try {
      const response = await instance.get(
        url.GET_PROMOTIONS + `?city=${localStorage.getItem("city") || ""}`,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getNew() {
    try {
      const response = await instance.get(
        url.GET_NEW + `?city=${localStorage.getItem("city") || ""}`,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getServices() {
    try {
      const response = await instance.get(
        url.GET_SERVICES,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
