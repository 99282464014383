import * as url from "../endpoints";
import { instance } from "./createAxios";

export const axiosAuth = {
  async getLogin(params) {
    try {
      const response = await instance.post(url.LOGIN, { user: params });
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async getSignUp(params) {
    try {
      const response = await instance.post(url.SIGNUP, { user: params });
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async getConfirmToken(params) {
    try {
      const response = await instance.get(url.CONFIG_TOKEN(params));
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async sendEmail(params) {
    try {
      const response = await instance.post(url.SEND_EMAIL, params);
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async verifyCode(params) {
    try {
      const response = await instance.post(url.VERIFY_CODE, params);
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async changePassword(params) {
    try {
      const response = await instance.put(url.CHANGE_PASSWORD, params);
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async addItemsFromUnauth() {
    try {
      const response = await instance.put(url.ADD_ITEMS_FROM_UNAUTH(), {
        basket: JSON.parse(localStorage.getItem("basket")) || [],
        favorite: {
          products: JSON.parse(localStorage.getItem("favorite_products")) || [],
          services: JSON.parse(localStorage.getItem("favorite_services")) || [],
        },
        article_likes:
          JSON.parse(localStorage.getItem("favorite_articles")) || [],
      });
      localStorage.removeItem("basket");
      localStorage.removeItem("favorite_products");
      localStorage.removeItem("favorite_services");
      localStorage.removeItem("favorite_articles");
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
};
