import styles from "./Basket.module.scss";
import React, { useState, useEffect } from "react";
import { axiosBasket, axiosFavorite, axiosHome } from "plugins/axios";
import {
  Checkbox,
  CounterItem,
  Link,
  Button,
  SliderProducts,
  ModalOneButton,
  CreateOrder,
} from "components";
import emptyProduct from "assets/img/empty_product.png";

import { useKeyDown } from "hooks";
import store from "../../redux/stores";
import {
  CHANGE_IS_OPEN_AUTH_MODAL,
  CHANGE_COUNT_IN_BASKET,
} from "constants/actionType";
import { useSelector } from "react-redux";

import { checkError } from "plugins/checkError";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Basket = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const countInBasket = useSelector((state) => state.countInBasket);

  const [products, setProducts] = useState([]);
  const [hits, setHits] = useState([]);
  const [orderPrice, setOrderPrice] = useState({
    orderPrice: 0,
    priceOfGoods: 0,
  });
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [idForDelete, setIdForDelete] = useState(-1);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState("");

  useKeyDown(() => {
    setIdForDelete(-1);
  }, ["Escape"]);

  useEffect(() => {
    const getMyCart = async () => {
      let newRes = [];
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.getMyCart();
        let { isError, messageError } = checkError(res);
        if (!isError) {
          newRes = res?.items.map((e) => ({ ...e, isSelected: true })) || [];
          setProducts(newRes);
        }
      } else {
        let newProductIDs =
          JSON.parse(localStorage.getItem("basket"))?.map((e) => e.id) || [];
        let res = await axiosBasket.getMyCartForUnauth({
          products: newProductIDs,
        });
        let { isError, messageError } = checkError(res);
        if (!isError) {
          let inFavorite =
            JSON.parse(localStorage.getItem("favorite_products")) || [];
          let basketFromStorage = JSON.parse(localStorage.getItem("basket"));
          newRes =
            res?.products.map((e) => ({
              id: e.id,
              product: e,
              isSelected: true,
              in_favorite: inFavorite.includes(e.id),
              count: basketFromStorage.find((i) => i.id === e.id).count || 1,
            })) || [];
          setProducts(newRes);
        }
      }
      setSelectedAll(true);
      setOrderPrice(calcOrderPrice(newRes));
      let result = await axiosHome.getHits();
      setHits(
        result.purchased.map((e) => ({ ...e, count_in_basket: 0 })) || []
      );
    };
    getMyCart();
  }, [countInBasket]);

  const changeSelected = (id, newValue) => {
    let newProducts = products.map((e) =>
      e.id === id ? { ...e, isSelected: newValue } : e
    );

    setSelectedAll(checkUnselected(newProducts));
    setProducts(newProducts);
    setOrderPrice(calcOrderPrice(newProducts));
  };
  const changeSelectedAll = (newValue) => {
    let newProducts = products.map((e) => ({ ...e, isSelected: newValue }));

    setSelectedAll(newValue);
    setProducts(newProducts);
    setOrderPrice(calcOrderPrice(newProducts));
  };

  const favorite = async (id) => {
    let product = products.find((e) => e.id === id);
    if (!product) return;
    if (localStorage.getItem("token")) {
      let res = await (product.in_favorite
        ? axiosFavorite.removeFromFavorite(product.product.id)
        : axiosFavorite.addToFavorite(product.product.id));
      let { isError, messageError } = checkError(res);
      if (isError) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      if (product.in_favorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_products")
        ).filter((e) => e !== product.id);
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_products")
          ? [
              product.id,
              ...JSON.parse(localStorage.getItem("favorite_products")),
            ]
          : [product.id];
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      }
    }
    setProducts(
      products.map((e) =>
        e.id === product.id ? { ...e, in_favorite: !e.in_favorite } : e
      )
    );
  };
  const tryRemoveProduct = (id) => {
    setIdForDelete(id);
  };

  const removeProduct = async (id) => {
    let product = products.find((e) => e.id === id);
    if (!product) return;
    if (localStorage.getItem("token")) {
      let res = await axiosBasket.removeFromCart(product.product.id);
      let { isError, messageError } = checkError(res);
      if (isError) {
        setIdForDelete(-1);
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      let newBasket = localStorage.getItem("basket")
        ? JSON.parse(localStorage.getItem("basket")).filter(
            (e) => e.id !== product.id
          )
        : [];
      localStorage.setItem("basket", JSON.stringify(newBasket));
    }
    let newProducts = products.filter((e) => e.id !== product.id);
    store.dispatch({
      type: CHANGE_COUNT_IN_BASKET,
      countInBasket:
        newProducts.reduce((sum, product) => sum + product.count, 0) || 0,
    });
    setIdForDelete(-1);
    setSelectedAll(checkUnselected(newProducts));
    setProducts(newProducts);
    setOrderPrice(calcOrderPrice(newProducts));
  };

  const changeCountProduct = async (id, newValue) => {
    let product = products.find((e) => e.id === id);
    if (!product) return;
    if (newValue <= 0) {
      tryRemoveProduct(id);
    } else {
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.changeCartProductCount(
          product.product.id,
          newValue
        );
        let { isError, messageError } = checkError(res);
        if (isError) {
          toast.info(messageError, {
            position: "bottom-center",
            autoClose: 5000,
            theme: "light",
          });
          return;
        }
      } else {
        let newBasket = localStorage.getItem("basket")
          ? JSON.parse(localStorage.getItem("basket")).map((e) =>
              e.id === product.id ? { ...e, count: newValue } : e
            )
          : [];
        localStorage.setItem("basket", JSON.stringify(newBasket));
      }
      let newProducts = products.map((e) =>
        e.id === product.id ? { ...e, count: newValue } : e
      );

      store.dispatch({
        type: CHANGE_COUNT_IN_BASKET,
        countInBasket:
          newProducts.reduce((sum, product) => sum + product.count, 0) || 0,
      });
      setProducts(newProducts);
      setOrderPrice(calcOrderPrice(newProducts));
    }
  };

  const openOrder = async () => {
    if (localStorage.getItem("token")) {
      if (products.some((product) => product.isSelected)) {
        setOpenOrderModal(true);
      } else {
        setMessageInfo("Выберите хотя бы один товар");
      }
    } else {
      setShowOrderModal(true);
    }
  };

  const openAuth = () => {
    setShowOrderModal(false);
    store.dispatch({
      type: CHANGE_IS_OPEN_AUTH_MODAL,
      isOpenAuthModal: true,
    });
  };

  return (
    <>
      <Helmet title="Корзина">
        <meta name="description" content="Shalfey : Корзина" />
        <link rel="canonical" href="/basket" />
      </Helmet>
      <ToastContainer />
      <div className={styles.basket}>
        {products.length ? (
          <>
            <div className={styles.basket__header}>
              Корзина
              <span>
                {products.reduce((sum, product) => sum + product.count, 0)}
              </span>
            </div>
            <div className={styles.basket__left_side}>
              <div className={styles.basket__select_all}>
                <label>
                  <Checkbox
                    isSelected={isSelectedAll}
                    width={isMobile ? vwM(20) : vw(20)}
                    height={isMobile ? vwM(20) : vw(20)}
                    change={changeSelectedAll}
                  />
                  <span>Выбрать всё</span>
                </label>
              </div>
              {products.length
                ? products.map((product) => (
                    <ProductInBasket
                      key={product.id}
                      id={product.id}
                      inFavorite={product.in_favorite}
                      count={product.count}
                      product={product.product}
                      isSelected={product.isSelected}
                      changeSelected={changeSelected}
                      favorite={favorite}
                      changeCountProduct={changeCountProduct}
                      tryRemoveProduct={tryRemoveProduct}
                    />
                  ))
                : "корзина пуста"}
            </div>
            <div className={styles.order_block}>
              <div className={styles.order_block__order_price}>
                <span>Сумма заказа</span>
                <span>{orderPrice.orderPrice.toFixed(2)} ₽</span>
              </div>
              <div className={styles.order_block__price_of_goods}>
                <span>Стоимость товаров</span>
                <span>{orderPrice.priceOfGoods.toFixed(2)} ₽</span>
              </div>
              <div className={styles.order_block__discount}>
                <span>Cкидка</span>
                <span>
                  -
                  {(orderPrice.priceOfGoods - orderPrice.orderPrice).toFixed(2)}{" "}
                  ₽
                </span>
              </div>
              <div className={styles.order_block__button}>
                <Button
                  text="Оформить заказ"
                  maxWidth={isMobile ? "" : vw(484)}
                  width="100%"
                  padding={
                    isMobile ? `${vhM(10)} ${vwM(20)}` : `${vh(10)} ${vw(20)}`
                  }
                  bgColor="#20B900"
                  color="#ffffff"
                  fontWeight="600"
                  fontSize={isMobile ? vhM(20) : vh(20)}
                  lineHeight={isMobile ? vhM(40) : vh(36)}
                  click={openOrder}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.basket__empty}>
            <div className={styles.basket__header}>Корзина пуста</div>
            <div className={styles.basket__empty__additional_info}>
              Чтобы найти товары, воспользуйтесь поиском
            </div>
            <div className={styles.basket__empty__actions}>
              <Button
                text="Начать покупки"
                fontSize={isMobile ? vhM(16) : vh(16)}
                lineHeight={isMobile ? vhM(20) : vh(20)}
                fontWeight="600"
                padding={`${isMobile ? vhM(12) : vh(12)} ${
                  isMobile ? vwM(32) : vw(32)
                }`}
                bgColor="#20B900"
                borderRadius={isMobile ? vhM(10) : vh(10)}
                click={() => navigate("/")}
              />
            </div>
            {hits ? (
              <div className={styles.basket__empty__hits}>
                <div className={styles.basket__empty__hits__header}>
                  Хиты продаж
                </div>
                <SliderProducts items={hits} />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        {idForDelete > -1 && (
          <ModalOneButton
            title="Удаление товара"
            description="Вы действительно хотите удалить товар? Отменить данное действие будет невозможно."
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: vw(312),
                    padding: `${vh(6)} ${vw(12)}`,
                    fontSize: vh(16),
                    lineHeight: vh(26),
                  }
            }
            button={{
              title: "Удалить",
              action: () => removeProduct(idForDelete),
            }}
            close={() => setIdForDelete(-1)}
          />
        )}
        {showOrderModal && (
          <ModalOneButton
            title="Оплата"
            description="Войдите в аккаунт, чтобы оплатить заказ."
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: vw(312),
                    padding: `${vh(6)} ${vw(12)}`,
                    fontSize: vh(16),
                    lineHeight: vh(26),
                  }
            }
            button={{
              title: "Войти",
              action: openAuth,
            }}
            close={() => setShowOrderModal(false)}
          />
        )}
        {messageInfo.length ? (
          <ModalOneButton
            title="Оплата"
            description={messageInfo}
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: vw(312),
                    padding: `${vh(6)} ${vw(12)}`,
                    fontSize: vh(16),
                    lineHeight: vh(26),
                  }
            }
            button={{
              title: "Закрыть",
              action: () => {
                setMessageInfo("");
              },
            }}
            close={() => setMessageInfo("")}
          />
        ) : (
          <></>
        )}
        {openOrderModal && (
          <CreateOrder
            orderPrice={orderPrice}
            products={products}
            clearProducts={() => setProducts([])}
            onClose={() => setOpenOrderModal(false)}
          />
        )}
      </div>
    </>
  );
};

const ProductInBasket = ({
  id,
  inFavorite,
  product,
  count,
  isSelected,
  changeSelected,
  favorite,
  changeCountProduct,
  tryRemoveProduct,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <div className={styles.product}>
      <div className={styles.product__left_side}>
        <div className={styles.product__select}>
          <Checkbox
            isSelected={isSelected}
            width={isMobile ? vwM(20) : vw(20)}
            height={isMobile ? vwM(20) : vw(20)}
            change={(newValue) => changeSelected(id, newValue)}
          />
        </div>
        <div className={styles.product__img}>
          <Link path={`/product/${product.id}`}>
            <img
              src={
                product?.pic_urls?.length
                  ? product.pic_urls[0].pic_url
                  : emptyProduct
              }
              alt={product.name}
              width={isMobile ? vhM(130) : vh(130)}
              height={isMobile ? vhM(130) : vh(130)}
            />
          </Link>
        </div>
        <div className={styles.product__addition}>
          <div className={styles.product__addition__title}>
            <Link path={`/product/${product.id}`}>{product.name}</Link>
          </div>
          {!isMobile ? (
            <div className={styles.product__addition__actions}>
              <div
                className={styles.add_to_favorite}
                onClick={() => favorite(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={vw(30)}
                  height={vw(30)}
                  viewBox="0 0 30 30"
                  fill={inFavorite ? "#DC7413" : "none"}
                >
                  <path
                    d="M14.513 8.29274L14.9286 9.07972L15.3442 8.29274C16.5517 6.00621 18.46 4.47 20.9888 4.47C24.6603 4.47 27.3871 7.53799 27.3871 11.3665C27.3871 13.8677 26.1982 15.9347 24.0395 18.2007C22.543 19.7716 20.6245 21.3954 18.3747 23.2997C17.3547 24.1631 16.2665 25.0841 15.1187 26.084C15.0099 26.1787 14.8471 26.1786 14.7384 26.084C13.5914 25.0848 12.5037 24.1642 11.484 23.3012C9.2355 21.398 7.31713 19.7744 5.82012 18.2031C3.66049 15.9363 2.47 13.8679 2.47 11.3665C2.47 7.53739 5.19724 4.47 8.8683 4.47C11.3971 4.47 13.3054 6.00607 14.513 8.29274Z"
                    stroke={inFavorite ? "#DC7413" : "#5F6B69"}
                    strokeWidth="0.94"
                  />
                </svg>
              </div>
              <div
                className={styles.remove}
                onClick={() => tryRemoveProduct(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={vw(30)}
                  height={vw(30)}
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="nonzero">
                    <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                    <path
                      fill="#5F6B69"
                      stroke="#ffffff"
                      d="M14.28 2a2 2 0 0 1 1.897 1.368L16.72 5H20a1 1 0 1 1 0 2l-.003.071-.867 12.143A3 3 0 0 1 16.138 22H7.862a3 3 0 0 1-2.992-2.786L4.003 7.07A1.01 1.01 0 0 1 4 7a1 1 0 0 1 0-2h3.28l.543-1.632A2 2 0 0 1 9.721 2h4.558Zm3.717 5H6.003l.862 12.071a1 1 0 0 0 .997.929h8.276a1 1 0 0 0 .997-.929L17.997 7ZM10 10a1 1 0 0 1 .993.883L11 11v5a1 1 0 0 1-1.993.117L9 16v-5a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Zm.28-6H9.72l-.333 1h5.226l-.334-1Z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <CounterItem
              count={count}
              smallButton
              width="fit-content"
              boxSizing="border-box"
              padding={`${vhM(9)} ${vwM(28)}`}
              fontSize={vhM(20)}
              lineHeight={vhM(24)}
              color="#5F6B69"
              isNewStyle={true}
              change={(newValue) => changeCountProduct(id, newValue)}
            />
          )}
        </div>
      </div>
      {!isMobile && (
        <div className={styles.product__right_side}>
          <div className={styles.product__price}>
            {calcPrice(
              product.discount_type ? product.discount_price : product.price,
              count
            )}{" "}
            ₽
          </div>
          {product.discount_type && (
            <div className={styles.product__price_without_discount}>
              {calcPrice(product.price, count)} ₽
            </div>
          )}
          <div className={styles.product__change_count}>
            <CounterItem
              count={count}
              smallButton
              boxSizing="border-box"
              padding={`${vh(10)} ${vw(11.5)}`}
              change={(newValue) => changeCountProduct(id, newValue)}
            />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={styles.product__mobile__info}>
          <div className={styles.product__mobile__price}>
            {product.discount_type && (
              <div className={styles.product__price_without_discount}>
                {calcPrice(product.price, count)} ₽
              </div>
            )}
            <div className={styles.product__price}>
              {calcPrice(
                product.discount_type ? product.discount_price : product.price,
                count
              )}{" "}
              ₽
            </div>
          </div>
          <div className={styles.product__mobile__actions}>
            <div
              className={styles.add_to_favorite}
              onClick={() => favorite(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vwM(30)}
                height={vwM(30)}
                viewBox="0 0 30 30"
                fill={inFavorite ? "#DC7413" : "none"}
              >
                <path
                  d="M14.513 8.29274L14.9286 9.07972L15.3442 8.29274C16.5517 6.00621 18.46 4.47 20.9888 4.47C24.6603 4.47 27.3871 7.53799 27.3871 11.3665C27.3871 13.8677 26.1982 15.9347 24.0395 18.2007C22.543 19.7716 20.6245 21.3954 18.3747 23.2997C17.3547 24.1631 16.2665 25.0841 15.1187 26.084C15.0099 26.1787 14.8471 26.1786 14.7384 26.084C13.5914 25.0848 12.5037 24.1642 11.484 23.3012C9.2355 21.398 7.31713 19.7744 5.82012 18.2031C3.66049 15.9363 2.47 13.8679 2.47 11.3665C2.47 7.53739 5.19724 4.47 8.8683 4.47C11.3971 4.47 13.3054 6.00607 14.513 8.29274Z"
                  stroke={inFavorite ? "#DC7413" : "#5F6B69"}
                  strokeWidth="0.94"
                />
              </svg>
            </div>
            <div className={styles.remove} onClick={() => tryRemoveProduct(id)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vwM(30)}
                height={vwM(30)}
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="nonzero">
                  <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                  <path
                    fill="#5F6B69"
                    stroke="#ffffff"
                    d="M14.28 2a2 2 0 0 1 1.897 1.368L16.72 5H20a1 1 0 1 1 0 2l-.003.071-.867 12.143A3 3 0 0 1 16.138 22H7.862a3 3 0 0 1-2.992-2.786L4.003 7.07A1.01 1.01 0 0 1 4 7a1 1 0 0 1 0-2h3.28l.543-1.632A2 2 0 0 1 9.721 2h4.558Zm3.717 5H6.003l.862 12.071a1 1 0 0 0 .997.929h8.276a1 1 0 0 0 .997-.929L17.997 7ZM10 10a1 1 0 0 1 .993.883L11 11v5a1 1 0 0 1-1.993.117L9 16v-5a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Zm.28-6H9.72l-.333 1h5.226l-.334-1Z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const calcPrice = (price = 0, count = 1) => {
  return Number(Number(count * Number(price).toFixed(2)).toFixed(2));
};
const calcOrderPrice = (newProducts) => {
  let newOrderPrice = {
    orderPrice: 0,
    priceOfGoods: 0,
  };
  for (let i = newProducts.length - 1; i >= 0; i--) {
    if (newProducts[i].isSelected) {
      newOrderPrice.orderPrice += calcPrice(
        newProducts[i]?.product?.discount_type
          ? newProducts[i]?.product?.discount_price || 0
          : newProducts[i]?.product?.price || 0,
        newProducts[i].count
      );
      newOrderPrice.priceOfGoods += calcPrice(
        newProducts[i].product.price,
        newProducts[i].count
      );
    }
  }
  return newOrderPrice;
};
const checkUnselected = (newProducts) => {
  return newProducts.length && !newProducts.some((e) => e.isSelected === false);
};

export { Basket };
