import React from "react";
import styles from "./SliderProducts.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NewProduct } from "components";

const NextArrow = (props) => {
  return (
    <div
      style={{
        ...props.style,
        right: props.isMoveArrow
          ? props.isDefaultStyle
            ? "-31px"
            : "-46px"
          : 0,
      }}
      className={`${props.className} ${styles.arrow_slider} ${styles.arrow_slider__right}`}
      onClick={props.onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
      >
        <path
          d="M1 1C1 1 6.2628 5.68234 9.17411 8.46611C9.47591 8.75469 9.46767 9.23797 9.15986 9.52013C6.09691 12.3278 4.21442 14.0535 1 17"
          stroke="#757A77"
          strokeWidth="0.727273"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
const PrevArrow = (props) => {
  return (
    <div
      style={{
        ...props.style,
        left: props.isMoveArrow
          ? props.isDefaultStyle
            ? "-24px"
            : "-46px"
          : 0,
      }}
      className={`${props.className} ${styles.arrow_slider} ${styles.arrow_slider__left}`}
      onClick={props.onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
      >
        <path
          d="M9.72656 1C9.72656 1 4.46377 5.68234 1.55246 8.46611C1.25065 8.75469 1.25889 9.23797 1.56671 9.52013C4.62965 12.3278 6.51215 14.0535 9.72656 17"
          stroke="#757A77"
          strokeWidth="0.727273"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

const SliderProducts = ({
  items = [],
  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
  },
  isDefaultStyle = true,
  isMoveArrow = true,
}) => {
  return (
    <Slider
      {...settings}
      nextArrow={
        <NextArrow isDefaultStyle={isDefaultStyle} isMoveArrow={isMoveArrow} />
      }
      prevArrow={
        <PrevArrow isDefaultStyle={isDefaultStyle} isMoveArrow={isMoveArrow} />
      }
      variableWidth={true}
      swipeToSlide={true}
      centerPadding="60px"
    >
      {items.map((i) => (
        <NewProduct
          key={`${i.name}-${Math.random()}`}
          item={i}
          // imgStyle={{ width: "10vw" }}
          marginRight="25px"
          isDefaultStyle={isDefaultStyle}
          slider
        />
      ))}
    </Slider>
  );
};

export { SliderProducts };
