import React, { useEffect, useState } from "react";
import { NewProfileInfo, TrackOrders } from "components";
import styles from "./Profile.module.scss";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { userButtons, MOBILE_CHAT } from "constants/profileButton";

const Profile = () => {
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [showProfileInfo, setShowProfileInfo] = useState(true);
  const [activeWindow, setActiveWindow] = useState({
    title: "Доставки",
    type: "trackOrder",
    component: <TrackOrders />,
  });

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (tab) {
      let activeTab =
        userButtons.find((item) => item.type == tab) ||
        (MOBILE_CHAT.type === tab ? MOBILE_CHAT : undefined);
      setActiveWindow({
        ...activeTab,
        component: activeTab?.component || <>В разработке</>,
      });
    } else {
      setActiveWindow({
        ...userButtons[0],
        component: userButtons[0]?.component || <>В разработке</>,
      });
    }
    setShowProfileInfo(!(isMobile && tab === "update"));
  }, [searchParams.get("tab")]);

  return (
    <section className={styles.container}>
      <Helmet title={activeWindow.title}>
        <meta name="description" content={"Shalfey : " + activeWindow.title} />
        <link rel="canonical" href="/profile" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.content}>
        {showProfileInfo ? (
          <div className={styles.menu}>
            <NewProfileInfo activeWindow={activeWindow.type} />
          </div>
        ) : (
          <div />
        )}
        <div>{activeWindow.component}</div>
      </div>
    </section>
  );
};

export { Profile };
