import React, { useState, useEffect } from "react";
import styles from "./Questionnaire.module.scss";
import { Question, Button, Loader } from "components";

import { axiosQuestionnaire } from "plugins/axios";
import { useNavigate } from "react-router-dom";
import * as questionnaireTypes from "../../constants/questionnaireType";
import * as steps from "../../constants/questionnaireStep";
import { Helmet } from "react-helmet";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { useMediaQuery } from "react-responsive";

const BackButton = ({ click }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Button
      text={
        <span style={{ display: "flex", alignItems: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={isMobile ? vhM(16) : vh(16)}
            height={isMobile ? vhM(16) : vh(16)}
            viewBox="0 0 8 14"
            fill="none"
            style={{ marginRight: isMobile ? vwM(10) : vw(10) }}
          >
            <path
              d="M7 1C7 1 3.0815 4.48631 0.945134 6.53196C0.74188 6.72658 0.74833 7.05191 0.955771 7.24207C3.2322 9.32879 4.62057 10.6015 7 12.7826"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          Назад
        </span>
      }
      bgColor="#5F6B69"
      color="#FFFFFF"
      width={isMobile ? "100%" : "auto"}
      fontSize={isMobile ? vhM(20) : vh(20)}
      lineHeight={isMobile ? vhM(22) : vh(22)}
      fontWeight="600"
      borderRadius={isMobile ? vhM(10) : vh(10)}
      padding={isMobile ? `${vhM(18)} ${vwM(65)}` : `${vh(18)} ${vw(65)}`}
      click={click}
    />
  );
};
const NextButton = ({ click }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Button
      text={
        <span style={{ display: "flex", alignItems: "center" }}>
          Далее
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={isMobile ? vhM(16) : vh(16)}
            height={isMobile ? vhM(16) : vh(16)}
            viewBox="0 0 8 14"
            fill="none"
            style={{ marginLeft: isMobile ? vwM(10) : vw(10) }}
          >
            <path
              d="M1 1C1 1 4.9185 4.48631 7.05487 6.53196C7.25812 6.72658 7.25167 7.05191 7.04423 7.24207C4.7678 9.32879 3.37943 10.6015 1 12.7826"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </span>
      }
      bgColor="#3BC451"
      color="#FFFFFF"
      width={isMobile ? "100%" : "auto"}
      fontSize={isMobile ? vhM(20) : vh(20)}
      lineHeight={isMobile ? vhM(22) : vh(22)}
      fontWeight="600"
      borderRadius={isMobile ? vhM(10) : vh(10)}
      padding={isMobile ? `${vhM(18)} ${vwM(65)}` : `${vh(18)} ${vw(65)}`}
      click={click}
    />
  );
};

const Questionnaire = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [step, setStep] = useState(steps.TYPE);
  const [groupsIllness, setGroupsIllness] = useState([]);
  const [illness, setIllness] = useState([]);
  const [selectedIllnesses, setSelectedIllnesses] = useState([]);
  const [curGroup, setCurGroup] = useState(-1);
  const [showNext, setShowNext] = useState(false);
  const [questionnaireType, setQuestionnaireType] = useState(
    questionnaireTypes.ILLNESSES
  );
  const [subCategory, setSubCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (step === steps.TYPE) {
      setGroupsIllness([]);
      setIllness([]);
      setSelectedIllnesses([]);
      setShowNext(false);
    } else if (step === steps.GROUP) {
      const getIllnesses = async () => {
        setLoading(true);
        let res = {};
        if (questionnaireType === questionnaireTypes.ILLNESSES) {
          res = await axiosQuestionnaire.getIllnesses();
          setGroupsIllness(
            res?.groups.map((e) => ({
              id: e.obj.id,
              name: e.obj.name,
              children: e.children,
            }))
          );
        } else if (questionnaireType === questionnaireTypes.SYMPTOMS) {
          res = await axiosQuestionnaire.getSymptoms();
          setGroupsIllness(res?.groups || []);
        } else if (questionnaireType === questionnaireTypes.AGES) {
          res = await axiosQuestionnaire.getAges();
          setGroupsIllness(
            res?.year_classification.map((e) => ({
              id: e.obj.id,
              name: e.obj.name,
              children: e.children,
            }))
          );
        }
        setLoading(false);
      };
      getIllnesses();
    } else if (step === steps.CATEGORY) {
      if (questionnaireType === questionnaireTypes.SYMPTOMS) {
        setIllness(
          groupsIllness
            .find((e) => e.id === curGroup)
            ?.symptoms.map((e) => ({
              ...e,
              isSelected:
                selectedIllnesses.findIndex((a) => a.id === e.id) >= 0,
            }))
        );
      } else if (questionnaireType === questionnaireTypes.AGES) {
      }
    }
    window.scrollTo(0, 0);
  }, [step]);

  const back = (prevStep) => {
    if (
      step === steps.CATEGORY &&
      questionnaireType === questionnaireTypes.SYMPTOMS
    ) {
      setSelectedIllnesses([
        ...selectedIllnesses.filter(
          (e) => illness.findIndex((a) => a.id === e.id) === -1
        ),
        ...illness.filter((e) => e.isSelected),
      ]);
    }
    setStep(prevStep);
  };
  const next = (nextStep) => {
    setStep(nextStep);
  };
  const selectQuestionnaireType = (value) => {
    setQuestionnaireType(value);
    setStep(steps.GROUP);
  };
  const changeSelectedIllness = (id, value) => {
    let newIllnesses = illness.map((e) =>
      e.id === id ? { ...e, isSelected: value } : e
    );
    let newSelectedIllnesses = [
      ...selectedIllnesses.filter(
        (e) => newIllnesses.findIndex((a) => a.id === e.id) === -1
      ),
      ...newIllnesses.filter((e) => e.isSelected),
    ];
    setIllness(newIllnesses);
    setSelectedIllnesses(newSelectedIllnesses);
    setShowNext(
      !!newSelectedIllnesses.length || newIllnesses.some((e) => e.isSelected)
    );
  };

  const selectGroup = (id) => {
    let groupIllness = groupsIllness.find((e) => e.id === id);
    if (questionnaireType === questionnaireTypes.ILLNESSES) {
      if (!groupIllness?.children.length) {
        navigate(
          `/questionnaire-result?${questionnaireTypes.ILLNESSES}=${groupIllness.id}`
        );
      } else {
        setIllness(
          groupIllness.children.map((i) => ({
            id: i.obj.id,
            name: i.obj.name,
            children: i.children,
          }))
        );
      }
    } else if (questionnaireType === questionnaireTypes.SYMPTOMS) {
      setIllness(groupIllness.symptoms);
    } else if (questionnaireType === questionnaireTypes.AGES) {
      if (!groupIllness?.children.length) {
        navigate(
          `/questionnaire-result?year_classifications=${groupIllness.id}`
        );
      } else {
        setIllness(
          groupIllness.children.map((i) => ({
            id: i.obj.id,
            name: i.obj.name,
            children: i.children,
          }))
        );
      }
    }
    setCurGroup(id);
    setStep(steps.CATEGORY);
  };
  const continueSelectIllness = () => {
    setStep(steps.GROUP);
  };
  const sentSymptoms = () => {
    navigate(
      `/questionnaire-result?${questionnaireType}=${selectedIllnesses
        .map((e) => e.id)
        .join()}`
    );
  };
  const selectIllness = (id) => {
    let selectedIllness =
      step === steps.CATEGORY
        ? illness.find((e) => e.id === id)
        : subCategory.find((e) => e.id === id);
    if (!selectedIllness?.children.length) {
      navigate(
        `/questionnaire-result?${questionnaireTypes.ILLNESSES}=${selectedIllness.id}`
      );
    } else {
      setSubCategory(
        selectedIllness.children.map((e) => ({
          id: e.obj.id,
          name: e.obj.name,
          children: e.children,
        }))
      );
      if (step === steps.CATEGORY) {
        setStep(steps.SUB_CATEGORY);
      }
    }
  };
  const selectIllnessAge = (id) => {
    let selectedIllness =
      step === steps.CATEGORY
        ? illness.find((e) => e.id === id)
        : subCategory.find((e) => e.id === id);
    if (!selectedIllness?.children.length) {
      navigate(
        `/questionnaire-result?year_classifications=${selectedIllness.id}`
      );
    } else {
      setSubCategory(
        selectedIllness.children.map((e) => ({
          id: e.obj.id,
          name: e.obj.name,
          children: e.children,
        }))
      );
      if (step === steps.CATEGORY) {
        setStep(steps.SUB_CATEGORY);
      }
    }
  };

  return (
    <div className={styles.questionnaire_cont}>
      {step === steps.TYPE ? (
        <div className={styles.questionnaire}>
          <Helmet title="Анкета">
            <meta name="description" content="Shalfey : Анкета" />
            <meta name="keywords" content="Анкета" />
            <link rel="canonical" href="/questionnaire" />
          </Helmet>
          <div className={styles.questionnaire__header}>
            <p className={styles.questionnaire__title}>Анкета</p>
            <p className={styles.questionnaire__description}>
              Знаете ли вы свое заболевание?
            </p>
          </div>
          <Question
            text="Я знаю свое заболевание"
            minHeight={vh(78)}
            margin={`0 auto ${vh(38)} auto`}
            click={() => selectQuestionnaireType(questionnaireTypes.ILLNESSES)}
          />
          <Question
            text="У меня есть симптомы/жалобы"
            minHeight={vh(78)}
            margin={`0 auto ${vh(38)} auto`}
            click={() => selectQuestionnaireType(questionnaireTypes.SYMPTOMS)}
          />
          <Question
            text="Рекомендации по возрасту"
            minHeight={vh(78)}
            margin="0 auto "
            click={() => selectQuestionnaireType(questionnaireTypes.AGES)}
          />
        </div>
      ) : step === steps.GROUP ? (
        <div className={styles.questionnaire}>
          {loading ? (
            <Loader align="center" bgColor="#20b900" />
          ) : (
            <>
              <p className={styles.questionnaire__select_group}>
                {questionnaireType === questionnaireTypes.ILLNESSES
                  ? "Выберите подходящую категорию заболевания"
                  : questionnaireType === questionnaireTypes.SYMPTOMS
                  ? "Выберите систему, в которой у вас есть жалобы"
                  : questionnaireType === questionnaireTypes.AGES
                  ? "Выберите подходящую вам категорию возраста"
                  : "Вы ничего не выбрали"}
              </p>
              <div className={styles.questionnaire__two_columns}>
                {groupsIllness.map((e) => (
                  <div
                    key={`group-${e.id}`}
                    className={styles.questionnaire__question}
                  >
                    <Question text={e.name} click={() => selectGroup(e.id)} />
                  </div>
                ))}
              </div>
              <div className={styles.questionnaire__buttons}>
                <div>
                  <BackButton click={() => back(steps.TYPE)} />
                </div>
                <div />
              </div>
            </>
          )}
        </div>
      ) : step === steps.CATEGORY ? (
        <div className={styles.questionnaire}>
          <div className={styles.questionnaire__header}>
            <p className={styles.questionnaire__title_small}>
              {questionnaireType === questionnaireTypes.ILLNESSES
                ? "Выберите заболевание, симптомы которого у вас есть"
                : questionnaireType === questionnaireTypes.SYMPTOMS
                ? "Выберите подходящий вариант"
                : questionnaireType === questionnaireTypes.AGES
                ? "Выберите подходящую вам категорию"
                : "Вы ничего не выбрали"}
            </p>
            <p className={styles.questionnaire__description_small}>
              {questionnaireType === questionnaireTypes.ILLNESSES
                ? ""
                : questionnaireType === questionnaireTypes.SYMPTOMS
                ? "Сначала укажите основные симптомы, которые по вашему мнению приносят вам больше всего дискомфорта и снижают работоспособность, далее укажите дополнительные симптомы"
                : questionnaireType === questionnaireTypes.AGES
                ? ""
                : "Вы ничего не выбрали"}
            </p>
          </div>
          <div className={styles.questionnaire__two_columns}>
            {illness.map((e) => (
              <div
                key={`illness-${e.id}`}
                className={styles.questionnaire__question}
              >
                {questionnaireType === questionnaireTypes.ILLNESSES ? (
                  <Question text={e.name} click={() => selectIllness(e.id)} />
                ) : questionnaireType === questionnaireTypes.SYMPTOMS ? (
                  <Question
                    text={e.name}
                    isCheckbox={true}
                    minHeight="13.704vh"
                    isSelected={e.isSelected}
                    justifyContent="normal"
                    change={(value) => changeSelectedIllness(e.id, value)}
                  />
                ) : questionnaireType === questionnaireTypes.AGES ? (
                  <Question
                    text={e.name}
                    click={() => selectIllnessAge(e.id)}
                  />
                ) : null}
              </div>
            ))}
          </div>

          <div className={styles.questionnaire__buttons}>
            <div>
              <BackButton click={() => back(steps.GROUP)} />
            </div>
            {questionnaireType !== questionnaireTypes.SYMPTOMS || !showNext ? (
              <div />
            ) : (
              <div>
                <NextButton click={() => next(steps.LAST)} />
              </div>
            )}
          </div>
        </div>
      ) : step === steps.SUB_CATEGORY ? (
        <div className={styles.questionnaire}>
          <div className={styles.questionnaire__header}>
            <p className={styles.questionnaire__title_small}>
              {questionnaireType === questionnaireTypes.AGES ||
              questionnaireType === questionnaireTypes.ILLNESSES
                ? "Выберите подходящую вам категорию"
                : "Вы ничего не выбрали"}
            </p>
            <p className={styles.questionnaire__description_small}>
              {questionnaireType === questionnaireTypes.AGES ||
              questionnaireType === questionnaireTypes.ILLNESSES
                ? ""
                : "Вы ничего не выбрали"}
            </p>
          </div>
          <div className={styles.questionnaire__two_columns}>
            {subCategory.map((e) => (
              <div
                key={`illness-${e.id}`}
                className={styles.questionnaire__question}
              >
                {questionnaireType === questionnaireTypes.AGES ? (
                  <Question
                    text={e.name}
                    click={() => selectIllnessAge(e.id)}
                  />
                ) : questionnaireType === questionnaireTypes.ILLNESSES ? (
                  <Question text={e.name} click={() => selectIllness(e.id)} />
                ) : null}
              </div>
            ))}
          </div>

          <div className={styles.questionnaire__buttons}>
            <div>
              <BackButton click={() => back(steps.CATEGORY)} />
            </div>
            <div />
          </div>
        </div>
      ) : (
        <div className={styles.questionnaire}>
          <div className={styles.questionnaire__header}>
            <p
              className={styles.questionnaire__title_small}
              style={{
                maxWidth: isMobile ? "100%" : vw(550),
                width: "100%",
                margin: "0 auto",
              }}
            >
              {questionnaireType === questionnaireTypes.SYMPTOMS
                ? "Есть ли у вас дополнительные жалобы?"
                : ""}
            </p>
          </div>
          <div className={styles.questionnaire__last_buttons}>
            <Button
              text="Да"
              bgColor="#5F6B69"
              width={isMobile ? "100%" : vw(286)}
              marginBottom={isMobile ? vhM(26) : vh(26)}
              fontSize={isMobile ? vhM(22) : vh(22)}
              lineHeight={isMobile ? vhM(27) : vh(27)}
              fontWeight="600"
              borderRadius={isMobile ? vhM(15) : vh(15)}
              padding={isMobile ? `${vhM(18.5)} 0` : `${vh(18.5)} 0`}
              click={continueSelectIllness}
            />
            <Button
              text="Нет"
              bgColor="#3BC451"
              width={isMobile ? "100%" : vw(286)}
              fontSize={isMobile ? vhM(22) : vh(22)}
              lineHeight={isMobile ? vhM(27) : vh(27)}
              fontWeight="600"
              borderRadius={isMobile ? vhM(15) : vh(15)}
              padding={isMobile ? `${vhM(18.5)} 0` : `${vh(18.5)} 0`}
              click={sentSymptoms}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Questionnaire };
