import { Helmet } from "react-helmet";

const Yandex = () => {
  return (
    <Helmet title="Yandex">
      <meta name="description" content={"Shalfey : Yandex"} />
      <link rel="canonical" href="/yandex" />
      <meta name="robots" content="noindex" />
      <script src={`${window.location.origin}/yandexPage.js`} />
    </Helmet>
  );
};

export { Yandex };
