import { useState } from "react";
import styles from "./ResetPassword.module.scss";
import { Input, Button } from "components";
import { ERR_NETWORK } from "constants/status";
import eye_close from "assets/img/eye-close.svg";
import eye_open from "assets/img/eye-open.svg";
import { Helmet } from "react-helmet";
import { axiosAuth } from "plugins/axios";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [error, setError] = useState({
    password: "",
    passwordConfirm: "",
    errorMessage: "",
  });

  const submitData = async (event) => {
    event.preventDefault();
    let valid = true;
    setError({
      password: "",
      passwordConfirm: "",
      errorMessage: "",
    });
    if (password !== passwordConfirm) {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Пароли должны соврадать",
        };
      });
      return;
    }
    if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        password
      )
    ) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          password:
            "Пароль должен состоять из английского алфавита длиной не менее 8 символов, содержать заглавную и прописную буквы, цифру и спец. символ из #?!@$%^&*-_",
        };
      });
    }
    if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        passwordConfirm
      )
    ) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          passwordConfirm:
            "Пароль должен состоять из английского алфавита длиной не менее 8 символов, содержать заглавную и прописную буквы, цифру и спец. символ из #?!@$%^&*-_",
        };
      });
    }
    if (valid) {
      let response = await axiosAuth.changePassword({
        password,
        password_similar: passwordConfirm,
      });
      if (
        response.status === 404 &&
        response.data.Error === "There is no such user"
      ) {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: "Нет такого пользователя",
          };
        });
      } else if (response.status === "updated") {
        window.location.replace("/");
      } else {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: ERR_NETWORK.message,
          };
        });
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные",
        };
      });
    }
  };

  return (
    <section className={styles.container}>
      <Helmet title="Восстановить пароль">
        <meta name="description" content={"Shalfey : Восстановить пароль"} />
        <link rel="canonical" href="/reset_password" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.content}>
        <h2>Изменить пароль</h2>
        <Input
          type={showPassword ? "text" : "password"}
          placeholder="Пароль"
          value={password}
          marginBottom="20px"
          errorMessage={error.password}
          prepend={
            <img
              src={showPassword ? eye_open : eye_close}
              width="32px"
              alt="Поиск"
              onClick={() => setShowPassword(!showPassword)}
            />
          }
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type={showPasswordConfirm ? "text" : "password"}
          placeholder="Подтвердите пароль"
          value={passwordConfirm}
          marginBottom="20px"
          errorMessage={error.passwordConfirm}
          prepend={
            <img
              src={showPasswordConfirm ? eye_open : eye_close}
              width="32px"
              alt="Поиск"
              onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
            />
          }
          onChange={(e) => setPasswordConfirm(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              submitData(event);
            }
          }}
        />
        <Button text="Изменить пароль" padding="8px 14px" click={submitData} />
        {error.errorMessage !== "" ? <span>{error.errorMessage}</span> : null}
      </div>
    </section>
  );
};

export { ResetPassword };
