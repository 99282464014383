import React, { useState } from "react";
import styles from "./CopyButton.module.scss";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { vh } from "plugins/convertPXtoVHVW";

const CopyButton = ({
  text = "info@ecoshalf.com",
  textBeforeCopy = "Скопировать",
  textAfterCopy = "Email скопирован!",
  sizeBtn = vh(14),
  ...style
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Ошибка при копировании текста: ", err);
    }
  };

  return (
    <>
      <button
        className={styles.copy_button}
        style={{ ...style }}
        data-tooltip-id="copy-tooltip"
        data-tooltip-content={isCopied ? textAfterCopy : textBeforeCopy}
        onClick={copyText}
      >
        {isCopied ? (
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height={sizeBtn}
            width={sizeBtn}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
          </svg>
        ) : (
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 448 512"
            height={sizeBtn}
            width={sizeBtn}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"></path>
          </svg>
        )}
      </button>
      <Tooltip id="copy-tooltip" place="top" effect="solid" />
    </>
  );
};

export { CopyButton };
