import React, { useState, useEffect, useRef } from "react";
import styles from "./Profile.module.scss";
import { axiosUserProfile } from "plugins/axios";
import { ItemChatList, MessageChat, Input } from "components";
import search_icon from "assets/img/search.svg";
import { vh, vw } from "plugins/convertPXtoVHVW";
import { Button } from "components";

const Chats = () => {
  const [chatList, setChatList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedChat, setSelectedChat] = useState([]);
  const scrollContainerRef = useRef(null);

  const [wasLastChatList, setWasLastChatList] = useState(false);
  const [currentChatListPage, setCurrentChatListPage] = useState(1);
  const [nextChatListPage, setNextChatListPage] = useState(2);
  const [wasLastMessageList, setWasLastMessageList] = useState(false);
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [nextChatPage, setNextChatPage] = useState(2);
  const [searchChat, setSearchChat] = useState("");
  const [messageText, setMessageText] = useState("");

  const [isUpdateList, setIsUpdateList] = useState(false);

  const onScrollChatList = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentChatListPage < nextChatListPage) {
      setCurrentChatListPage(currentChatListPage + 1);
    }
  };
  const onScrollChat = (event) => {
    const scrollBottom = !event.target.scrollTop;
    if (scrollBottom && currentChatPage < nextChatPage) {
      setCurrentChatPage(currentChatPage + 1);
    }
  };

  useEffect(() => {
    const getChatList = async () => {
      let resChatList = await axiosUserProfile.getChatList(
        searchChat,
        currentChatListPage
      );
      if (resChatList?.results) {
        setChatList(resChatList.results);
        if (!selectedOrder) {
          setSelectedOrder(
            resChatList.results.length
              ? resChatList.results[0].order_number
              : ""
          );
        }

        setNextChatListPage(
          !resChatList.next ? currentChatListPage : currentChatListPage + 1
        );
        setWasLastChatList(!resChatList.next);
      }
      setIsUpdateList(false);
    };
    if (!wasLastChatList) {
      getChatList();
    }
  }, [currentChatListPage, wasLastChatList, isUpdateList]);
  useEffect(() => {
    const getChat = async () => {
      if (selectedOrder) {
        let resChat = await axiosUserProfile.getChatOrder(
          selectedOrder,
          currentChatPage
        );
        if (resChat?.results) {
          let newChat = resChat.results.map((chat) => ({
            ...chat,
            type_of_message:
              chat.type_of_message === "UM" ? "MINE" : "NOT_MINE",
          }));
          if (!resChat.previous) {
            if (newChat.length) {
              if (newChat[0].type_of_message === "MINE") {
                newChat[0].isLast = true;
              }
            }
            setSelectedChat(newChat.reverse());
          } else {
            setSelectedChat([...newChat.reverse(), ...selectedChat]);
          }
          setNextChatPage(
            !resChat.next ? currentChatPage : currentChatPage + 1
          );
          setWasLastMessageList(!resChat.next);
        }
        const scrollContainer = scrollContainerRef.current;
        if (currentChatPage === 1) {
          setTimeout(() => {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }, 500);
        } else {
          let oldScrollHeight = scrollContainer.scrollHeight;
          setTimeout(() => {
            scrollContainer.scrollTop =
              scrollContainer.scrollHeight - oldScrollHeight;
          }, 100);
        }
      }
    };
    if (!wasLastMessageList) {
      getChat();
    }
  }, [selectedOrder, wasLastMessageList, currentChatPage]);

  const selectChat = (order) => {
    setChatList(
      chatList.map((chat) =>
        chat.order_number === selectedOrder
          ? { ...chat, new_mess_count: 0 }
          : chat
      )
    );
    setSelectedOrder(order);
    setMessageText("");
    setWasLastMessageList(false);
    setCurrentChatPage(1);
    setNextChatPage(2);
  };
  const sendMessage = async () => {
    if (messageText.length) {
      let res = await axiosUserProfile.sendMessageChat(
        selectedOrder,
        messageText
      );
      if (!res) {
        let newSelectedChat = selectedChat.map((chat) => ({
          ...chat,
          isLast: false,
        }));
        setSelectedChat([
          ...newSelectedChat,
          {
            username: "",
            isLast: true,
            is_seen: false,
            text: messageText,
            type_of_message: "MINE",
            creation_date: `${("0" + new Date().getDate()).slice(-2)}.${(
              "0" +
              (new Date().getMonth() + 1)
            ).slice(-2)}.${new Date().getFullYear()}`,
          },
        ]);
        setIsUpdateList(true);
        setCurrentChatListPage(1);
        setNextChatListPage(2);
        setWasLastChatList(false);
      } else {
        setSelectedChat([
          ...selectedChat,
          {
            username: "",
            isLast: false,
            is_seen: false,
            text: messageText,
            type_of_message: "MINE",
            isError: true,
            creation_date: `${"0" + new Date().getDate()}.${
              new Date().getMonth() + 1
            }.${new Date().getFullYear()}`,
          },
        ]);
      }
      setMessageText("");
      setTimeout(() => {
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 100);
    }
  };
  const search = async () => {
    setWasLastChatList(false);
    setCurrentChatListPage(1);
    setNextChatListPage(2);
    setIsUpdateList(true);
  };

  return (
    <div className={styles.chat_cont}>
      <div className={styles.chat_list} onScroll={onScrollChatList}>
        <div style={{ marginBottom: vh(20) }}>
          <Input
            light
            placeholder="Введите артикул или название товара"
            value={searchChat}
            fontColor="#BFC4C3"
            height={vh(44)}
            inputStyles={{
              padding: `${vh(13)} ${vw(23.5)}`,
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              fontSize: vh(14),
            }}
            prependStyles={{ height: "100%", boxSizing: "border-box" }}
            prepend={
              <img
                src={search_icon}
                style={{ height: vh(10.67) }}
                alt="search"
                onClick={search}
              />
            }
            onChange={(e) => setSearchChat(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
        </div>
        {chatList.length ? (
          chatList.map((chat, index) => (
            <ItemChatList
              key={index}
              chat={chat}
              selectedOrder={selectedOrder}
              open={() => selectChat(chat.order_number)}
            />
          ))
        ) : (
          <span className={styles.chat__empty}>Нет чатов</span>
        )}
      </div>
      <div className={styles.selected_chat}>
        <div
          className={styles.selected_chat__messages}
          onScroll={onScrollChat}
          ref={scrollContainerRef}
        >
          {selectedChat.length ? (
            selectedChat.map((chat, index) => (
              <MessageChat key={index} message={chat} />
            ))
          ) : (
            <span className={styles.chat__empty}>
              Чат пустой. Напишите что-нибудь, чтобы начать разговор
            </span>
          )}
        </div>
        <div className={styles.selected_chat__send_message}>
          <Input
            light
            placeholder="Введите ваше сообщение..."
            value={messageText}
            height={vh(44)}
            width="100%"
            marginRight={vw(12)}
            inputStyles={{
              padding: `${vh(13)} ${vw(23.5)}`,
              height: "100%",
              boxSizing: "border-box",
              fontSize: vh(14),
            }}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
          <Button
            text="Отправить"
            bgColor="#20B900"
            color="#ffffff"
            padding={`${vh(12.5)} ${vw(25)}`}
            minWidth={vw(367)}
            borderRadius={vh(10)}
            fontSize={vh(16)}
            lineHeight={vh(19)}
            fontWeight="600"
            click={sendMessage}
          />
        </div>
      </div>
    </div>
  );
};

export { Chats };
