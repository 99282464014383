import React, { useState, useEffect } from "react";
import styles from "./Deliveries.module.scss";
import { axiosUserProfile, axiosProduct } from "plugins/axios";
import { checkError } from "plugins/checkError";

import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  NewProduct,
  ModalOneButton,
  SliderProducts,
  ModalСontacts,
} from "components";
import EmptyProduct from "assets/img/empty_product.png";
import birdie from "assets/img/birdie.svg";
import { DELIVERIES_STATUSES } from "constants/purchaseStatuses";

const TrackOrders = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [orders, setOrders] = useState([]);
  const [history, setHistory] = useState([]);

  const [statusHistory, setStatusHistory] = useState([]);
  const [modalHistory, setModalHistory] = useState(false);

  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isOpenModalСontacts, setIsOpenModalСontacts] = useState(false);
  const [modalCancelOrderService, setModalCancelOrderService] = useState(false);

  const [modalCancelOrder, setModalCancelOrder] = useState(false);
  const [modalCancelOrderSuccess, setModalCancelOrderSuccess] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(0);
  const [cancelOrderAllow, setCancelOrderAllow] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const getOrders = async () => {
    let result = await axiosUserProfile.getTrackOrdres("?page=" + currentPage);
    if (result?.data?.detail === "Invalid page." || result?.status === 403) {
      setWasLastList(true);
      return;
    }
    setPreviousPage(currentPage);
    setOrders([...orders, ...result.results]);
  };

  const getMyHistory = async () => {
    let products = JSON.parse(localStorage.getItem("history")) || [];
    let res = await axiosProduct.getMyHistoryProducts({ products });
    setHistory(res.history || []);
  };

  const getStatusHistory = async (order_number) => {
    let res = await axiosUserProfile.getStatusHistory(order_number);
    setStatusHistory(res);
  };

  const createCancelOrder = async (cancel_type = "all") => {
    setIsCancelLoading(true);
    let result = await axiosUserProfile.createCancelOrder(
      cancelOrder.order_number,
      cancel_type,
      cancelOrder.product.id
    );
    let { isError, messageError } = checkError(result);
    if (isError) {
      setErrorMessage(messageError);
    } else {
      setIsCancelLoading(false);
      if (result.data.status === "sent") {
        setCancelOrderAllow(false);
      } else if (result.data.status === "contact") {
        setModalCancelOrder(false);
        setModalCancelOrderService(true);
      } else {
        setOrders([]);
        setWasLastList(false);
        setCurrentPage(1);
        setPreviousPage(0);
        setModalCancelOrder(false);
        setErrorMessage("");
        setModalCancelOrderSuccess(true);
      }
    }
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getOrders();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    getMyHistory();
  }, []);

  return (
    <div className={styles.track_orders}>
      <div className={styles.track_orders__header}>
        {!!orders.length ? "Доставки" : "Доставок нет"}
      </div>
      <div
        className={styles.track_orders__body}
        onScroll={(event) => onScroll(event)}
      >
        {!!orders?.length ? (
          orders.map((item) => (
            <div key={item.id} className={styles.track_order}>
              <div className={styles.track_body}>
                <img
                  src={item.product.pic_urls[0]?.pic_url || EmptyProduct}
                  alt={
                    item?.product?.pic_urls?.length
                      ? item.product.pic_urls[0]?.alt || item.product.name
                      : item.product.name
                  }
                />
                <div>
                  <p>{item.product.name}</p>
                  {item.notification_active && (
                    <p>
                      <b>Статус заказа:&nbsp;</b>
                      {DELIVERIES_STATUSES.find(
                        (el) => el.code === item.purchase_status
                      )
                        ? DELIVERIES_STATUSES.find(
                            (el) => el.code === item.purchase_status
                          ).text
                        : ""}
                    </p>
                  )}
                </div>
              </div>

              <div className={styles.buttons}>
                {item.notification_active && (
                  <Button
                    text="История заказа"
                    padding="1.2vh 1.5vw"
                    light
                    click={() => {
                      getStatusHistory(item.order_number);
                      setModalHistory(true);
                    }}
                  />
                )}
                <p
                  className={styles.cancel_order}
                  onClick={() => {
                    setCancelOrderAllow(item.purchase_status === "PD");
                    setCancelOrder(item);
                    setModalCancelOrder(true);
                  }}
                >
                  Отменить заказ
                </p>
              </div>
            </div>
          ))
        ) : (
          <>
            <p className={styles.empty}>
              Чтобы найти и выбрать подходящие товары, воспользуйтесь поиском
            </p>
            <Button
              text="Начать покупки"
              padding="1.5vh 1vw"
              click={() => navigate("/")}
            />
          </>
        )}
        {!!history?.length && (
          <div className={styles.history_title}>Вы смотрели</div>
        )}
        {isMobile ? (
          !!history.length && (
            <div style={{ padding: "0 3vw" }}>
              <SliderProducts items={history} />
            </div>
          )
        ) : (
          <div className={styles.history}>
            {history?.map((item) => (
              <NewProduct
                key={item.id}
                item={item}
                width="auto"
                height="auto"
                imgStyle={{ width: "10vw" }}
                isDefaultStyle
                slider
              />
            ))}
          </div>
        )}
      </div>
      {modalHistory && (
        <ModalOneButton
          title="История заказа"
          description={
            <>
              {statusHistory.map((item, index) => (
                <div key={index} className={styles.line}>
                  <div className={styles.label}>{item.status}</div>
                  <div>
                    {item.change_date.slice(8, 10)}{" "}
                    {months[Number(item.change_date.slice(5, 7)) - 1]},{" "}
                    {item.change_date.slice(11, 16)}
                  </div>
                </div>
              ))}
            </>
          }
          button={{
            title: "Закрыть",
            action: () => setModalHistory(false),
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          width={isMobile ? "90vw" : "35vw"}
          descriptionAlign="left"
          buttonLight
          close={() => setModalHistory(false)}
        />
      )}
      {modalCancelOrder && (
        <ModalOneButton
          title={
            cancelOrderAllow ? "Отменить заказ" : "Отмена заказа невозможна"
          }
          description={
            cancelOrderAllow ? (
              <>
                <div className={styles.cancel_text}>
                  Вы действительно хотите отменить заказ? Отменить данное
                  действие будет невозможно.
                </div>
                {!cancelOrder.is_one && (
                  <Button
                    text="Отменить часть заказа"
                    width="100%"
                    bgColor="#EAF8E7"
                    color="#20B900"
                    marginTop="3vh"
                    padding="1.2vh 0"
                    click={() => createCancelOrder("one")}
                  />
                )}
                {!!errorMessage && (
                  <span style={{ color: "red" }}>{errorMessage}</span>
                )}
              </>
            ) : (
              <div className={styles.cancel_text}>
                Ваш заказ уже в пути. Отменить заказ невозможно. Вы можете
                оформить возврат товаров после получения заказа.
              </div>
            )
          }
          button={{
            title: cancelOrderAllow
              ? isCancelLoading
                ? "Обработка..."
                : "Отменить"
              : "Закрыть",
            action: () => {
              if (cancelOrderAllow) {
                createCancelOrder();
              } else {
                setErrorMessage("");
                setModalCancelOrder(false);
              }
            },
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
            bgColor: !cancelOrderAllow && "#EAF8E7",
            color: !cancelOrderAllow && "#20B900",
            disabled: isCancelLoading,
          }}
          width={isMobile ? "90vw" : "23vw"}
          close={() => {
            setErrorMessage("");
            setModalCancelOrder(false);
          }}
        />
      )}
      {modalCancelOrderService && (
        <ModalOneButton
          title="Отмена заказа"
          description={
            <div className={styles.cancel_text}>
              Вы можете отменить заказ, написав нам в Службу заботы либо
              связавшись с продавцом
            </div>
          }
          button={{
            title: "Служба заботы",
            action: () => {
              setIsOpenModalСontacts(true);
              setModalCancelOrderService(false);
            },
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          width={isMobile ? "90vw" : "23vw"}
          close={() => setModalCancelOrderService(false)}
        />
      )}
      {isOpenModalСontacts && (
        <ModalСontacts onClose={() => setIsOpenModalСontacts(false)} />
      )}
      {modalCancelOrderSuccess && (
        <ModalOneButton
          title={
            <>
              <img
                src={birdie}
                alt="Галочка"
                height={20}
                style={{ marginRight: "3px" }}
              />
              Ваш заказ отменен
            </>
          }
          description="Деньги поступят на счёт в течение 3 дней"
          button={{
            title: "Закрыть",
            action: () => setModalCancelOrderSuccess(false),
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
            bgColor: "#EAF8E7",
            color: "#20B900",
          }}
          width={isMobile ? "90vw" : "23vw"}
          close={() => setModalCancelOrderSuccess(false)}
        />
      )}
    </div>
  );
};

export { TrackOrders };
