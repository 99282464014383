import React, { useState, useEffect } from "react";
import styles from "./Questionnaire.module.scss";
import { MainMenu, Tag } from "components";
import { axiosQuestionnaire } from "plugins/axios";
import { SliderProducts } from "components";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

const QuestionnaireResult = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [searchParams] = useSearchParams();
  const [tags, setTags] = useState([]);
  const [typesOfMedicine, setTypesOfMedicine] = useState([]);

  useEffect(() => {
    const getResult = async () => {
      let illnessesIds = searchParams.get("illnesses") || "";
      let symptomsIds = searchParams.get("symptoms") || "";
      let agesIds = searchParams.get("year_classifications") || "";
      let res = {};
      if (illnessesIds) {
        res = await axiosQuestionnaire.getIllnessesResult(
          "&illnesses=" + illnessesIds
        );
      } else if (symptomsIds) {
        res = await axiosQuestionnaire.getSymptomsResult(
          "&symptoms=" + symptomsIds
        );
      } else if (agesIds) {
        res = await axiosQuestionnaire.getAgesResult(
          "&year_classifications=" + agesIds
        );
      }
      setTags(res?.tags || []);
      setTypesOfMedicine(res?.results?.filter((e) => e.products.length) || []);
    };
    getResult();

    window.scrollTo(0, 0);
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
  };
  return (
    <div className={styles.q_result}>
      <Helmet title="Результат анкетирования">
        <meta name="description" content="Shalfey : Результат анкетирования" />
        <link rel="canonical" href="/questionnaire-result" />
      </Helmet>
      {!isMobile && (
        <div className={styles.menu}>
          <MainMenu />
        </div>
      )}
      <div className={styles.questionnaire_result}>
        <div className={styles.questionnaire_result__header}>
          <p>Результат анкетирования</p>
          <p>
            Мы проанализировали ваши симптомы/жалобы и на основе данных, которые
            вы предоставили в анкете, подобрали следующие препараты:{" "}
          </p>
          <div className={styles.questionnaire_result__symptoms}>
            {tags.map((e, index) => (
              <Tag key={index} text={e} />
            ))}
          </div>
        </div>
        <div className={styles.questionnaire_result__add_info}>
          БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ. ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ.
          НЕОБХОДИМА КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА
        </div>
        {typesOfMedicine.length ? (
          typesOfMedicine.map((e, index) => (
            <div key={e.id} className={styles.questionnaire_result__group}>
              <div className={styles.questionnaire_result__group__header}>
                <img src={e.icon_url} alt={e.name} />
                <span>{e.name}</span>
              </div>
              <div className={styles.questionnaire_result__group__products}>
                <SliderProducts
                  items={e.products}
                  settings={settings}
                  isDefaultStyle={index % 2 === 0}
                  isMoveArrow={!isMobile}
                />
              </div>
            </div>
          ))
        ) : (
          <span className={styles.questionnaire_result__empty}>
            Ничего не найдено
          </span>
        )}
      </div>
    </div>
  );
};

export { QuestionnaireResult };
