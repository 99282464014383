import React, { useState, useEffect } from "react";
import styles from "./ModalChatSide.module.scss";
import arrow from "assets/img/arrow.svg";
import EmptyProduct from "assets/img/empty_product.png";
import { axiosUserProfile } from "plugins/axios";

const ModalChatSide = ({ id }) => {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [seller, setSeller] = useState("");

  let messageList = messages?.map((message, index) => {
    if (message.type_of_message === "SM") {
      return (
        <div key={index} className={styles.answer}>
          {message.text}
        </div>
      );
    } else {
      return (
        <div key={index} className={styles.question}>
          {message.text}
        </div>
      );
    }
  });

  const addNewMassage = async () => {
    if (question && question.trim() !== "") {
      await axiosUserProfile.sendQuestion(id, { text: question });
    }
    getChat();
    setQuestion("");
  };

  const getChat = async () => {
    if (id !== 0) {
      let result = await axiosUserProfile.getChat(id);
      setMessages(result.results?.messages);
      setSeller(result.results?.seller);
    }
  };

  useEffect(() => {
    getChat();
  }, [id]);

  return (
    <>
      <div className={styles.seller}>
        <img src={seller?.pic_url || EmptyProduct} alt={seller?.name} />
        <p>{seller?.name}</p>
      </div>
      {!!messages?.length ? (
        <div className={styles.messageList}>{messageList}</div>
      ) : (
        <div className={styles.empty}>Сообщений пока нет</div>
      )}
      <div className={styles.for_input}>
        <input
          type="text"
          placeholder="Введите ваше сообщение"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <img
          src={arrow}
          alt="Input"
          className={styles.input_button}
          onClick={addNewMassage}
        />
      </div>
    </>
  );
};

export { ModalChatSide };
