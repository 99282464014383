import React from "react";
import styles from "./Chat.module.scss";

const ItemChatList = ({
  chat,
  selectedOrder,
  isMobile = false,
  open = () => {},
}) => {
  return isMobile ? (
    <div
      className={styles.mobile_chat}
      style={{
        backgroundColor:
          selectedOrder === chat.order_number ? "#eaf8e7" : "#f8fbf9",
      }}
      onClick={open}
    >
      <div className={styles.mobile_chat__header}>
        <div className={styles.mobile_chat__name} title={chat?.username}>
          {chat?.username}
        </div>
        <div
          className={styles.mobile_chat__order_number}
          title={chat?.order_number}
        >
          {chat?.order_number}
        </div>
      </div>
      <div className={styles.mobile_chat__message}>
        {chat?.last_message || ""}
      </div>
      <div className={styles.mobile_chat__info}>
        <span className={styles.mobile_chat__info__date}>
          {chat?.message_date}
        </span>
        {chat?.new_mess_count ? (
          <span className={styles.mobile_chat__info__count}>
            {chat?.new_mess_count}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div
      className={styles.chat}
      style={{
        backgroundColor:
          selectedOrder === chat.order_number ? "#eaf8e7" : "#f8fbf9",
      }}
      onClick={open}
    >
      <div className={styles.chat__name} title={chat?.username}>
        {chat?.username}
      </div>
      <div className={styles.chat__order_number} title={chat?.order_number}>
        {chat?.order_number}
      </div>
      <div className={styles.chat__info}>
        <span className={styles.chat__info__date}>{chat?.message_date}</span>
        {chat?.new_mess_count ? (
          <span className={styles.chat__info__count}>
            {chat.new_mess_count}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export { ItemChatList };
