export const STREETS = [
  "Аллея",
  "Бульвар",
  "Вал",
  "Въезд",
  "Заезд",
  "Застава",
  "Квартал",
  "Кольцо",
  "Коса",
  "Линия",
  "Луг",
  "Микрорайон",
  "Мост",
  "Набережная",
  "Переезд",
  "Переулок",
  "Площадь",
  "Поляна",
  "Починок",
  "Проезд",
  "Проспект",
  "Проулок",
  "Разъезд",
  "Ряд",
  "Сквер",
  "Слобода",
  "Спуск",
  "Станция",
  "Ступени",
  "Съезд",
  "Терраса",
  "Тоннель",
  "Тракт",
  "Тупик",
  "Угол",
  "Улица",
  "Ферма",
  "Шоссе",
];
