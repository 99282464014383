import { useState } from "react";
import styles from "./ForgotPassword.module.scss";
import { Input, Button } from "components";
import { ERR_NETWORK } from "constants/status";
import { Helmet } from "react-helmet";
import { axiosAuth } from "plugins/axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState([]);
  const [code, setCode] = useState([]);
  const [showCode, setShowCode] = useState(false);
  const [error, setError] = useState({
    code: "",
    email: "",
    errorMessage: "",
  });

  const sendEmail = async (event) => {
    event.preventDefault();
    let valid = true;
    setError({
      email: "",
      code: "",
      errorMessage: "",
    });
    if (!/.+@.+\..+/.test(email)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          email: "Неверный формат",
        };
      });
    }
    if (valid) {
      let response = await axiosAuth.sendEmail({ email });
      if (
        response.status === 404 &&
        response.data.Error === "There is no user with such email"
      ) {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: "Нет такого пользователя с такой почтой",
          };
        });
      } else if (response.result === "sent") {
        setShowCode(true);
      } else {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: ERR_NETWORK.message,
          };
        });
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные",
        };
      });
    }
  };

  const sendCode = async (event) => {
    event.preventDefault();
    let valid = true;
    setError({
      email: "",
      code: "",
      errorMessage: "",
    });
    if (!/.+@.+\..+/.test(email)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          email: "Неверный формат",
        };
      });
    }
    if (showCode && code === "") {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          code: "Неверный формат",
        };
      });
    }
    if (valid) {
      let response = await axiosAuth.verifyCode({ email, code });
      if (response.status === 404) {
        if (response.data.Error === "There is no user with such email") {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: "Нет такого пользователя с такой почтой",
            };
          });
        }
        if (
          response.data.Error ===
          "Such user did not initiate the renewal or already failed it"
        ) {
          setError((prev) => {
            return {
              ...prev,
              errorMessage:
                "Такой пользователь не запрашивал код или ввел неверный код",
            };
          });
        }
      } else if (
        response.status === 400 &&
        response.data.Error ===
          "Attemp has been failed. You have one less attempt to renew password"
      ) {
        setError((prev) => {
          return {
            ...prev,
            errorMessage:
              "Попытка провалилась. У вас на одну попытку обновить пароль меньше",
          };
        });
      } else if (response.access) {
        localStorage.setItem("token", response.access);
        localStorage.setItem("refresh_token", response.refresh);
        localStorage.setItem("name", response.username || "Пользователь");
        window.location.replace("/reset_password");
      } else {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: ERR_NETWORK.message,
          };
        });
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные",
        };
      });
    }
  };

  return (
    <section className={styles.container}>
      <Helmet title="Забыли пароль?">
        <meta name="description" content="Shalfey : Забыли пароль?" />
        <link rel="canonical" href="/forgot_password" />
      </Helmet>
      <div className={styles.content}>
        <h2>Забыли пароль?</h2>
        <Input
          placeholder="Email"
          value={email}
          marginBottom="20px"
          errorMessage={error.email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              sendEmail(event);
            }
          }}
        />
        <Input
          placeholder="Код"
          value={code}
          disable={!showCode}
          marginBottom="20px"
          errorMessage={error.code}
          onChange={(e) => setCode(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              sendCode(event);
            }
          }}
        />
        <Button
          text={showCode ? "Подтвердить" : "Отправить код"}
          padding="8px 14px"
          click={showCode ? sendCode : sendEmail}
        />
        {error.errorMessage !== "" ? <span>{error.errorMessage}</span> : null}
      </div>
    </section>
  );
};

export { ForgotPassword };
