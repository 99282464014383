import { Button, Input, Checkbox, Loader, Link } from "components";
import styles from "./BecomeSeller.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { axiosSeller } from "plugins/axios";
import { ERR_NETWORK, TOKEN_NOT_VALID } from "constants/status";
import { checkError } from "plugins/checkError";
import * as links from "constants/url";
import { AGREEMENT, PRIVACY_POLICY } from "plugins/endpoints";
import { Helmet } from "react-helmet";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { useOutsideClick } from "hooks";
import { STREETS } from "constants/streetType";
import { CHANGE_IS_OPEN_AUTH_MODAL } from "constants/actionType";
import store from "../../redux/stores";

const BecomeSeller = ({
  onClose = () => {
    store.dispatch({
      type: CHANGE_IS_OPEN_AUTH_MODAL,
      isOpenAuthModal: true,
    });
  },
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [loading, setLoading] = useState(true);
  const [needAuth, setNeedAuth] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const [tab, setTab] = useState(1);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    taxpayer_number: "",
    registration_reason_code: null,
    city: "",
    street: "",
    house: "",
    apartment: "",
    post_index: "",
    fio: "",
    additional_fio: "",
    additional_email: "",
    additional_phone: "",
    bank_name: "",
    bank_id_code: "",
    payment_account: "",
    correspondent_account: "",
    warehouse_city: "",
    warehouse_type_street: "Улица",
    warehouse_street: "",
    warehouse_house: "",
    warehouse_vlad: "",
    warehouse_apartment: "",
    warehouse_office: "",
    warehouse_structure: "",
    warehouse_block: "",
    warehouse_post_index: "",
    warehouse_working_hours: "",
    warehouse_shipping_hours: "",
    dpd_number: "",
    selected: false,
  });
  const [error, setError] = useState({
    name: "",
    phone: "",
    email: "",
    taxpayer_number: "",
    registration_reason_code: "",
    city: "",
    street: "",
    house: "",
    apartment: "",
    post_index: "",
    fio: "",
    additional_fio: "",
    additional_email: "",
    additional_phone: "",
    bank_name: "",
    bank_id_code: "",
    payment_account: "",
    correspondent_account: "",
    warehouse_city: "",
    warehouse_street: "",
    warehouse_type_street: "",
    warehouse_house: "",
    warehouse_vlad: "",
    warehouse_apartment: "",
    warehouse_office: "",
    warehouse_structure: "",
    warehouse_block: "",
    warehouse_post_index: "",
    warehouse_working_hours: "",
    warehouse_shipping_hours: "",
    dpd_number: "",
    errorMessage: "",
  });

  const getUser = async () => {
    setLoading(true);
    let response = await axiosSeller.getSellerCheck();
    switch (response.status) {
      case "Not a seller":
        break;
      case "Seller not moderated":
        navigate(links.WAIT_FOR_VERIFICATION.url);
        break;
      case "Seller approved":
        navigate(links.SELLER_PROFILE.url);
        break;
      default:
        setErrMessage("Для продолжения необходимо авторизоваться");
        setNeedAuth(true);
        setLoading(false);
        return;
    }
    let res = await axiosSeller.becomeSellerInfo();
    const { isError, messageError } = checkError(res);
    if (isError) {
      setErrMessage(messageError);
      setNeedAuth(true);
      setLoading(false);
      return;
    }
    setUser({
      ...user,
      fio: res?.fio || "",
      email: res?.email || "",
      phone: res?.phone === "None" ? "" : res?.phone,
    });
    setNeedAuth(false);
    setLoading(false);
  };

  const checkProperties = () => {
    let valid = true;
    let error_check = {
      name: "",
      phone: "",
      email: "",
      taxpayer_number: "",
      registration_reason_code: "",
      city: "",
      street: "",
      house: "",
      post_index: "",
      fio: "",
      additional_fio: "",
      additional_email: "",
      additional_phone: "",
      bank_name: "",
      bank_id_code: "",
      payment_account: "",
      correspondent_account: "",
      warehouse_city: "",
      warehouse_street: "",
      warehouse_house: "",
      warehouse_post_index: "",
      warehouse_working_hours: "",
      warehouse_shipping_hours: "",
      dpd_number: "",
      errorMessage: "",
    };
    let optional_fields = [
      "warehouse_apartment",
      "warehouse_vlad",
      "warehouse_office",
      "warehouse_structure",
      "warehouse_block",
      "apartment",
    ];
    for (let key in user) {
      if (
        key !== "registration_reason_code" &&
        (user[key] === null || user[key] === "") &&
        !optional_fields.includes(key)
      ) {
        valid = false;
        error_check[key] = "Введите корректные данные";
      }
      if (key === "registration_reason_code" && user[key] === "") {
        user[key] = null;
      }
    }
    setError(() => error_check);
    if (!/.+@.+\..+/.test(user.email)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          email: "Неверный формат",
        };
      });
    }
    if (!/.+@.+\..+/.test(user.additional_email)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          additional_email: "Неверный формат",
        };
      });
    }
    if (user.payment_account.length < 20) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          payment_account: "Должно быть больше 20 символов",
        };
      });
    }
    if (user.correspondent_account.toString().length !== 20) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          correspondent_account: "Должно быть 20 символов",
        };
      });
    }
    if (!user.correspondent_account.toString().startsWith("301")) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          correspondent_account: "Должно начинаться с 301",
        };
      });
    }
    if (!user.selected) {
      return false;
    }
    return valid;
  };

  const submitData = async (event) => {
    event.preventDefault();
    if (checkProperties()) {
      let response = await axiosSeller.becomeSeller({
        ...user,
        warehouse_type_street: user?.warehouse_type_street.toLowerCase(),
      });
      if (response.status === 400) {
        if (response.data.Error === "User already created a seller") {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: "Вы уже являетесь продавцом",
            };
          });
        } else if (response.data.Error === "Necessary fields are not given") {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: "Заполните все поля на врех вкладках",
            };
          });
        } else if (
          response.data.Error ===
          "Incorrect hours formats. Must be [0-23].[0-59]-[0-23].[0-59]"
        ) {
          setError((prev) => {
            return {
              ...prev,
              warehouse_working_hours: "Введите данные в формате 0.00-23.59",
              warehouse_shipping_hours: "Введите данные в формате 0.00-23.59",
            };
          });
        } else if (response.data.Error === "Incorrect address") {
          setError((prev) => {
            return {
              ...prev,
              warehouse_city: "Заполните поле адреса склада правильно",
              warehouse_street: "Заполните поле адреса склада правильно",
              warehouse_type_street: "Заполните поле адреса склада правильно",
              warehouse_house: "Заполните поле адреса склада правильно",
              warehouse_post_index: "Заполните поле адреса склада правильно",
            };
          });
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: response.data.Error,
            };
          });
        }
      } else if (response.status === 201) {
        window.location.href =
          process.env.REACT_APP_USER_URL + links.WAIT_FOR_VERIFICATION.url;
      } else if (response.status === 403) {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: TOKEN_NOT_VALID.message,
          };
        });
      } else {
        setError((prev) => {
          return {
            ...prev,
            errorMessage: ERR_NETWORK.message,
          };
        });
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные на всех вкладках",
        };
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return loading ? (
    <Loader bgColor="#20B900" align="center" />
  ) : needAuth ? (
    <div className={styles.error}>
      {errMessage}{" "}
      {errMessage === "Для продолжения необходимо авторизоваться" && (
        <Button
          text="Авторизация"
          padding="12px 22px"
          margin="20px auto"
          click={onClose}
        />
      )}
    </div>
  ) : (
    <section className={styles.container}>
      <Helmet title="Регистрация для продавцов">
        <meta
          name="description"
          content="Shalfey : Регистрация для продавцов"
        />
        <link rel="canonical" href="/becomeseller" />
      </Helmet>
      <h1 className={styles.heading}>Станьте партнером Шалфей</h1>
      <div className={styles.content}>
        <div className={styles.form}>
          <h2>Регистрация для продавцов</h2>
          <div className={styles.tabs}>
            <p
              className={`${styles.tab} ${tab === 1 && styles.active}`}
              onClick={() => setTab(1)}
            >
              Контактные данные
            </p>
            <p
              className={`${styles.tab} ${
                tab === 2 ? styles.active : styles.non_active
              }`}
              onClick={() => setTab(2)}
            >
              Банковские реквизиты
            </p>
            <p
              className={`${styles.tab} ${
                tab === 3 ? styles.active : styles.non_active
              }`}
              onClick={() => setTab(3)}
            >
              Склады
            </p>
          </div>
          {tab === 1 ? (
            <>
              <Input
                placeholder="Наименование продавца"
                value={user.name}
                errorMessage={error.name}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
              />
              <Input
                placeholder="Номер телефона"
                value={user.phone}
                errorMessage={error.phone}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
              />
              <Input
                placeholder="Email"
                value={user.email}
                errorMessage={error.email}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              <Input
                placeholder="ИНН"
                value={user.taxpayer_number}
                errorMessage={error.taxpayer_number}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setUser({
                      ...user,
                      taxpayer_number: e.target.value,
                    });
                  }
                }}
              />
              <Input
                placeholder="КПП"
                value={user.registration_reason_code || ""}
                errorMessage={error.registration_reason_code}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setUser({
                      ...user,
                      registration_reason_code: e.target.value,
                    });
                  }
                }}
              />
              <Input
                placeholder="Город"
                value={user.city}
                errorMessage={error.city}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => setUser({ ...user, city: e.target.value })}
              />
              <div className={styles.address_details}>
                <Input
                  placeholder="Улица"
                  value={user.street}
                  errorMessage={error.street}
                  marginBottom={vh(17)}
                  marginRight={vw(8)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(e) => setUser({ ...user, street: e.target.value })}
                />
                <Input
                  placeholder="Дом"
                  value={user.house}
                  errorMessage={error.house}
                  marginBottom={vh(17)}
                  marginRight={vw(8)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                    boxSizing: "border-box",
                    width: isMobile ? "100%" : vw(80),
                  }}
                  onChange={(e) => setUser({ ...user, house: e.target.value })}
                />
                <Input
                  placeholder="Офис"
                  value={user.apartment}
                  errorMessage={error.apartment}
                  marginBottom={vh(17)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                    boxSizing: "border-box",
                    width: isMobile ? "100%" : vw(80),
                  }}
                  onChange={(e) =>
                    setUser({ ...user, apartment: e.target.value })
                  }
                />
              </div>
              <Input
                placeholder="Почтовый индекс"
                value={user.post_index}
                errorMessage={error.post_index}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) =>
                  setUser({ ...user, post_index: e.target.value })
                }
              />
              <Input
                placeholder="ФИО руководителя"
                value={user.fio}
                errorMessage={error.fio}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => setUser({ ...user, fio: e.target.value })}
              />
              <Input
                placeholder="ФИО ответственного лица"
                value={user.additional_fio}
                errorMessage={error.additional_fio}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, additional_fio: ev.target.value })
                }
              />
              <Input
                placeholder="Email ответственного лица"
                value={user.additional_email}
                errorMessage={error.additional_email}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, additional_email: ev.target.value })
                }
              />
              <Input
                placeholder="Телефон ответственного лица"
                value={user.additional_phone}
                errorMessage={error.additional_phone}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, additional_phone: ev.target.value })
                }
              />
            </>
          ) : tab === 2 ? (
            <>
              <Input
                placeholder="Наименование банка"
                value={user.bank_name}
                errorMessage={error.bank_name}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) =>
                  setUser({ ...user, bank_name: e.target.value })
                }
              />
              <Input
                placeholder="БИК"
                value={user.bank_id_code}
                errorMessage={error.bank_id_code}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setUser({ ...user, bank_id_code: e.target.value });
                  }
                }}
              />
              <Input
                placeholder="Расчетный Счет"
                value={user.payment_account}
                errorMessage={error.payment_account}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) =>
                  setUser({ ...user, payment_account: e.target.value })
                }
              />
              <Input
                placeholder="Корреспондентский счет"
                value={user.correspondent_account}
                errorMessage={error.correspondent_account}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setUser({
                      ...user,
                      correspondent_account: e.target.value,
                    });
                  }
                }}
              />
            </>
          ) : (
            <>
              <Input
                placeholder="Город"
                value={user.warehouse_city}
                errorMessage={error.warehouse_city}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, warehouse_city: ev.target.value })
                }
              />
              <CustomSelectTypeStreet
                value={user.warehouse_type_street}
                change={(new_street) =>
                  setUser({ ...user, warehouse_type_street: new_street })
                }
              />
              <Input
                placeholder="Улица "
                value={user.warehouse_street}
                errorMessage={error.warehouse_street}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, warehouse_street: ev.target.value })
                }
              />
              <div className={styles.address_details}>
                <Input
                  placeholder="Дом"
                  value={user.warehouse_house}
                  errorMessage={error.warehouse_house}
                  marginBottom={vh(17)}
                  marginRight={vw(8)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(ev) =>
                    setUser({ ...user, warehouse_house: ev.target.value })
                  }
                />
                <Input
                  placeholder="Владение"
                  value={user.warehouse_vlad}
                  errorMessage={error.warehouse_vlad}
                  marginBottom={vh(17)}
                  marginRight={vw(8)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(ev) =>
                    setUser({ ...user, warehouse_vlad: ev.target.value })
                  }
                />
                <Input
                  placeholder="Корпус"
                  value={user.warehouse_block}
                  errorMessage={error.warehouse_block}
                  marginBottom={vh(17)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(ev) =>
                    setUser({ ...user, warehouse_block: ev.target.value })
                  }
                />
              </div>
              <div className={styles.address_details}>
                <Input
                  placeholder="Строение"
                  value={user.warehouse_structure}
                  errorMessage={error.warehouse_structure}
                  marginBottom={vh(17)}
                  marginRight={vw(8)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(ev) =>
                    setUser({ ...user, warehouse_structure: ev.target.value })
                  }
                />
                <Input
                  placeholder="Квартира"
                  value={user.warehouse_apartment}
                  errorMessage={error.warehouse_apartment}
                  marginBottom={vh(17)}
                  marginRight={vw(8)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(ev) =>
                    setUser({ ...user, warehouse_apartment: ev.target.value })
                  }
                />
                <Input
                  placeholder="Офис"
                  value={user.warehouse_office}
                  errorMessage={error.warehouse_office}
                  marginBottom={vh(17)}
                  lineHeight={vh(16)}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    padding: `${isMobile ? vhM(16) : vh(16)} ${
                      isMobile ? vwM(16) : vw(16)
                    }`,
                    height: "auto",
                    borderRadius: vh(10),
                  }}
                  onChange={(ev) =>
                    setUser({ ...user, warehouse_office: ev.target.value })
                  }
                />
              </div>
              <Input
                placeholder="Почтовый индекс"
                value={user.warehouse_post_index}
                errorMessage={error.warehouse_post_index}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, warehouse_post_index: ev.target.value })
                }
              />
              <Input
                placeholder="Время работы"
                value={user.warehouse_working_hours}
                errorMessage={error.warehouse_working_hours}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, warehouse_working_hours: ev.target.value })
                }
              />
              <Input
                placeholder="Время отгрузки"
                value={user.warehouse_shipping_hours}
                errorMessage={error.warehouse_shipping_hours}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({
                    ...user,
                    warehouse_shipping_hours: ev.target.value,
                  })
                }
              />
              <Input
                placeholder="Клиентский номер DPD"
                value={user.dpd_number}
                errorMessage={error.dpd_number}
                marginBottom={vh(17)}
                lineHeight={vh(16)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  padding: `${isMobile ? vhM(16) : vh(16)} ${
                    isMobile ? vwM(16) : vw(16)
                  }`,
                  height: "auto",
                  borderRadius: vh(10),
                }}
                onChange={(ev) =>
                  setUser({ ...user, dpd_number: ev.target.value })
                }
              />
            </>
          )}
          <div style={{ display: "flex", marginBottom: vh(22) }}>
            <Checkbox
              width="2vh"
              height="2vh"
              isSelected={user.selected}
              marginRight={isMobile ? vwM(9.5) : vw(9.5)}
              styleBack={{ borderRadius: "0.5vh" }}
              change={(value) => setUser({ ...user, selected: value })}
            />
            <div className={styles.link}>
              Я соглашаюсь с{" "}
              <Link
                path={process.env.REACT_APP_BASE_URL + AGREEMENT}
                color="#55C83D"
                target="_blank"
              >
                Договором о сотрудничестве
              </Link>{" "}
              и предоставляю согласие на обработку персональных данных в
              соответствии с{" "}
              <Link
                path={process.env.REACT_APP_BASE_URL + PRIVACY_POLICY}
                color="#55C83D"
                target="_blank"
              >
                Политикой обработки персональных данных
              </Link>
            </div>
          </div>
          {error.errorMessage !== "" ? <span>{error.errorMessage}</span> : null}
          <Button text="Зарегистрироваться" padding="13px" click={submitData} />
        </div>
      </div>
    </section>
  );
};

const CustomSelectTypeStreet = ({ value = "", change }) => {
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  const changeSelect = (street) => {
    setOpenSelect(false);
    change(street);
  };
  return (
    <div className={styles.custom_input}>
      <div
        ref={refSelect}
        name="street"
        className={styles.custom_input__select}
      >
        <ButtonSelect
          title={value === "Улица" ? "Тип улицы" : value || "Тип улицы"}
          openSelect={openSelect}
          click={() => setOpenSelect(!openSelect)}
        />
        <ul className={`${styles.streets} ${openSelect ? styles.open : ""}`}>
          <div>
            {STREETS.map((street, index) => (
              <li key={index} onClick={() => changeSelect(street)}>
                <span className={value === street ? styles.select : null}>
                  {street}
                </span>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};
const ButtonSelect = ({ title = "", openSelect = false, click = () => {} }) => {
  return (
    <button className={openSelect ? styles.open : ""} onClick={click}>
      <span>{title}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={vh(12)}
        height={vh(13)}
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
          stroke="#20b900"
          strokeWidth="0.61828"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export { BecomeSeller };
