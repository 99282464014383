import React, { useState, useEffect } from "react";
import styles from "./Favorite.module.scss";
import * as sortingType from "constants/sortingType";
import { MainMenu, NewProduct, SliderProducts, Button, Tabs } from "components";
import { axiosFavorite, axiosHome } from "plugins/axios";
import { checkError } from "plugins/checkError";
import { useNavigate } from "react-router-dom";
import { Service } from "pages/Services/Service";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

const Favorite = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [hits, setHits] = useState([]);
  const [sorting, setSorting] = useState(sortingType.DATE.name);

  useEffect(() => {
    const getFavoriteList = async () => {
      if (localStorage.getItem("token")) {
        let res = await axiosFavorite.getMyFavorite(sorting);
        const { isError, MessageError } = checkError(res);
        if (!isError) {
          setProducts(
            res?.products.map((e) => ({
              ...e,
              product: { ...e.product, in_favorite: true },
            })) || []
          );
          setServices(
            res?.services.map((e) => ({ ...e.service, in_favorite: true })) ||
              []
          );
        }
      } else {
        let productsUnauth =
          JSON.parse(localStorage.getItem("favorite_products")) || [];
        let servicesUnauth =
          JSON.parse(localStorage.getItem("favorite_services")) || [];

        let res = await axiosFavorite.getMyFavoriteForUnauth({
          products: productsUnauth,
          services: servicesUnauth,
          order_by: sorting,
        });
        const { isError, MessageError } = checkError(res);
        if (!isError) {
          setProducts(
            res?.products?.map((e) => ({
              ...e,
              product: { ...e, in_favorite: true },
            })) || []
          );
          setServices(
            res?.services?.map((e) => ({ ...e, in_favorite: true })) || []
          );
        }
      }
      let result = await axiosHome.getHits();
      setHits(
        result.purchased.map((e) => ({ ...e, in_favorite: false })) || []
      );
    };
    getFavoriteList();
  }, [sorting]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeSorting = (newSorting) => {
    setSorting(newSorting);
  };
  const returnToMain = () => {
    navigate("/");
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Helmet title="Избранное">
        <meta name="description" content="Shalfey : Избранное" />
        <link rel="canonical" href="/favorite" />
      </Helmet>
      {!isMobile && (
        <div className={styles.menu}>
          <MainMenu />
        </div>
      )}
      <div className={styles.favorite}>
        {products.length || services.length ? (
          <>
            <div className={styles.favorite__header}>
              Избранное<span>{products.length + services.length}</span>
            </div>
            {products.length ? (
              <>
                {!isMobile && (
                  <Tabs
                    tabs={sortingType.SORTING_TYPE_MAP}
                    currentTab={sorting}
                    changeTab={changeSorting}
                  />
                )}
                <div className={styles.products}>
                  <div className={styles.products__title}>Товары</div>
                  {isMobile && (
                    <Tabs
                      tabs={sortingType.SORTING_TYPE_MAP}
                      currentTab={sorting}
                      changeTab={changeSorting}
                    />
                  )}
                  <div className={styles.favorite__list}>
                    {products.map((e) => (
                      <div key={e.id}>
                        <NewProduct
                          item={e.product}
                          width="auto"
                          height="auto"
                          imgStyle={{
                            width: "100%",
                            height: vh(218),
                          }}
                          isDefaultStyle
                          slider
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {services.length ? (
              <div className={styles.services}>
                <div className={styles.services__title}>Услуги</div>
                {services.length ? (
                  <div className={styles.services__list}>
                    {services.map((e, index) => (
                      <Service key={index} element={e} />
                    ))}
                  </div>
                ) : (
                  "пусто"
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className={styles.basket__empty}>
            <div className={styles.favorite__header}>В избранном пусто</div>
            <div className={styles.favorite__empty__additional_info}>
              Чтобы найти товары, воспользуйтесь поиском
            </div>
            <div className={styles.favorite__empty__actions}>
              <Button
                text="Начать покупки"
                fontSize={isMobile ? vhM(16) : vh(16)}
                lineHeight={isMobile ? vhM(20) : vh(20)}
                fontWeight="600"
                padding={`${isMobile ? vhM(12) : vh(12)} ${
                  isMobile ? vwM(32) : vw(32)
                }`}
                bgColor="#20B900"
                borderRadius={isMobile ? vhM(10) : vh(10)}
                click={returnToMain}
              />
            </div>
            {hits ? (
              <div className={styles.favorite__empty__hits}>
                <div className={styles.favorite__empty__hits__header}>
                  Хиты продаж
                </div>
                <SliderProducts items={hits} />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { Favorite };
