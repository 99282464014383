import * as url from "../endpoints";
import { instance, config } from "./createAxios";
import { removeToken } from "../token";

export const axiosUserProfile = {
  async getProfileInfo() {
    try {
      const response = await instance.get(url.GET_PROFILE(), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getProfileDetails() {
    try {
      const response = await instance.get(url.GET_PROFILE_DETAILS, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateProfileBasic(data) {
    try {
      const response = await instance.put(
        url.UPDATE_BASIC_PROFILE,
        data,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async updateProfileAdd(data) {
    try {
      const response = await instance.put(url.UPDATE_ADD_PROFILE, data, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async updateProfilePic(data) {
    try {
      let formData = new FormData();
      if (data) {
        formData.append("avatar", data, data.name);
      }
      const response = await instance.put(
        url.UPDATE_PIC_PROFILE,
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async deleteProfile() {
    try {
      const response = await instance.delete(url.DELETE_PROFILE, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async getTrackOrdres(params) {
    try {
      const response = await instance.get(
        url.GET_TRACK_ORDERS + params,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async getPurchases(params) {
    try {
      const response = await instance.get(url.GET_PURCHASE + params, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async getPurchase(id) {
    try {
      const response = await instance.get(
        url.GET_PURCHASE_PRODUCT(id) +
          `?city=${localStorage.getItem("city") || ""}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendMark(id, data) {
    try {
      let formData = new FormData();
      formData.append("mark", data.mark);
      formData.append("comment", data.comment);
      for (let i = 1; i < data.Images.length + 1; i++) {
        formData.append(
          "pic_" + i,
          data.Images[i - 1],
          data.Images[i - 1].name
        );
      }

      const response = await instance.post(url.SEND_MARK(id), formData, config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getChat(id) {
    try {
      const response = await instance.get(url.GET_CHAT_PURCHASE(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendQuestion(id, params) {
    try {
      await instance.post(url.SEND_QUESTION(id), params, config);
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async getMyReviews(page) {
    try {
      const response = await instance.get(
        url.GET_MY_REVIEWES + "?page=" + page,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async deleteMyReview(id) {
    try {
      const response = await instance.delete(
        url.EDIT_REVIEW(id) + "delete",
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async editMyReview(id, data) {
    try {
      let formData = new FormData();
      formData.append("mark", data.mark);
      formData.append("comment", data.comment);
      for (let i = 1; i < data.pic_urls.length + 1; i++) {
        formData.append(
          "pic_" + i,
          data.pic_urls[i - 1],
          data.pic_urls[i - 1].name
        );
      }
      for (let i = 1; i < data.Images.length + 1; i++) {
        formData.append("old_img_" + i, data.Images[i - 1]);
      }
      formData.append("old_img_count", data.Images.length);
      const response = await instance.put(
        url.EDIT_REVIEW(id) + "update",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getNotifications() {
    try {
      const response = await instance.get(url.GET_NOTIFICATIONS, config);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getStatusHistory(order_number) {
    try {
      const response = await instance.get(
        url.GET_STATUS_HISTORY(order_number),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async setNotificationSeen(order_number) {
    try {
      const response = await instance.put(
        url.CHANGE_NOTIFICATION_SEEN(order_number),
        {},
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getChatList(query = "", page = 1) {
    try {
      const response = await instance.get(
        url.GET_CHAT_LIST_PROFILE(query, page),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getChatOrder(order = "", page = 1) {
    try {
      const response = await instance.get(
        url.GET_CHAT_ORDER_PROFILE(order, page),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async sendMessageChat(order = "", text = "") {
    try {
      const response = await instance.post(
        url.SEND_MESSAGE_CHAT_PROFILE(order),
        { text },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async createCancelOrder(order_number, cancel_type, product_id) {
    try {
      const response = await instance.post(
        url.CREATE_CANCEL_ORDER,
        { order_number, cancel_type, product_id },
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async createReturnProduct(data) {
    try {
      let formData = new FormData();
      formData.append("purchase_pk", data.purchase_pk);
      formData.append("text", data.comment);
      for (let i = 1; i < data.Images.length + 1; i++) {
        formData.append(
          "pic_" + i,
          data.Images[i - 1],
          data.Images[i - 1].name
        );
      }
      const response = await instance.post(
        url.CREATE_RETURN_PRODUCT,
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
};
