import star_12 from "assets/img/star_12.svg";
import star_13 from "assets/img/star_13.svg";
import styles from "./Reviews.module.scss";

import React, { useState } from "react";
import { ModalImg, MobileModalImg } from "components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Reviews = ({ reviews, reviewsCount, productId }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImages, setSelectedImages] = useState();
  const [modalMobileOpen, setModalMobileOpen] = useState(false);

  const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];
  let marksCount = [
    {
      star_count: 1,
      star: "звезда",
      mark_count: reviews.one_count,
      percent: (reviews.one_count * 100) / reviewsCount,
    },
    {
      star_count: 2,
      star: "звезды",
      mark_count: reviews.two_count,
      percent: (reviews.two_count * 100) / reviewsCount,
    },
    {
      star_count: 3,
      star: "звезды",
      mark_count: reviews.three_count,
      percent: (reviews.three_count * 100) / reviewsCount,
    },
    {
      star_count: 4,
      star: "звезды",
      mark_count: reviews.four_count,
      percent: (reviews.four_count * 100) / reviewsCount,
    },
    {
      star_count: 5,
      star: "звезд",
      mark_count: reviews.five_count,
      percent: (reviews.five_count * 100) / reviewsCount,
    },
  ];
  let allImg = [];
  const [modalOpen, setModalOpen] = useState(false);

  const allPic = (main_pic) => {
    reviews.list?.map((items) =>
      items.pic_urls?.map((item) => {
        allImg.push({ pic_url: item.pic_url });
        if (main_pic === item.pic_url) setSelectedImage(allImg.length - 1);
      })
    );

    setSelectedImages(allImg);
    if (isMobile) {
      setModalMobileOpen(true);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  const zoomImg = (pic_urls, pic_url) => {
    setSelectedImage(pic_url);
    setSelectedImages(pic_urls);
    if (isMobile) {
      setModalMobileOpen(true);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.reviews}>
        {!isMobile && <p className={styles.total}>Отзывы</p>}

        {reviewsCount > 0 ? (
          <>
            <div className={styles.all_pic}>
              {reviews.list?.map((items) =>
                items.pic_urls?.map((item, index) => (
                  <img
                    key={index}
                    src={item.pic_url}
                    alt="Товар"
                    onClick={() => allPic(item.pic_url)}
                  />
                ))
              )}
            </div>
            {reviews.list?.slice(0, 2).map((item, index) => (
              <div key={index} className={styles.review}>
                <div className={styles.stars}>
                  {[...Array(item.mark)].map((x, i) => (
                    <img
                      key={i}
                      src={star_12}
                      className={styles.star}
                      alt="Звезда"
                    />
                  ))}
                  {[...Array(5 - item.mark)].map((x, i) => (
                    <img
                      key={i}
                      src={star_13}
                      className={styles.star}
                      alt="Звезда"
                    />
                  ))}

                  <p>{`${item.username}, ${item.creation_date.slice(8, 10)} ${
                    months[Number(item.creation_date.slice(5, 7)) - 1]
                  }`}</p>
                </div>
                <div className={styles.review_pic}>
                  {item.pic_urls?.map((product, index) => (
                    <img
                      key={index}
                      src={product.pic_url}
                      alt="Товар"
                      onClick={() => zoomImg(item.pic_urls, index)}
                    />
                  ))}
                </div>
                <h1 className={styles.review_text}>{item.comment}</h1>
              </div>
            ))}
          </>
        ) : (
          <p className={styles.reviews__empty}>Отзывов нет</p>
        )}
        {reviewsCount > 0 && (
          <div
            className={styles.all_rev}
            onClick={() => navigate("/product/allreviews/" + productId)}
          >
            <h1>Все отзывы </h1> <h2>{reviewsCount}</h2>
          </div>
        )}
      </div>
      {reviewsCount > 0 && (
        <div className={styles.reviews_stars}>
          {isMobile && <p className={styles.total}>Отзывы</p>}
          <div className={styles.star_part}>
            <div>
              {reviews.mark
                ? [...Array(Math.round(reviews.mark))].map((x, i) => (
                    <img key={i} src={star_12} alt="Звезда" />
                  ))
                : null}
            </div>
            <p>{`${reviews.mark?.toFixed(1)} / 5`}</p>
          </div>

          <div className={styles.line} />
          {marksCount.reverse().map((item, index) => (
            <div className={styles.star_line} key={index}>
              <h1>{`${item.star_count} ${item.star}`}</h1>
              <div
                style={{
                  width: "140px",
                  height: "2px",
                  background: "#EFEFED",
                  right: "0",
                }}
              >
                <div
                  style={{
                    width: `${item.percent}%`,
                    height: "100%",
                    background: "#DF730E",
                  }}
                />
              </div>
              <h2>{item.mark_count}</h2>
            </div>
          ))}
        </div>
      )}
      <MobileModalImg
        isOpen={modalMobileOpen}
        pic_urls={selectedImages}
        onClose={() => setModalMobileOpen(false)}
      />
      {modalOpen ? (
        <ModalImg
          isOpen={modalOpen}
          pic_urls={selectedImages}
          main_img={selectedImage}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

export { Reviews };
