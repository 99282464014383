import star_12 from "assets/img/star_12.svg";
import star_13 from "assets/img/star_13.svg";
import up from "assets/img/up.svg";
import down from "assets/img/down.svg";

import styles from "./AllReviews.module.scss";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { ModalImg, MainMenu, MobileModalImg } from "components";
import { useParams } from "react-router-dom";
import { axiosProduct } from "plugins/axios";

const AllReviews = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImages, setSelectedImages] = useState();
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState([]);
  const [modalMobileOpen, setModalMobileOpen] = useState(false);
  const params = useParams();

  const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];
  let [marksCount, setMarksCount] = useState([
    {
      star_count: 5,
      star: "звезд",
      mark_count: "",
      percent: "",
    },
    {
      star_count: 4,
      star: "звезды",
      mark_count: "",
      percent: "",
    },
    {
      star_count: 3,
      star: "звезды",
      mark_count: "",
      percent: "",
    },
    {
      star_count: 2,
      star: "звезды",
      mark_count: "",
      percent: "",
    },
    {
      star_count: 1,
      star: "звезда",
      mark_count: "",
      percent: "",
    },
  ]);
  let allImg = [];
  const [modalOpen, setModalOpen] = useState(false);

  const allPic = (main_pic) => {
    reviews?.list.map((items) =>
      items.pic_urls?.map((item, index) => {
        allImg.push({
          pic_url: item.pic_url,
        });
        if (main_pic === item.pic_url) {
          setSelectedImage(allImg.length - 1);
        }
      })
    );

    setSelectedImages(allImg);
    if (isMobile) {
      setModalMobileOpen(true);
    } else {
      setModalOpen(!modalOpen);
    }
  };
  const getReviews = async () => {
    let result = await axiosProduct.getProductReviews(params.id);
    setReviewsCount(result.count || 0);
    setReviews(result.results || []);
    let newMarksCount = marksCount;
    newMarksCount[4].mark_count = result.results.one_count || 0;
    newMarksCount[3].mark_count = result.results.two_count || 0;
    newMarksCount[2].mark_count = result.results.three_count || 0;
    newMarksCount[1].mark_count = result.results.four_count || 0;
    newMarksCount[0].mark_count = result.results.five_count || 0;
    newMarksCount[4].percent =
      (result.results.one_count * 100) / result.count || 0;
    newMarksCount[3].percent =
      (result.results.two_count * 100) / result.count || 0;
    newMarksCount[2].percent =
      (result.results.three_count * 100) / result.count || 0;
    newMarksCount[1].percent =
      (result.results.four_count * 100) / result.count || 0;
    newMarksCount[0].percent =
      (result.results.five_count * 100) / result.count || 0;
    setMarksCount(newMarksCount);
  };

  const zoomImg = (pic_urls, pic_url) => {
    setSelectedImage(pic_url);
    setSelectedImages(pic_urls);
    if (isMobile) {
      setModalMobileOpen(true);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  const showAnswer = (id) => {
    const categories = document.getElementById(id);
    const galochka = document.getElementById("galochka_" + id);
    if (categories.style.display === "block") {
      categories.style.display = "none";
      galochka.innerHTML = `<img src=${down} alt="Вниз" />`;
    } else {
      categories.style.display = "block";
      galochka.innerHTML = `<img src=${up} alt="Вверх" />`;
    }
  };

  useEffect(() => {
    getReviews();
  }, [params.id]);

  return (
    <div className={styles.container}>
      <Helmet title="Отзывы">
        <meta name="description" content="Shalfey : Отзывы" />
        <link rel="canonical" href={`/product/allreviews/${params.id}`} />
      </Helmet>
      <div className={styles.menu}>
        <MainMenu />
      </div>
      <div className={styles.section}>
        <div className={styles.reviews}>
          <div className={styles.total}>
            <p>Отзывы</p>
            <h1>{reviewsCount}</h1>
          </div>
          <div style={{ width: "300px" }}>
            <div className={styles.star_part}>
              <div>
                {reviews.mark
                  ? [...Array(Math.round(reviews.mark))].map((x, i) => (
                      <img key={i} src={star_12} alt="Звезда" />
                    ))
                  : null}
              </div>
              <p>{`${reviews.mark?.toFixed(1)} / 5`}</p>
            </div>

            <div className={styles.line} />
            {marksCount.map((item, index) => (
              <div className={styles.star_line} key={index}>
                <h1>{`${item.star_count} ${item.star}`}</h1>
                <div
                  style={{
                    width: "185px",
                    height: "2px",
                    background: "#EFEFED",
                    right: "0",
                  }}
                >
                  <div
                    style={{
                      width: `${item.percent}%`,
                      height: "100%",
                      background: "#DF730E",
                    }}
                  />
                </div>
                <h2>{item.mark_count}</h2>
              </div>
            ))}
          </div>
          <div className={styles.all_pic}>
            {reviews.list?.map((items) =>
              items.pic_urls?.map((item, index) => (
                <img
                  key={index}
                  src={item.pic_url}
                  alt="Товар"
                  onClick={() => allPic(item.pic_url)}
                />
              ))
            )}
          </div>
          <div className={styles.table}>
            {reviews.list?.map((item, index) => (
              <div key={index} className={styles.review}>
                <div className={styles.stars}>
                  {[...Array(item.mark)].map((x, i) => (
                    <img
                      key={i}
                      src={star_12}
                      className={styles.star}
                      alt="Звезда"
                    />
                  ))}
                  {[...Array(5 - item.mark)].map((x, i) => (
                    <img
                      key={i}
                      src={star_13}
                      className={styles.star}
                      alt="Звезда"
                    />
                  ))}

                  <p>{`${item.username}, ${item.creation_date.slice(8, 10)} ${
                    months[Number(item.creation_date.slice(5, 7)) - 1]
                  }`}</p>
                </div>
                <div className={styles.review_pic}>
                  {item.pic_urls?.map((product, index) => (
                    <img
                      key={index}
                      src={product.pic_url}
                      alt="Товар"
                      onClick={() => zoomImg(item.pic_urls, index)}
                    />
                  ))}
                </div>
                <h1 className={styles.review_text}>{item.comment}</h1>
                {item.answer && (
                  <div
                    className={styles.paragraph}
                    onClick={() => showAnswer(index)}
                  >
                    <h2 className={styles.answer}>Ответ представителя</h2>
                    <b id={`galochka_${index}`} style={{ marginLeft: "1vh" }}>
                      <img src={down} alt="Вверх" />
                    </b>
                  </div>
                )}
                <div id={index} className={styles.text}>
                  <h3 className={styles.review_text}>{item.answer}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>

        {modalOpen ? (
          <ModalImg
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            pic_urls={selectedImages}
            main_img={selectedImage}
          />
        ) : null}
        <MobileModalImg
          isOpen={modalMobileOpen}
          pic_urls={selectedImages}
          onClose={() => setModalMobileOpen(false)}
        />
      </div>
    </div>
  );
};

export { AllReviews };
