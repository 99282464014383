export const PC = { code: "PC", text: "В сборке" };
export const DV = { code: "DV", text: "Готово к выдаче" };
export const RV = { code: "RV", text: "Получено" };
export const CL = { code: "CL", text: "Отменен" };

export const WP = { code: "wait_for_payment", text: "Ожидает оплаты" };
export const PD = { code: "PD", text: "Оплачен" };
export const NP = { code: "NP", text: "Не оплачен" };
export const ST = { code: "ST", text: "Передан в доставку" };

export const DELIVERIES_STATUSES = [NP, PD, PC, DV, CL, ST, RV];
export const DELIVERIES_PAYMENT = [NP, PD];
