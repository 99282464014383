import React, { useEffect, useState } from "react";
import styles from "./MobileFilter.module.scss";
import { Button } from "components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { axiosHome, axiosFilter } from "plugins/axios";
import birdie from "assets/img/birdie.svg";
import arrow_2 from "assets/img/arrow_2.svg";

const MobileFilter = ({ onClose, ...rest }) => {
  const navigate = useNavigate();
  const { catalogue, tab: productsTab } = useParams();
  const [filterTab, setFilterTab] = useState(true);
  const [medicineTab, setMedicineTab] = useState(false);
  const [searchParams] = useSearchParams();
  const [sorting, setSorting] = useState(1);

  const [typesOfMedicine, setTypesOfMedicine] = useState([]);
  const [herbalData, setHerbalData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const [price, setPrice] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [medicine, setMedicine] = useState([]);
  const [actualMedicine, setActualMedicine] = useState([]);
  const [categories, setCategories] = useState([]);
  const [actualCategories, setActualCategories] = useState([]);

  const setPriceType = (value) => {
    if (price === value) {
      setPrice("");
    } else {
      setPrice(value);
    }
  };

  const setMedicineType = (value) => {
    let findItem = medicine.find((item) => item === value);
    if (findItem) {
      setMedicine(medicine.filter((item) => item !== value));
    } else {
      setMedicine([value, ...medicine]);
    }
  };

  const setCategoriesType = (value) => {
    let findItem = categories.find((item) => item === value);
    if (findItem) {
      setCategories(categories.filter((item) => item !== value));
    } else {
      setCategories([value, ...categories]);
    }
  };

  const setParams = () => {
    onClose();
    navigate(
      `/filter/${productsTab}?parent_category=${
        searchParams.get("parent_category") || ""
      }&symptoms=${
        searchParams.get("symptoms") || ""
      }&categories=${categories}&order_by=${price}&type_of_medicine=${medicine}`
    );
  };

  const setStartParams = () => {
    setPrice(0);
    setCategories([]);
    setMedicine([]);
  };

  const getFilterParams = async () => {
    let result = await axiosFilter.getFilterParams();
    setHerbalData(result.herbal_teas || []);
    setCategoriesData(result.drug_categories || []);
  };

  const getMainTypes = async () => {
    let result = await axiosHome.getMainTypes();
    setTypesOfMedicine(result || []);
  };

  useEffect(() => {
    getFilterParams();
    getMainTypes();
  }, []);

  useEffect(() => {
    setPrice(searchParams.get("order_by") || "");
    setCategories(searchParams.get("categories")?.split(",") || []);
    setMedicine(
      searchParams.get("type_of_medicine")?.split(",").filter(Boolean) || []
    );
  }, [catalogue]);

  useEffect(() => {
    switch (productsTab) {
      case "hits":
      case "new":
      case "promotions":
      case "search":
        setMedicineTab(true);
        break;
      default:
        setMedicineTab(false);
    }
  }, [productsTab]);

  return (
    <div className={styles.content} style={{ ...rest }}>
      {filterTab ? (
        <div className={styles.mobile_filter_heading}>Фильтры</div>
      ) : (
        <div className={styles.mobile_filter_title}>{sorting}</div>
      )}

      {filterTab && (
        <div style={{ height: "65vh" }}>
          <div
            className={styles.paragraph}
            onClick={() => {
              setFilterTab(false);
              setPrice(actualPrice);
              setSorting("По цене");
            }}
          >
            <h2 className={styles.composition}>По цене</h2>
            <img src={arrow_2} alt="По цене" />
          </div>
          {medicineTab && (
            <div
              className={styles.paragraph}
              onClick={() => {
                setFilterTab(false);
                setMedicine(actualMedicine);
                setSorting("Мир медицины");
              }}
            >
              <h2 className={styles.composition}>Мир медицины</h2>
              <img src={arrow_2} alt="Мир медицины" />
            </div>
          )}
          <div
            className={styles.paragraph}
            onClick={() => {
              setFilterTab(false);
              setCategories(actualCategories);
              setSorting("Сборы трав");
            }}
          >
            <h2 className={styles.composition}>Сборы трав</h2>
            <img src={arrow_2} alt="Сборы трав" />
          </div>
          <div
            className={styles.paragraph}
            onClick={() => {
              setFilterTab(false);
              setCategories(actualCategories);
              setSorting("Категории препаратов");
            }}
          >
            <h2 className={styles.composition}>Категории препаратов</h2>
            <img src={arrow_2} alt="Категории препаратов" />
          </div>
        </div>
      )}

      <div
        className={styles.variants}
        style={{ display: sorting === "По цене" ? "block" : "none" }}
      >
        <p
          onClick={() => setPriceType("asc")}
          style={{ color: price === "asc" ? "#20B900" : "#5f6b69" }}
        >
          По возрастанию
        </p>
        <div
          className={styles["horizontal-line"]}
          style={{
            borderBottomColor: price === "asc" ? "#20B900" : "#5f6b69",
          }}
        />
        <p
          onClick={() => setPriceType("desc")}
          style={{ color: price === "desc" ? "#20B900" : "#5f6b69" }}
        >
          По убыванию
        </p>
        <div
          className={styles["horizontal-line"]}
          style={{
            borderBottomColor: price === "desc" ? "#20B900" : "#5f6b69",
          }}
        />
      </div>
      <div
        className={styles.variants}
        style={{
          display: sorting === "Мир медицины" ? "block" : "none",
        }}
      >
        {typesOfMedicine.map((element) => (
          <div key={element.id}>
            <p
              onClick={() => setMedicineType(element.name)}
              style={{
                color: medicine.find((item) => item === element.name)
                  ? "#20B900"
                  : "#5f6b69",
              }}
            >
              {medicine.find((item) => item === element.name) && (
                <img src={birdie} alt="v" />
              )}
              {element.name}
            </p>
            <div
              className={styles["horizontal-line"]}
              style={{
                borderBottomColor: medicine.find(
                  (item) => item === element.name
                )
                  ? "#20B900"
                  : "#5f6b69",
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.variants}
        style={{ display: sorting === "Сборы трав" ? "block" : "none" }}
      >
        {herbalData.map((element) => (
          <div key={element.id}>
            <p
              onClick={() => setCategoriesType(element.id)}
              style={{
                color: categories.find((item) => item == element.id)
                  ? "#20B900"
                  : "#5f6b69",
              }}
            >
              {categories.find((item) => item == element.id) && (
                <img src={birdie} alt="v" />
              )}
              {element.name}
            </p>
            <div
              className={styles["horizontal-line"]}
              style={{
                borderBottomColor: categories.find((item) => item == element.id)
                  ? "#20B900"
                  : "#5f6b69",
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.variants}
        style={{
          display: sorting === "Категории препаратов" ? "block" : "none",
        }}
      >
        {categoriesData.map((element) => (
          <div key={element.id}>
            <p
              onClick={() => setCategoriesType(element.id)}
              style={{
                color: categories.find((item) => item == element.id)
                  ? "#20B900"
                  : "#5f6b69",
              }}
            >
              {categories.find((item) => item == element.id) && (
                <img src={birdie} alt="v" />
              )}
              {element.name}
            </p>
            <div
              className={styles["horizontal-line"]}
              style={{
                borderBottomColor: categories.find((item) => item == element.id)
                  ? "#20B900"
                  : "#5f6b69",
              }}
            />
          </div>
        ))}
      </div>
      <Button
        text={filterTab ? "Готово" : "Сохранить"}
        color="#20B900"
        bgColor="#F8FBF9"
        padding="1.6vh 2.5vh"
        margin="3vh 0 0"
        click={
          filterTab
            ? setParams
            : () => {
                switch (sorting) {
                  case "Категории препаратов":
                    setActualCategories(categories);
                    break;
                  case "Сборы трав":
                    setActualCategories(categories);
                    break;
                  case "Мир медицины":
                    setActualMedicine(medicine);
                    break;
                  default:
                    setActualPrice(price);
                }
                setSorting("");
                setFilterTab(true);
              }
        }
      />
      <Button
        text={filterTab ? "Очистить" : "Назад"}
        color="#9CA5A3"
        bgColor="#fff"
        padding="1.6vh 2.5vh"
        click={
          filterTab
            ? setStartParams
            : () => {
                setSorting("");
                setFilterTab(true);
              }
        }
      />
    </div>
  );
};

export { MobileFilter };
