import React, { useState, useEffect, useRef } from "react";
import styles from "./MobileChat.module.scss";
import { vhM, vwM } from "plugins/convertPXtoVHVW";
import { axiosUserProfile } from "plugins/axios";
import { ItemChatList, MessageChat, Input } from "components";
import search_icon from "assets/img/search.svg";

const MobileChat = () => {
  const [chatList, setChatList] = useState([]);
  const [countChats, setCountChats] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedChat, setSelectedChat] = useState([]);
  const scrollContainerRef = useRef(null);

  const [wasLastChatList, setWasLastChatList] = useState(false);
  const [currentChatListPage, setCurrentChatListPage] = useState(1);
  const [nextChatListPage, setNextChatListPage] = useState(2);
  const [wasLastMessageList, setWasLastMessageList] = useState(false);
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [nextChatPage, setNextChatPage] = useState(2);
  const [searchChat, setSearchChat] = useState("");
  const [messageText, setMessageText] = useState("");

  const [isUpdateList, setIsUpdateList] = useState(false);

  const onScrollChatList = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentChatListPage < nextChatListPage) {
      setCurrentChatListPage(currentChatListPage + 1);
    }
  };
  const onScrollChat = (event) => {
    const scrollBottom = !event.target.scrollTop;
    if (scrollBottom && currentChatPage < nextChatPage) {
      setCurrentChatPage(currentChatPage + 1);
    }
  };

  useEffect(() => {
    const getChatList = async () => {
      let resChatList = await axiosUserProfile.getChatList(
        searchChat,
        currentChatListPage
      );
      if (resChatList?.results) {
        setCountChats(resChatList?.count || 0);
        setChatList(resChatList.results);

        setNextChatListPage(
          !resChatList.next ? currentChatListPage : currentChatListPage + 1
        );
        setWasLastChatList(!resChatList.next);
      }
      setIsUpdateList(false);
    };
    if (!wasLastChatList) {
      getChatList();
    }
  }, [currentChatListPage, wasLastChatList, isUpdateList]);
  useEffect(() => {
    const getChat = async () => {
      if (Object.keys(selectedOrder).length) {
        let resChat = await axiosUserProfile.getChatOrder(
          selectedOrder.order_number,
          currentChatPage
        );
        if (resChat?.results) {
          let newChat = resChat.results.map((chat) => ({
            ...chat,
            type_of_message:
              chat.type_of_message === "UM" ? "MINE" : "NOT_MINE",
          }));
          if (!resChat.previous) {
            if (newChat.length) {
              if (newChat[0].type_of_message === "MINE") {
                newChat[0].isLast = true;
              }
            }
            setSelectedChat(newChat.reverse());
          } else {
            setSelectedChat([...newChat.reverse(), ...selectedChat]);
          }
          setNextChatPage(
            !resChat.next ? currentChatPage : currentChatPage + 1
          );
          setWasLastMessageList(!resChat.next);
        }
        const scrollContainer = scrollContainerRef.current;
        if (currentChatPage === 1) {
          setTimeout(() => {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }, 500);
        } else {
          let oldScrollHeight = scrollContainer.scrollHeight;
          setTimeout(() => {
            scrollContainer.scrollTop =
              scrollContainer.scrollHeight - oldScrollHeight;
          }, 100);
        }
      }
    };
    if (!wasLastMessageList) {
      getChat();
    }
  }, [selectedOrder, wasLastMessageList, currentChatPage]);

  const selectChat = (order) => {
    setChatList(
      chatList.map((chat) =>
        chat.order_number === selectedOrder.order_number
          ? { ...chat, new_mess_count: 0 }
          : chat
      )
    );
    setSelectedOrder(chatList.find((chat) => chat.order_number === order));
    setMessageText("");
    setWasLastMessageList(false);
    setCurrentChatPage(1);
    setNextChatPage(2);
  };
  const sendMessage = async () => {
    if (messageText.length) {
      let res = await axiosUserProfile.sendMessageChat(
        selectedOrder.order_number,
        messageText
      );
      if (!res) {
        let newSelectedChat = selectedChat.map((chat) => ({
          ...chat,
          isLast: false,
        }));
        setSelectedChat([
          ...newSelectedChat,
          {
            username: "",
            isLast: true,
            is_seen: false,
            text: messageText,
            type_of_message: "MINE",
            creation_date: `${("0" + new Date().getDate()).slice(-2)}.${(
              "0" +
              (new Date().getMonth() + 1)
            ).slice(-2)}.${new Date().getFullYear()}`,
          },
        ]);
        setIsUpdateList(true);
        setCurrentChatListPage(1);
        setNextChatListPage(2);
        setWasLastChatList(false);
      } else {
        setSelectedChat([
          ...selectedChat,
          {
            username: "",
            isLast: false,
            is_seen: false,
            text: messageText,
            type_of_message: "MINE",
            isError: true,
            creation_date: `${"0" + new Date().getDate()}.${
              new Date().getMonth() + 1
            }.${new Date().getFullYear()}`,
          },
        ]);
      }
      setMessageText("");
      setTimeout(() => {
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 100);
    }
  };
  const search = async () => {
    setWasLastChatList(false);
    setCurrentChatListPage(1);
    setNextChatListPage(2);
    setIsUpdateList(true);
  };
  const closeChat = () => {
    setSelectedOrder({});
    setSelectedChat([]);
    setMessageText("");
    setWasLastMessageList(false);
    setCurrentChatPage(1);
    setNextChatPage(2);
  };
  return (
    <div className={styles.mobile_chat_cont}>
      <div className={styles.mobile_chat__header}>
        Чаты<span>{countChats}</span>
      </div>
      <div className={styles.mobile_chat_list} onScroll={onScrollChatList}>
        <div style={{ marginBottom: vhM(20) }}>
          <Input
            light
            placeholder="Введите артикул или название товара"
            value={searchChat}
            fontColor="#BFC4C3"
            height={vhM(44)}
            inputStyles={{
              padding: `${vhM(13)} ${vwM(24)}`,
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              fontSize: vhM(14),
            }}
            prependStyles={{ height: "100%", boxSizing: "border-box" }}
            prepend={
              <img
                src={search_icon}
                style={{ height: vhM(10.67) }}
                alt="search"
                onClick={search}
              />
            }
            onChange={(e) => setSearchChat(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
        </div>
        {chatList.length ? (
          chatList.map((chat, index) => (
            <ItemChatList
              key={index}
              chat={chat}
              selectedOrder={selectedOrder.order_number}
              isMobile={true}
              open={() => selectChat(chat.order_number)}
            />
          ))
        ) : (
          <span className={styles.mobile_chat__empty}>Нет чатов</span>
        )}
      </div>
      {Object.keys(selectedOrder).length ? (
        <div className={styles.selected_mobile_chat}>
          <div className={styles.selected_mobile_chat__header}>
            <div className={styles.selected_mobile_chat__header__seller}>
              {selectedOrder?.avatar_url ? (
                <img src={selectedOrder?.avatar_url} alt="avatar" />
              ) : (
                <></>
              )}
              <span>{selectedOrder?.username || ""}</span>
            </div>
            <div
              className={styles.selected_mobile_chat__header__close}
              onClick={closeChat}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M0.619873 1.11978L16.3803 16.8802M16.3803 1.11978L0.619873 16.8802"
                  stroke="#5F6B69"
                  strokeWidth="1.0168"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          <div
            className={styles.selected_mobile_chat__messages}
            onScroll={onScrollChat}
            ref={scrollContainerRef}
          >
            {selectedChat.length ? (
              selectedChat.map((chat, index) => (
                <MessageChat key={index} message={chat} isMobile={true} />
              ))
            ) : (
              <div className={styles.selected_mobile_chat__empty}>
                Сообщений пока нет
              </div>
            )}
          </div>
          <div className={styles.selected_mobile_chat__send_message}>
            <input
              type="text"
              value={messageText}
              className={styles.selected_mobile_chat__send_message__input}
              placeholder="Ваше сообщение"
              onChange={(e) => setMessageText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
            />
            <span
              className={styles.selected_mobile_chat__send_message__send}
              onClick={sendMessage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M11.4077 6.81924C11.4077 6.81924 18.0059 12.6897 21.6032 16.1343C21.9455 16.462 21.9346 17.0098 21.5853 17.33C17.7521 20.8437 15.4143 22.9867 11.4077 26.6594"
                  stroke="#20B900"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { MobileChat };
