import React from "react";
import { Helmet } from "react-helmet";
import styles from "./NotFoundPage.module.scss";
import { Button } from "components";
import not_found from "assets/img/not_found.png";

const NotFoundPage = () => {
  return (
    <div className={styles.notfound}>
      <Helmet title="Ошибка">
        <link rel="canonical" href="*" />
        <meta
          name="description"
          content="Shalfey : Страница отсутствует или не найдена."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.header}>
        <h1>Упс!</h1>
        <h1>Страница не найдена...</h1>
      </div>
      <p>
        К сожалению, запрашиваемая вами страница не существует или была удалена.
        Возможно, вы ввели неправильный адрес или следовали неактуальной ссылке.
        <br />
        <b>Не переживайте, вы всё ещё можете:</b> <br />
        Вернуться на главную страницу
      </p>
      <Button
        text="Вернуться на гланую страницу"
        padding="16px 34px"
        fontSize="18px"
        click={() => {
          window.location.href = process.env.REACT_APP_USER_URL;
        }}
      />
      <img src={not_found} alt="Упс! Страница не найдена..." />
    </div>
  );
};

export { NotFoundPage };
