import styles from "./MainMenu.module.scss";
import { Link, Button } from "components";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosHome } from "plugins/axios";
import { useMediaQuery } from "react-responsive";
import { vhM, vwM } from "plugins/convertPXtoVHVW";

import * as links from "constants/url";
import preventive_icon from "assets/img/preventive_icon.svg";
import herb from "assets/img/herb.svg";
import china from "assets/img/china.svg";
import thai from "assets/img/thai.svg";
import russia from "assets/img/russia.svg";
import vietnamese from "assets/img/vietnamese.svg";

const MainMenu = ({
  typeOfMedicine,
  titleMedicines = "Выбери свой мир медицины",
  selectServices,
  selectNews,
  selectForum,
  visibleNews = false,
  visibleServices = true,
  visibleForum = false,
  isArticles = false,
  white = false,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const idsOfTypesOfMedicine = [
    { id: 6, url: preventive_icon },
    { id: 3, url: herb },
    { id: 1, url: china },
    { id: 2, url: thai },
    { id: 5, url: russia },
    { id: 4, url: vietnamese },
  ];
  const [typesOfMedicine, setTypesOfMedicine] = useState([]);

  const getMainTypes = async () => {
    let result = await axiosHome.getMainTypes();
    setTypesOfMedicine(result || []);
  };

  useEffect(() => {
    getMainTypes();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.questionnaire}>
        <p style={{ color: white && "#fff", marginTop: white && "0" }}>
          Индивидуальное решение,
          <br /> для вашего здоровья
        </p>
        <Button
          text="Анкета"
          width={isMobile ? "100%" : "auto"}
          height="5.1vh"
          prepend={
            <svg
              width="21"
              height="1.3vh"
              viewBox="0 0 21 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="0.897516"
                cy="0.897516"
                r="0.897516"
                fill={white ? "#fff" : "#20B900"}
              />
              <rect
                x="3.58984"
                width="17.0528"
                height="1.79503"
                rx="0.897516"
                fill={white ? "#fff" : "#20B900"}
              />
              <circle
                cx="0.897516"
                cy="6.28253"
                r="0.897516"
                fill={white ? "#fff" : "#20B900"}
              />
              <rect
                x="3.58984"
                y="5.38501"
                width="17.0528"
                height="1.79503"
                rx="0.897516"
                fill={white ? "#fff" : "#20B900"}
              />
              <circle
                cx="0.897516"
                cy="11.6678"
                r="0.897516"
                fill={white ? "#fff" : "#20B900"}
              />
              <rect
                x="3.58984"
                y="10.7703"
                width="17.0528"
                height="1.79503"
                rx="0.897516"
                fill={white ? "#fff" : "#20B900"}
              />
            </svg>
          }
          padding={isMobile ? `${vhM(13)} ${vwM(26)}` : "0px 50px"}
          newLight={!white}
          click={() => navigate(links.QUESTIONNAIRE.url)}
        />
      </div>
      <div className={styles.menu}>
        {visibleNews && (
          <Link
            className={`${styles.main_link} ${
              selectNews ? styles.active : null
            }`}
            path="/wiki/news"
          >
            Новости
          </Link>
        )}
        <p style={{ color: white && "#fff" }}>{titleMedicines}</p>
        {Array.isArray(typesOfMedicine) &&
          idsOfTypesOfMedicine.map((medicine) => {
            let typeOfMed = typesOfMedicine.find(
              (item) => item.id === medicine.id
            );
            return (
              <div key={medicine.id}>
                <Link
                  className={styles.link}
                  path={
                    isArticles
                      ? `/wiki/articles?types_of_medicine=${typeOfMed?.name}`
                      : `/catalogue/${typeOfMed?.name}`
                  }
                >
                  <img
                    src={white ? medicine.url : typeOfMed?.icon_url}
                    alt={typeOfMed?.name}
                    style={{
                      height: "2.9vh",
                      marginRight: "1vh",
                    }}
                  />
                  <li
                    className={`${
                      typeOfMedicine === typeOfMed?.name ? styles.active : ""
                    } ${white ? styles.active_white : ""}`}
                    style={{ color: white && "#fff" }}
                  >
                    <h1>{typeOfMed?.name}</h1>
                    {typeOfMed?.small_desc && (
                      <h2>({typeOfMed?.small_desc})</h2>
                    )}
                  </li>
                </Link>
              </div>
            );
          })}
        {/* {visibleServices && (
          <Link
            className={`${styles.main_link} ${
              selectServices ? styles.active : null
            }`}
            path="/services"
            color={white && "#fff"}
          >
            Услуги
          </Link>
        )} */}
        {visibleForum && (
          <Link
            className={`${styles.main_link} ${
              selectForum ? styles.active : null
            }`}
            path="/wiki/forum"
          >
            Форум
          </Link>
        )}
      </div>
      <div />
    </div>
  );
};
export { MainMenu };
