import styles from "./WaitForVerification.module.scss";
import React from "react";
import verification from "assets/img/verification.png";
import { Helmet } from "react-helmet";

const WaitForVerification = () => {
  return (
    <section className={styles.container}>
      <Helmet title="Ваши данные отправлены на проверку">
        <meta
          name="description"
          content="Shalfey : Ваши данные отправлены на проверку"
        />
        <link rel="canonical" href="/wait_for_verification" />
      </Helmet>
      <h1 className={styles.heading}>Ваши данные отправлены на проверку.</h1>
      <h1 className={styles.heading}>
        Мы отправим уведомление, когда проверка будет завершена.
      </h1>
      <img src={verification} alt="Ваши данные отправлены на проверку" />
    </section>
  );
};

export { WaitForVerification };
