import { Button, Loader } from "components";
import avatar from "assets/img/avatar.png";
import profile from "assets/img/profile_gray.svg";
import track_orders from "assets/img/track_orders.svg";
import purchases from "assets/img/purchases.svg";
import favorite from "assets/img/favorite_gray.svg";
import reviews from "assets/img/reviews.svg";
import Star_empty_gray from "assets/img/Star_empty_gray.svg";
import exit from "assets/img/exit.svg";
import chats from "assets/img/chats.svg";
import styles from "./NewProfileInfo.module.scss";

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { userButtons, MOBILE_CHAT } from "constants/profileButton";
import { axiosUserProfile, axiosBasket } from "plugins/axios";
import { checkError } from "plugins/checkError";

const NewProfileInfo = ({ activeWindow }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const imageArray = [
    track_orders,
    purchases,
    favorite,
    Star_empty_gray,
    reviews,
  ];
  const [user, setUser] = useState({});
  const [countsArray, setCountsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needAuth, setNeedAuth] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const getUser = async () => {
    setLoading(true);
    let res = await axiosUserProfile.getProfileInfo();
    if (res.response?.status === 403) {
      navigate("/");
    }
    const { isError, messageError } = checkError(res);
    if (isError) {
      setErrMessage(messageError);
      setNeedAuth(true);
      setLoading(false);
      return;
    }
    setUser(res?.user);
    setCountsArray([
      res?.counts.deliveries || 0,
      res?.counts.purchases || 0,
      res?.counts.favorite || 0,
      res?.counts.reviews || 0,
      res?.counts.chats || 0,
    ]);
    setNeedAuth(false);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return loading ? (
    <Loader bgColor="#20B900" align="center" />
  ) : needAuth ? (
    <div>{errMessage}</div>
  ) : (
    <section className={styles.container}>
      <img
        className={styles.avatar}
        src={user?.avatar_url || avatar}
        alt="Аварар"
      />
      <div className={styles.name}>{user.fio}</div>
      {activeWindow === "main" ? (
        <Button
          text="Редактировать профиль"
          bgColor="#F8FBF9"
          color="#5F6B69"
          padding={isMobile ? "1.5vh 8vw" : "1.5vh 2vw"}
          margin="0 auto"
          click={() => navigate("/profile?tab=update")}
        />
      ) : (
        <div className={styles.main_tab} onClick={() => navigate("/profile")}>
          <p>
            <img src={profile} alt="Профиль" />
            Профиль
          </p>
        </div>
      )}
      <div className={styles["horizontal-line"]} />
      {userButtons.slice(2).map((item, index) => (
        <div
          key={index}
          className={`${styles.tab} ${
            activeWindow === item.type ? styles.active : ""
          }`}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/profile?tab=" + item.type)}
        >
          <p>
            <img src={imageArray[index]} alt={item.title} />
            {item.title}
          </p>
          <p>{countsArray[index]}</p>
        </div>
      ))}
      <div
        className={`${styles.tab} ${
          activeWindow === "chats" ? styles.active : ""
        }`}
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            isMobile ? "/profile?tab=" + MOBILE_CHAT.type : "/profile/chats"
          )
        }
      >
        <p>
          <img src={chats} alt="Чаты" />
          Чаты
        </p>
        <p>{[...countsArray].pop()}</p>
      </div>
      <div className={styles["horizontal-line"]} />
      <div
        className={styles.exit}
        onClick={async () => {
          localStorage.removeItem("name");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("history");
          await axiosBasket.getCountInMyCart();
          navigate("/");
        }}
      >
        <img src={exit} /> Выйти
      </div>
    </section>
  );
};

export { NewProfileInfo };
