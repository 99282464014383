import axios from "axios";
import * as url from "../endpoints";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const instanceEmpty = axios.create();

export const config = {
  headers: {
    Authorization: `Bearer ` + localStorage.getItem("token"),
  },
};
export const configExcel = {
  responseType: "blob",
  headers: {
    Authorization: `Bearer ` + localStorage.getItem("token"),
  },
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (token = null) => {
  failedQueue.forEach((prom) => prom.resolve(token));

  failedQueue = [];
};

const refreshToken = async () => {
  const response = await instance.post(url.REFRESH_TOKEN, {
    refresh_token: localStorage.getItem("refresh_token"),
  });
  return response;
};
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalConfig = error.config;
    if (originalConfig.url !== url.LOGIN && error.response) {
      if (error.response.status === 403 && !originalConfig._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              if (token) {
                originalConfig.headers["Authorization"] = "Bearer " + token;
              } else {
                originalConfig.headers = {};
              }
              return instance(originalConfig);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
        originalConfig._retry = true;
        isRefreshing = true;
        try {
          const response = await refreshToken();

          localStorage.setItem("token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);
          originalConfig.headers["Authorization"] =
            "Bearer " + response.data.access;
          processQueue(response.data.access);
          isRefreshing = false;
          return instance(originalConfig);
        } catch (_error) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          originalConfig.headers = {};
          processQueue();
          console.log("Expired Token");
          isRefreshing = false;
          return instance(originalConfig);
        }
      }
    }
    return Promise.reject(error);
  }
);
