import React, { useState, useEffect } from "react";
import styles from "./Favorite.module.scss";
import * as sortingType from "constants/sortingType";
import { NewProduct, SliderProducts, Button, Tabs, Article } from "components";
import { checkError } from "plugins/checkError";
import { axiosFavorite, axiosHome, axiosArticle } from "plugins/axios";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { useNavigate } from "react-router-dom";
import { Service } from "pages/Services/Service";
import { useMediaQuery } from "react-responsive";

const Favorite = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [articles, setArticles] = useState([]);
  const [hits, setHits] = useState([]);
  const [sorting, setSorting] = useState(sortingType.DATE.name);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
  };

  const favorite = async (id) => {
    let article = articles.find((item) => item.id === id);
    if (!article) return;
    await axiosArticle.favoriteArticle(article.id);
    setArticles(
      articles.map((item) =>
        item.id === article.id
          ? {
              ...item,
              in_favorite: !item.in_favorite,
              like_count: !item.in_favorite
                ? item.like_count + 1
                : item.like_count - 1,
            }
          : item
      )
    );
  };

  const getFavoriteList = async () => {
    let res = await axiosFavorite.getMyFavorite(sorting);
    const { isError } = checkError(res);
    if (!isError) {
      setProducts(
        res?.products.map((item) => ({
          ...item,
          product: { ...item.product, in_favorite: true },
        })) || []
      );
      setServices(
        res?.services.map((item) => ({ ...item.service, in_favorite: true })) ||
          []
      );
      setArticles(
        res?.articles.map((item) => ({ ...item, in_favorite: true })) || []
      );
    }

    let result = await axiosHome.getHits();
    setHits(
      result.purchased.map((item) => ({ ...item, in_favorite: false })) || []
    );
  };

  useEffect(() => {
    getFavoriteList();
  }, [sorting]);

  return (
    <div className={styles.favorite}>
      {products.length || services.length || articles.length ? (
        <>
          <div className={styles.favorite__header}>
            Избранное
            <span>{products.length + services.length + articles.length}</span>
          </div>
          {products.length ? (
            <>
              <Tabs
                tabs={[
                  {
                    name: sortingType.DATE.name,
                    title: sortingType.DATE.title,
                  },
                  {
                    name: sortingType.PRICE.name,
                    title: sortingType.PRICE.title,
                  },
                ]}
                currentTab={sorting}
                styleTab={
                  isMobile
                    ? {
                        fontSize: vhM(16),
                        lineHeight: vhM(22),
                        padding: `${vhM(15)} ${vwM(13)}`,
                      }
                    : {}
                }
                changeTab={setSorting}
              />
              <div className={styles.products}>
                <div className={styles.products__title}>Товары</div>
                <div className={styles.favorite__list}>
                  {products.map((item) => (
                    <NewProduct
                      key={item.id}
                      item={item.product}
                      width="auto"
                      height="auto"
                      imgStyle={{ width: "100%", height: vh(218) }}
                      isDefaultStyle
                      slider
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {services.length ? (
            <div className={styles.services}>
              <div className={styles.services__title}>Услуги</div>
              {services.length ? (
                <div className={styles.services__list}>
                  {services.map((item, index) => (
                    <Service key={index} element={item} />
                  ))}
                </div>
              ) : (
                "пусто"
              )}
            </div>
          ) : (
            <></>
          )}
          <div className={styles.services__title} style={{ marginTop: "3vh" }}>
            Статьи
          </div>
          <div className={styles.body}>
            {articles.length ? (
              articles.map((item) => (
                <Article
                  key={item.id}
                  article={item}
                  favorite={() => favorite(item.id)}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <div className={styles.basket__empty}>
          <div className={styles.favorite__header}>В избранном пусто</div>
          <div className={styles.favorite__empty__additional_info}>
            Чтобы найти товары, воспользуйтесь поиском
          </div>
          <div className={styles.favorite__empty__actions}>
            <Button
              text="Начать покупки"
              fontSize={vh(16)}
              lineHeight={vh(20)}
              fontWeight="600"
              padding={`${vh(12)} ${vw(32)}`}
              bgColor="#20B900"
              borderRadius={vh(10)}
              click={() => navigate("/")}
            />
          </div>
          {hits ? (
            <div className={styles.favorite__empty__hits}>
              <div className={styles.favorite__empty__hits__header}>
                Хиты продаж
              </div>
              <SliderProducts
                items={hits}
                settings={settings}
                isDefaultStyle={false}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export { Favorite };
