import React from "react";
import styles from "./Inputs.module.scss";
import { Checkbox } from ".";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { useMediaQuery } from "react-responsive";

const Question = ({
  text,
  isCheckbox = false,
  isSelected = false,
  change,
  click,
  ...rest
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <label
      className={`${styles.questionnaire_question} ${
        isSelected ? styles.questionnaire_question__checked : ""
      }`}
      style={{ ...rest }}
      onClick={click}
    >
      {isCheckbox && (
        <Checkbox
          isSelected={isSelected}
          marginRight={isMobile ? vwM(18) : vw(24)}
          width={isMobile ? vhM(22) : vh(26)}
          height={isMobile ? vhM(22) : vh(26)}
          change={change}
        />
      )}
      <span className={styles.question_text}>{text}</span>
      {!isCheckbox && (
        <span className={styles.question_icon}>
          <svg
            width={isMobile ? vhM(28) : vh(28)}
            height={isMobile ? vhM(28) : vh(28)}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="14" cy="14" r="14" fill="#20B900" fillOpacity="0.54" />
            <path
              d="M11 8C11 8 14.9185 11.4863 17.0549 13.532C17.2581 13.7266 17.2517 14.0519 17.0442 14.2421C14.7678 16.3288 13.3794 17.6015 11 19.7826"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </span>
      )}
    </label>
  );
};

export { Question };
