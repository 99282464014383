import * as actionType from "../../constants/actionType";

const initialState = {
  countInBasket: 0,
  sellerNotificationsCount: 0,
  isOpenAuthModal: false,
  isOpenCityModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CHANGE_COUNT_IN_BASKET:
      return {
        ...state,
        countInBasket:
          action.countInBasket === undefined ? 0 : action.countInBasket,
      };
    case actionType.CHANGE_SELLER_NOTIFICATIONS_COUNT:
      return {
        ...state,
        sellerNotificationsCount:
          action.sellerNotificationsCount === undefined
            ? 0
            : action.sellerNotificationsCount,
      };
    case actionType.CHANGE_IS_OPEN_AUTH_MODAL:
      return {
        ...state,
        isOpenAuthModal:
          action.isOpenAuthModal === undefined ? false : action.isOpenAuthModal,
      };
    case actionType.CHANGE_IS_OPEN_CITY_MODAL:
      return {
        ...state,
        isOpenCityModal:
          action.isOpenCityModal === undefined ? false : action.isOpenCityModal,
      };
    default:
      return state;
  }
};

export default reducer;
