import styles from "./InstractionLayout.module.scss";
import { Header, Footer, MobileMenu } from "components";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const InstractionLayout = ({ staticPages = false }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <>
      <Header isSeller={!isMobile} staticPages={staticPages} />
      <main className={styles.main}>
        <Outlet />
        <MobileMenu />
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  );
};

export { InstractionLayout };
