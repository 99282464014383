import React from "react";
import styles from "./Article.module.scss";
import { typeStyleText } from "constants/articleStylesAndActions";
import { vh, vw } from "plugins/convertPXtoVHVW";

const ArticleBlock = ({ module }) => {
  if (module.module_type === typeStyleText.TEXT.type)
    return (
      <div className={styles.article_block__text}>
        {trasformTextToHTML(module.text)}
      </div>
    );
  else if (module.module_type === typeStyleText.TEXT_BOLD.type)
    return (
      <div className={styles.article_block__text_bold}>
        {trasformTextToHTML(module.text)}
      </div>
    );
  else if (module.module_type === typeStyleText.QUOTE.type)
    return (
      <div className={styles.article_block__quote}>
        {trasformTextToHTML(module.text)}
      </div>
    );
  else if (module.module_type === typeStyleText.HEADING.type)
    return (
      <div className={styles.article_block__heading}>
        {trasformTextToHTML(module.text)}
      </div>
    );
  else if (module.module_type === typeStyleText.IMAGE.type)
    return (
      <img
        className={styles.article_block__image}
        src={module.image_url}
        width={vw(1047)}
        height={vh(520)}
        alt={module?.alt || module?.text || module?.image_url.split("/").pop()}
      />
    );
  else if (module.module_type === typeStyleText.DIVIDER.type)
    return <div className={styles.article_block__divider} />;
  else return;
};

const trasformTextToHTML = (text) => {
  let paragraphs = text.split("<br>");
  return paragraphs.length === 1
    ? paragraphs[0]
    : paragraphs.map((e, index) => (
        <span key={index}>
          {e}
          <br />
        </span>
      ));
};

export { ArticleBlock };
