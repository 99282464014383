import React, { useState, useEffect, useRef } from "react";
import styles from "./ModalCity.module.scss";
import { axiosStatic } from "plugins/axios";
import { Button, Input } from "components";
import { vh, vw } from "plugins/convertPXtoVHVW";
import search_icon from "assets/img/search_dark_grey.svg";

const ModalCity = () => {
  const [cityText, setCityText] = useState(localStorage.getItem("city") || "");
  const [addresses, setAddresses] = useState([]);
  const debounceTimeout = useRef(null);
  const [isSearch, setIsSearch] = useState(false);
  const [errorCity, setErrorCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getCities = async () => {
    let resCities = await axiosStatic.getCities(cityText);
    setAddresses(resCities);
    setIsLoading(false);
  };

  useEffect(() => {
    if (cityText === "") {
      setAddresses([]);
      return;
    }
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (isSearch) {
      getCities();
      setIsSearch(false);
    } else {
      debounceTimeout.current = setTimeout(() => {
        getCities();
      }, 1500);
    }

    return () => clearTimeout(debounceTimeout.current);
  }, [cityText, isSearch]);

  const changeCity = async () => {
    if (addresses.some((address) => address.name === cityText)) {
      localStorage.setItem("city", cityText);
      window.location.reload();
    } else {
      setErrorCity(cityText.length ? "Такой город не найден" : "Укажите адрес");
    }
  };
  return (
    <div className={styles.city_cont}>
      <div className={styles.city__header}>Ваш адрес</div>
      <div className={styles.city__search}>
        <Input
          light
          errorMessage={errorCity}
          placeholder="Поиск адреса"
          value={cityText}
          fontColor="#5F6B69"
          height={vh(50)}
          width="100%"
          inputStyles={{
            padding: `${vh(15)} ${vw(18)}`,
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            fontSize: vh(16),
            lineHeight: vh(20),
          }}
          prependStyles={{ height: "100%", boxSizing: "border-box" }}
          prepend={
            <img
              src={search_icon}
              style={{ height: vh(21) }}
              alt="search"
              onClick={() => setIsSearch(true)}
            />
          }
          onChange={(e) => {
            setCityText(e.target.value);
            setErrorCity("");
            setIsLoading(true);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setIsSearch(true);
            }
          }}
        />
        {isLoading ? (
          <div className={styles.city__search__empty}>Поиск...</div>
        ) : addresses.length ? (
          <ul className={styles.city__search__list}>
            {addresses.map((address) => (
              <li
                key={address.id}
                className={address.name === cityText ? styles.select : null}
                onClick={() => {
                  setCityText(address.name);
                  setErrorCity("");
                }}
              >
                <span>{address.name}</span>
              </li>
            ))}
          </ul>
        ) : !cityText.length ? (
          <></>
        ) : (
          <div className={styles.city__search__empty}>
            Такого города не найдено
          </div>
        )}
      </div>
      <div className={styles.city__button}>
        <Button
          text="Выбрать"
          width="100%"
          bgColor="#20B900"
          color="#ffffff"
          padding={`${vh(15.5)} ${vw(31)}`}
          click={changeCity}
        />
      </div>
    </div>
  );
};

export { ModalCity };
