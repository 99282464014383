import React from "react";
import styles from "./Inputs.module.scss";

const Tag = ({ text = "", ...rest }) => {
  return (
    <span className={styles.tag} style={{ ...rest }}>
      {text}
    </span>
  );
};

export { Tag };
