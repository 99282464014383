import * as url from "../endpoints";
import * as ERROR from "constants/error";
import { instance, config } from "./createAxios";
import { removeToken } from "plugins/token";

export const axiosProduct = {
  async getProduct(id) {
    try {
      const response = await instance.get(
        url.GET_PRODUCT(id) + `?city=${localStorage.getItem("city") || ""}`,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getProductReviews(id) {
    try {
      const response = await instance.get(url.GET_PRODUCT_REVIEWS(id));
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getRecommendations(id) {
    try {
      const response = await instance.get(
        url.GET_SIMILAR(id) + `?city=${localStorage.getItem("city") || ""}`,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getService(id) {
    try {
      const response = await instance.get(
        url.GET_SERVICE(id) + `?city=${localStorage.getItem("city") || ""}`,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getServiceTypes() {
    try {
      const response = await instance.get(url.GET_SERVISE_TYPES);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async getServices(type = "") {
    try {
      const response = await instance.get(
        url.GET_SERVISE(type),
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  async getMyHistoryProducts(params) {
    try {
      const response = await instance.post(
        url.GET_USER_HISTORY,
        { ...params, city: localStorage.getItem("city") || "" },
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async watchRemoteServiceUrl(id) {
    try {
      const response = await instance.put(
        url.WATCH_REMOTE_SERVICE_URL(id),
        { city: localStorage.getItem("city") || "" },
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
};
