import * as url from "../endpoints";
import { instance, config } from "./createAxios";
import { removeToken } from "../token";
import { typeStyleText } from "constants/articleStylesAndActions";
import { urlImgToFile } from "plugins/urlImgToFile";

export const axiosArticle = {
  async getArticles(page = 1, params = "") {
    try {
      const response = await instance.get(
        url.GET_ARTICLES(page, params),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getMyArticles(type = "published", page = 1, params = "") {
    try {
      const response = await instance.get(
        url.GET_MY_ARTICLES(type, page, params),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSymptoms() {
    try {
      const response = await instance.get(url.GET_ARTICLE_SYMPTOMS());
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getArticle(id) {
    try {
      const response = await instance.get(url.GET_ARTICLE(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getMyArticle(id) {
    try {
      const response = await instance.get(url.GET_MY_ARTICLE(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async favoriteArticle(id) {
    try {
      const response = await instance.put(url.FAVORITE_ARTICLE(id), {}, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async createArticle(data) {
    try {
      let formData = new FormData();
      formData.append("title", data.name);
      formData.append("description", data.short_desc);
      formData.append("main_pic", data.cover_url, data.cover_url.name);
      formData.append("status", data.visibility);
      formData.append("symptoms", data.symptoms);
      formData.append("types_of_medicine", data.types_of_medicine);
      formData.append("count_of_block", data.blocks.length);
      for (let i = 0; i < data.blocks.length; i++) {
        formData.append(
          `block_type_${data.blocks[i].order}`,
          data.blocks[i].type
        );
        if (data.blocks[i].type === typeStyleText.DIVIDER.type) {
          continue;
        } else if (data.blocks[i].type === typeStyleText.IMAGE.type) {
          formData.append(
            `block_pic_${data.blocks[i].order}`,
            data.blocks[i].image,
            data.blocks[i].image.name
          );
        } else {
          formData.append(
            `block_text_${data.blocks[i].order}`,
            data.blocks[i].text.replaceAll("\n", "<br>")
          );
        }
      }
      const response = await instance.post(
        url.CREATE_ARTICLE(),
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateArticle(id, data) {
    try {
      let formData = new FormData();
      formData.append("title", data.name);
      formData.append("description", data.short_desc);
      let cover_url =
        typeof data.cover_url === "string"
          ? await urlImgToFile(data.cover_url, "main_pic")
          : data.cover_url;
      formData.append("main_pic", cover_url, cover_url.name);
      formData.append("status", data.visibility);
      formData.append("symptoms", data.symptoms);
      formData.append("types_of_medicine", data.types_of_medicine);
      formData.append("count_of_block", data.blocks.length);
      for (let i = 0; i < data.blocks.length; i++) {
        formData.append(
          `block_type_${data.blocks[i].order}`,
          data.blocks[i].type
        );
        if (data.blocks[i].type === typeStyleText.DIVIDER.type) {
          continue;
        } else if (data.blocks[i].type === typeStyleText.IMAGE.type) {
          let block_image =
            typeof data.blocks[i].image === "string"
              ? await urlImgToFile(
                  data.blocks[i].image,
                  `block_pic_${data.blocks[i].order}`
                )
              : data.blocks[i].image;
          formData.append(
            `block_pic_${data.blocks[i].order}`,
            block_image,
            block_image.name
          );
        } else {
          formData.append(
            `block_text_${data.blocks[i].order}`,
            data.blocks[i].text.replaceAll("\n", "<br>")
          );
        }
      }
      const response = await instance.put(
        url.UPDATE_MY_ARTICLE(id),
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async removeArticle(id) {
    try {
      const response = await instance.delete(url.REMOVE_MY_ARTICLE(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};

export const axiosNews = {
  async getNewsList(page = 1, params = "") {
    try {
      const response = await instance.get(
        url.GET_NEWS_LIST(page, params),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getNews(id) {
    try {
      const response = await instance.get(url.GET_NEWS(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};

export const axiosForum = {
  async getForumList(page = 1, params = "") {
    try {
      const response = await instance.get(
        url.GET_FORUM_LIST(page, params),
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getMyForumList(page = 1, params = "") {
    try {
      const response = await instance.get(
        url.GET_MY_FORUM_LIST(page, params),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getForumDetails(id) {
    try {
      const response = await instance.get(
        url.GET_FORUM_DETAILS(id),
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getForumComments(id, page = 1) {
    try {
      const response = await instance.get(
        url.GET_FORUM_COMMENTS(id, page),
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async createForumTheme(data = {}) {
    try {
      let formData = new FormData();
      formData.append("header", data?.header);
      formData.append("text", data?.text);
      for (let i = 0; i < data.images.length; i++) {
        let image =
          typeof data.images[i] === "string"
            ? await urlImgToFile(data.images[i], `pic_${i + 1}`)
            : data.images[i];
        formData.append(`pic_${i + 1}`, image, image.name);
      }

      const response = await instance.post(
        url.CREATE_FORUM_THEME(),
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getRawDataTheme(id) {
    try {
      const response = await instance.get(url.GET_RAW_DATA_THEME(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async updateTheme(id, data = {}) {
    try {
      let formData = new FormData();
      formData.append("header", data?.header);
      formData.append("text", data?.text);
      for (let i = 0; i < data.images.length; i++) {
        let image =
          typeof data.images[i] === "string"
            ? await urlImgToFile(data.images[i], `pic_${i + 1}`)
            : data.images[i];
        formData.append(`pic_${i + 1}`, image, image.name);
      }

      const response = await instance.put(
        url.UPDATE_THEME(id),
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async closeTheme(id) {
    try {
      const response = await instance.put(url.CLOSE_THEME(id), {}, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async createComment(id, data = {}) {
    try {
      let formData = new FormData();
      formData.append("text", data?.text);
      for (let i = 0; i < data.images.length; i++) {
        let image =
          typeof data.images[i] === "string"
            ? await urlImgToFile(data.images[i], `pic_${i + 1}`)
            : data.images[i];
        formData.append(`pic_${i + 1}`, image, image.name);
      }

      const response = await instance.post(
        url.CREATE_COMMENT(id),
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};
