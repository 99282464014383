import React from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./Modal.module.scss";
import { vw, vwM } from "plugins/convertPXtoVHVW";

const ModalNoButton = ({
  title = "Удаление товара",
  description = "Вы действительно хотите удалить товар? Отменить данное действие будет невозможно.",
  close = () => {},
  descriptionAlign = "center",
  ...rest
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className={styles.alert} tabIndex={-1}>
      <div className={styles.alert__background} onClick={close} />
      <div className={styles.alert_block} style={{ ...rest }}>
        <div className={styles.alert_block__close} onClick={close}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={isMobile ? vwM(26) : vw(26)}
            height={isMobile ? vwM(26) : vw(26)}
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.28442 19.8617C5.07183 20.0722 5.06964 20.4156 5.27953 20.6288C5.48942 20.842 5.83191 20.8441 6.0445 20.6337L12.5152 14.2275L18.9031 20.7151C19.113 20.9283 19.4554 20.9305 19.668 20.72C19.8806 20.5096 19.8828 20.1661 19.6729 19.953L13.2851 13.4654L19.7599 7.05522C19.9725 6.84475 19.9747 6.50132 19.7648 6.28816C19.5549 6.07499 19.2124 6.07281 18.9998 6.28328L12.525 12.6934L6.13334 6.20192C5.92345 5.98876 5.58096 5.98657 5.36837 6.19704C5.15578 6.40751 5.15358 6.75094 5.36347 6.9641L11.7552 13.4556L5.28442 19.8617Z"
              fill="#5F6B69"
            />
          </svg>
        </div>
        {!!title && <div className={styles.alert_block__title}>{title}</div>}
        <div
          className={styles.alert_block__description}
          style={{ textAlign: descriptionAlign }}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export { ModalNoButton };
