import * as url from "../endpoints";
import * as ERROR from "constants/error";
import { instance, config } from "./createAxios";

export const axiosFilter = {
  async getFilterParams() {
    try {
      const response = await instance.get(url.FILTER);
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getComplexFilter(data, params) {
    try {
      const response = await instance.post(
        url.COMPLEX_FILTER +
          `?city=${localStorage.getItem("city") || ""}` +
          params,
        data,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getDiseasesList(params) {
    try {
      const response = await instance.get(
        url.SIMPLE_FILTER +
          `?city=${localStorage.getItem("city") || ""}` +
          params,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getMedicineFilterProducts(params) {
    try {
      const response = await instance.get(
        url.MEDICINE_PRODUCTS +
          `?city=${localStorage.getItem("city") || ""}` +
          params,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async getMedicineFilter(params) {
    try {
      const response = await instance.get(
        url.MEDICINE_FILTER + params,
        localStorage.getItem("token") ? config : {}
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
};
