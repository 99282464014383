export const TOKEN_NOT_VALID = {
  code: "token_not_valid",
  message: "Для продолжения необходимо авторизоваться",
};
export const ERR_NETWORK = {
  code: "ERR_NETWORK",
  message: "Произошли неполадки на сервере. Повторите запрос позже!",
};
export const ERR_BAD_RESPONSE = {
  code: "ERR_BAD_RESPONSE",
  message: "Произошли неполадки на сервере. Повторите запрос позже!",
};
export const ERR_BAD_REQUEST = {
  code: "ERR_BAD_REQUEST",
  message: "Неудачный запрос. Повторите запрос!",
};
