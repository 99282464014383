import styles from "./ModalWindow.module.scss";
import { Link, Logo } from "components";
import { useLocation } from "react-router-dom";

const ModalWindow = ({
  width = "25vw",
  backgroundColor = "white",
  isLogo = false,
  isWikiPage = false,
  children,
  isOpen = false,
  onClose = null,
  ...rest
}) => {
  const { pathname } = useLocation();

  return (
    <div
      className={styles.alert}
      style={{ width: isOpen ? "100%" : "0" }}
      tabIndex={-1}
    >
      <div className={styles.alert__background} onClick={onClose} />
      <div
        className={styles.alert_block}
        style={{
          width: isOpen ? width : "0",
          backgroundColor,
          ...rest,
        }}
      >
        {pathname.includes("/wiki") && isWikiPage ? (
          <div
            className={styles.modal_wiki}
            style={{
              display: isOpen ? "flex" : "none",
            }}
          >
            <div className={styles.modal_wiki__logo}>
              <Link path="/wiki/articles">
                <Logo marginLeft="0" />
              </Link>
              <span>Шалфей.Вики</span>
            </div>
            <button className={styles.modal_wiki__close} onClick={onClose} />
          </div>
        ) : (
          <div
            className={isLogo ? "" : styles.modal_content}
            style={{
              display: isOpen ? "flex" : "none",
              justifyContent: isLogo ? "space-between" : "",
            }}
          >
            {isLogo ? (
              <>
                <div
                  className={styles.modal_wiki__logo}
                  style={{ margin: "2vh 0 0 5vw" }}
                >
                  <Link path="/">
                    <Logo marginLeft="0" />
                  </Link>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "1.7vh",
                      fontWeight: "300",
                    }}
                  >
                    экосистема <br />
                    шалфей
                  </div>
                </div>
                <button
                  className={styles.modal_close_button}
                  onClick={onClose}
                />
              </>
            ) : (
              <button className={styles.modal_close_button} onClick={onClose} />
            )}
          </div>
        )}
        {children}
      </div>
      :
    </div>
  );
};

export { ModalWindow };
