import { useEffect } from "react";
export const useKeyDown = (callback, keys) => {
  useEffect(() => {
    const onKeyDown = (event) => {
      const wasAnyKeyPressed = keys.some((key) => event.key === key);
      if (wasAnyKeyPressed) {
        event.preventDefault();
        callback();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });
};
