import React, { useState, useEffect } from "react";

import styles from "./Inputs.module.scss";
import for_checkbox from "../../assets/img/for_checkbox.svg";

const Checkbox = ({
  isSelected = false,
  change,
  width = "26px",
  height = "26px",
  styleBack = {},
  ...rest
}) => {
  const [selected, setSelected] = useState(isSelected || false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const changeCheckbox = () => {
    setSelected(!selected);
    change(!selected);
  };

  return (
    <label className={styles.checkbox} style={{ ...rest }}>
      <input type="checkbox" checked={isSelected} onChange={changeCheckbox} />
      <span
        className={isSelected ? styles.isSelected : ""}
        style={{ ...styleBack, width, height }}
      >
        {isSelected ? (
          <img src={for_checkbox} width={width} height={width} alt="v" />
        ) : (
          <></>
        )}
      </span>
    </label>
  );
};

export { Checkbox };
